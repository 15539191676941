var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('o-dialog',{ref:"dialog",attrs:{"title":"Change password","info":_vm.info,"options":[
    { text: 'Cancel', type: 'cancel' },
    { text: 'Change password', type: 'confirm', callback: _vm.confirm, disabled: !_vm.valid } ],"portal":_vm.portal},scopedSlots:_vm._u([{key:"heading",fn:function(){return [(_vm.account.employee.image)?_c('img',{staticClass:"mx-auto h-12 w-12 rounded-full mt-3",attrs:{"src":_vm.account.employee.image}}):_vm._e()]},proxy:true}])},[_vm._v(" "),_c('div',{staticClass:"pt-4 text-left"},[_c('o-form',{ref:"form"},[_c('o-field-group',{staticClass:"block pb-3",attrs:{"label":"Old password","name":"Old password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var status = ref.status;
return [_c('password-field',{attrs:{"status":status},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}})]}}])}),_vm._v(" "),_c('o-field-group',{staticClass:"block pb-3",attrs:{"label":"New password","name":"New password","rules":"required|password:@Confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var status = ref.status;
return [_c('password-field',{attrs:{"status":status},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})]}}])}),_vm._v(" "),_c('o-password-strength',{staticClass:"mb-6",attrs:{"password":_vm.newPassword,"strength":_vm.strength},on:{"update:strength":function($event){_vm.strength=$event}}}),_vm._v(" "),_c('o-field-group',{staticClass:"block pb-3",attrs:{"label":"Confirm password","name":"Confirm","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var status = ref.status;
return [_c('password-field',{attrs:{"status":status},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})]}}])}),_vm._v(" "),_c('div',{staticClass:"mb-3 flex justify-end"},[_c('o-button',{attrs:{"flat":"","size":"sm"},on:{"click":function($event){return _vm.handleGeneratePassword()}}},[_vm._v("\n          Suggest a password\n        ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }