import { isNull } from "lodash"

const Watcher = {
  isValid (value) {
    return (
      value !== undefined &&
      value !== null &&
      value !== '' &&
      !value.isProxy &&
      typeof value === 'object' &&
      !Array.isArray(value)
    )
  },
  hasKey (watch, key) {
    return watch && watch.includes(key)
  },
  create (value, watch, callback, key) {
    if (this.isValid(value) && (!key || this.hasKey(watch, key))) {
      return new Proxy(value, this.handler(watch, callback))
    }

    return value
  },
  handler (watch, callback) {
    return {
      get (target, key) {
        return Watcher.create(target[key], watch, callback, key)
      },
      set (target, key, value) {
        target[key] = value
        callback()
        return true
      }
    }
  }
}

export class Filter {
  constructor (config) {
    if (config) {
      this._value = config.value
      this.label = config.label
      this.info = config.info
      this.tooltip = config.tooltip
      this.options = config.options
      this.component = config.component
      this.force = config.force
      this.watch = config.watch
      this.props = config.props
      this.custom = config.custom
      this.features = config.features
      this.config = config
    }
  }

  get value () {
    const value = Watcher.create(this._value, this.watch, () => {
      this.valueChanged()
    })
    return value
  }

  set value (newValue) {
    this._value = newValue

    this.valueChanged()
  }

  valueChanged () {
    this.items = null
  }

  get valueLabel () {
    if (this.config && this.config.valueLabel) {
      return this.config.valueLabel.apply(this)
    } else {
      return this.value
    }
  }

  clear () {
    if (this.config && this.config.clear) {
      return this.config.clear.apply(this)
    } else {
      this.value = null
    }
  }

  isEmpty () {
    if (this.config && this.config.isEmpty) {
      return this.config.isEmpty.apply(this, [this.value])
    } else {
      if (Array.isArray(this.value) && !this.value.length) {
        return true
      } else if (!this.value) {
        return true
      }

      return false
    }
  }

  matches (items) {
    if (this.config && this.config.matches) {
      return this.config.matches.apply(this, [items])
    }

    return null
  }

  get validation () {
    if (this.config && this.config.validation) {
      return this.config.validation.apply(this, [this.value])
    }

    return null
  }
}

export class RemoteFilter extends Filter {
  constructor (type, config) {
    super(config)
    this.type = type
    this.isLoading = false
  }

  request () {
    return {
      filter: this.type,
      params: this.params
    }
  }

  get params () {
    if (this.config && this.config.getParams) {
      return this.config.getParams.apply(this)
    } else {
      return null
    }
  }

  set params (newParams) {
    if (this.config && this.config.setParams) {
      return this.config.setParams.apply(this, [newParams])
    } else {
      return null
    }
  }
}

export class FilterCategory {
  constructor (config) {
    this.label = config.label
    this.filters = config.filters
    this.features = config.features
  }

  isActive () {
    let active = 0
    for (const key in this.filters) {
      const filter = this.filters[key]
      if (!filter.isEmpty()) {
        active++
      }
    }

    return active
  }
}

export class FilterOptions {
  constructor (config) {
    this._options = []
    this.map = config.map
    this.set = config.set

    this.update()
  }

  async update () {
    this.options = await this.map()
  }

  get options () {
    return this._options
  }

  set options (value) {
    this._options = value
    this.set(value)
  }
}

export class FilterController {
  constructor (model, { context, custom = false } = {}) {
    this.model = model
    this.matchAll = true
    this.custom = []

    if (context && custom) {
      context.$store.watch(
        (state, getters) => getters[`customFields/${model.modelName()}`],
        fields =>  this.setCustom(fields)
      )

      context.$store.dispatch('customFields/sync')
    }
  }

  get all () {
    const all = {}
    for (const filter in this) {
      if (this[filter] instanceof Filter) {
        all[filter] = this[filter]
      }
    }
    return all
  }

  get remote () {
    const remote = {}
    for (const filter in this.all) {
      if ((this.all[filter] instanceof RemoteFilter)) {
        remote[filter] = this.all[filter]
      }
    }
    return remote
  }

  get params () {
    return {}
  }

  get force () {
    for (const key in this.all) {
      const filter = this.all[key]
      if (filter.force && !filter.isEmpty()) {
        return true
      }
    }

    return false
  }

  setCustom (fields) {
    for (const field of fields) {
      const id = field.idCustomField

      this[id] = new RemoteFilter('customField', {
        custom: field,
        component: 'OCustomField',
        label: field.name,
        value: null,
        valueLabel () {
          return this.value
        },
        getParams () {
          const { custom, value } = this
          return {
            idCustomField: custom.idCustomField,
            value
          }
        },
        clear () {
          this.value = null
        },
        isEmpty () {
          return this.value === null
        }
      })

      const index = this.custom.findIndex(item => item.custom?.idCustomField === id)

      if (index > -1) {
        this.custom.splice(index, 1)
      }

      this.custom.push(this[id])
    }
  }

  fromJSON (data) {
    for (const key in this) {
      if (this[key] instanceof Filter) {
        const filter = this[key]

        if (data[key]) {
          filter.value = data[key]
        }
      }
    }
  }

  toJSON () {
    const data = {}

    for (const key in this) {
      if (this[key] instanceof Filter) {
        const filter = this[key]

        if (!filter.isEmpty() && filter.valueLabel) {
          data[key] = filter._value
        }
      }
    }

    return data
  }

  remoteForType (type) {
    for (const key in this.remote) {
      const filter = this.remote[key]
      if (filter.type === type) {
        return filter
      }
    }
    return null
  }

  isEmpty () {
    for (const key in this.all) {
      const filter = this.all[key]
      if (!filter.isEmpty()) {
        return false
      }
    }

    return true
  }

  isRemote () {
    for (const key in this.all) {
      const filter = this.all[key]
      if (!filter.isEmpty() && filter instanceof RemoteFilter) {
        return true
      }
    }

    return false
  }

  isValid () {
    for (const key in this.all) {
      const filter = this.all[key]

      const isEmpty = filter.isEmpty()
      const isValid = !filter.validation

      if (!isEmpty && !isValid) {
        return false
      }
    }

    return true
  }

  isActive () {
    for (const key in this.all) {
      const filter = this.all[key]
      if (!filter.isEmpty() && filter.valueLabel) {
        return true
      }
    }

    return false
  }

  async fetchRemote () {
    if (this.isRemote()) {
      const filterRequests = []
      const loadingFilters = []

      for (const key in this.all) {
        const filter = this.all[key]

        if (!filter.isEmpty()) {
          if (!this.force && filter.items) {
            continue
          }

          if (filter instanceof RemoteFilter) {
            const requestFilter = filter.request()

            filterRequests.push(requestFilter)
            loadingFilters.push(filter)
          }
        }
      }

      if (filterRequests.length > 0) {
        loadingFilters.forEach((filter) => { filter.isLoading = true })

        const filterResponses = await this.model.filter(filterRequests, this.params)
        const remotes = {}

        for (const filterResponse of filterResponses.filters) {
          remotes[filterResponse.filter] = filterResponse

          const filter = this.remoteForType(filterResponse.filter)
          if (filter) {
            filter.params = filterResponse.params
          }
        }

        return remotes
      }
    }

    return null
  }

  async fetchRemoteItems (ids) {
    if (ids && ids.length > 0) {
      const items = await this.model.listIDs(ids)

      return items
    } else {
      return null
    }
  }

  clear () {
    for (const key in this.all) {
      const filter = this.all[key]
      if (!filter.isEmpty()) {
        filter.clear()
      }
    }
  }

  async filter (items = []) {
    const filteredIDs = {}
    const allResults = {}

    // Add initial items to results object
    for (const item of items) {
      allResults[item.objectID()] = item
    }

    // Check each of the filters are valid before proceeding
    const isValid = this.isValid()

    if (!isValid) {
      return false
    }

    // Get remote filters and submit on /filter endpoint
    // Returns object containing results for each remote filter
    const remotes = await this.fetchRemote()
    let remoteIDs = null

    // Add remote filters results to remoteIDs if it doesn't exist in results array
    if (remotes) {
      remoteIDs = Object.values(remotes)
        .reduce((ids, currentFilter) => {
          if (currentFilter.ids) {
            return ids.concat(currentFilter.ids
              .filter(id => !allResults[id])
            )
          }

          return ids
        }, [])
    }

    // Get the list of items based on the remoteIDs and add them to the allResults object
    const remoteItems = await this.fetchRemoteItems(remoteIDs)

    if (remoteItems) {
      for (const remoteItem of remoteItems) {
        if (remoteItem) {
          allResults[remoteItem.objectID()] = remoteItem
        }
      }
    }

    // Add previous filter items to allResults object
    for (const key in this.remote) {
      const filter = this.remote[key]
      filter.isLoading = false

      if (!filter.isEmpty()) {
        if (filter.items) {
          for (const filterItem of filter.items) {
            if (filterItem) {
              allResults[filterItem.objectID()] = filterItem
            }
          }
        }
      }
    }

    const finalResults = Object.values(allResults)

    for (const key in this.all) {
      const filter = this.all[key]

      if (!filter.isEmpty()) {
        if (filter instanceof RemoteFilter) {
          if (remotes && remotes[filter.type] && remotes[filter.type].ids) {
            filter.items = remotes[filter.type].ids.map(id => allResults[id])
          }
        } else if (!filter.items) {
          filter.items = filter.matches(finalResults)
        }

        if (filter.items) {
          filteredIDs[key] = filter.items
            .filter(item => item)
            .map(item => item?.objectID())
        }
      }
    }

    const returnValues = Object.values(filteredIDs)
    let finalIDs = []

    if (this.matchAll && returnValues.length > 1) {
      finalIDs = returnValues.reduce((a, b) => b.filter(Set.prototype.has, new Set(a)))
    } else {
      finalIDs = [].concat.apply([], returnValues)
      finalIDs = [...new Set(finalIDs)]
    }


    return finalIDs.map((id) => {
      return allResults[id]
    })
  }
}

