<template>
  <div
    id="notification-list"
    class="z-80000"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <transition-group
      tag="div"
      name="list-complete"
      class="pointer-events-none fixed flex items-start top-0 w-full p-1 md:p-6 flex-col z-80000"
    >
      <notification
        v-for="item in items"
        :key="item.key"
        :item="item"
        @close="close(item)"
      />
    </transition-group>
  </div>
</template>

<script>
import Notification from './Notification'

const key = () => `${Date.now()}-${Math.random()}`

export default {
  components: {
    Notification
  },
  data () {
    return {
      items: [],
      parent: false
    }
  },
  methods: {
    open ({ title, text, variant, action, duration, content }) {
      if (!this.parent) {
        this.$mount()
        document.body.appendChild(this.$el)
        this.parent = true
      }
      return new Promise((resolve) => {
        const item = {
          key: key(),
          show: true,
          text,
          title,
          action,
          variant,
          duration,
          content,
          resolve,
          timer: new this.timer(() => {
            this.close(item)
          }, duration)
        }
        this.items.push(item)
      })
    },
    toast (options = {}) {
      const { title, text, variant = 'info', action, duration = 3000, content } = options
      return this.open({ title, text, variant, action, duration, content })
    },
    error (options = {}) {
      const { title = 'Something went wrong..', text = 'Sorry, there was an error while processing your request', errors, variant = 'danger', duration = 10000 } = options
      return this.open({ title, text: errors ? Object.values(errors).join(', ') : text, variant, duration })
    },
    close (item) {
      item.resolve()
      item.show = false
      this.remove(item)
    },
    remove (item) {
      const i = this.items.indexOf(item)
      if (i >= 0) {
        this.items.splice(i, 1)
      }
    },
    timer (callback, delay) {
      let timerId
      let start
      let remaining = delay

      this.pause = function () {
        window.clearTimeout(timerId)
        remaining -= Date.now() - start
      }

      this.resume = function () {
        start = Date.now()
        window.clearTimeout(timerId)
        timerId = window.setTimeout(callback, remaining)
      }

      this.resume()
    },
    handleMouseEnter () {
      const items = this.items

      items.forEach((notification) => {
        notification.timer.pause()
      })
    },
    handleMouseLeave () {
      const items = this.items

      items.forEach((notification) => {
        notification.timer.resume()
      })
    }
  }
}
</script>

<style lang="scss">
  .list-complete-item {
    transition: all 0.5s;
    display: inline-flex;
    margin-right: 10px;
  }
  .list-complete-enter, .list-complete-leave-to {
    opacity: 0;
    transform: translateY(-40px);
  }
  .list-complete-leave-active {
    position: absolute;
  }
</style>
