import APIObject from '../object'
import Enum from '../enums'

export default class FormItem extends APIObject {
  objectID () {
    return this.idFormItem
  }

  relations () {
    return {
      type: { type: FormItemType },
      dataType: { type: FormItemDataType }
    }
  }

  get isTypeSection () {
    return this.type === FormItemType.section
  }

  get isTypeParagraph () {
    return this.type === FormItemType.paragraph
  }

  get isTypeField () {
    return this.type === FormItemType.field
  }

  get isDataTypeText () {
    return this.dataType === FormItemDataType.text
  }

  get isDataTypeLongText () {
    return this.dataType === FormItemDataType.longtext
  }

  get isDataTypeMultiple () {
    return this.dataType === FormItemDataType.multiple
  }

  get isDataTypeBool () {
    return this.dataType === FormItemDataType.bool
  }

  get isDataTypeNumber () {
    return this.dataType === FormItemDataType.number
  }

  get isDataTypeDate () {
    return this.dataType === FormItemDataType.date
  }

  get isDataTypeSignature () {
    return this.dataType === FormItemDataType.signature
  }

  // TODO: A bunch more form functions here
  // I think most of these are only needed in Swift
}

export const FormItemType = new Enum({
  section: { value: 0, description: 'Section' },
  paragraph: { value: 1, description: 'Paragraph' },
  field: { value: 2, description: 'Field' }
})

export const FormItemDataType = new Enum({
  text: { value: 0, description: 'Text' },
  longtext: { value: 1, description: 'Long Text' },
  multiple: { value: 2, description: 'Multiple values' },
  bool: { value: 3, description: 'Checkbox' },
  yesNo: { value: 7, description: 'Yes / No' },
  number: { value: 4, description: 'Number' },
  date: { value: 5, description: 'Date' },
  signature: { value: 6, description: 'Signature' }
})
