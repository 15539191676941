<template>
  <div>
    <div class="flex justify-between">
      <o-button
        transparent
        icon="chevronLeft"
        size="sm"
        class="h-9"
        :icon-size="10"
        @click="move(-1)"
      />

      <date-range-optional-field
        v-model="_value"
        :status="status"
        :limit="unit === 'custom'"
        class="mx-1"
        @changeStart="handleStartChange"
        @changeFinish="handleFinishChange"
      />

      <o-button
        transparent
        icon="chevronRight"
        size="sm"
        class="h-9"
        :icon-size="10"
        @click="move(1)"
      />
    </div>

    <div class="mt-3">
      <o-chip
        v-for="option in units"
        :key="option.label"
        class="mb-1 mr-1 cursor-pointer"
        :variant="unit === option.value ? 'info' : 'default'"
        :flat="unit === option.value"
        @click="unit = option.value"
      >
        {{ option.label }}
      </o-chip>
    </div>
  </div>
</template>

<script>
import {
  DateRangeOptionalField
} from '@/components/fields'

export default {
  components: {
    DateRangeOptionalField
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    status: {
      type: [String, Boolean],
      default: null
    }
  },
  data () {
    return {
      unit: 'months'
    }
  },
  computed: {
    _value: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    units () {
      return [
        {
          label: 'Daily',
          value: 'days'
        },
        {
          label: 'Weekly',
          value: 'weeks'
        },
        {
          label: 'Monthly',
          value: 'months'
        },
        {
          label: 'Quarterly',
          value: 'quarter'
        },
        {
          label: 'Yearly',
          value: 'years'
        },
        {
          label: 'Custom',
          value: 'custom'
        }
      ]
    }
  },
  watch: {
    unit: {
      handler (unit) {
        if (unit !== 'custom') {
          const start = this._value.start * 1000
          const date = this.$date.startOf(start, unit)
          const value = 0

          this.set(date, unit, value)
        }
      }
    }
  },
  methods: {
    move (value) {
      const start = this._value.start * 1000
      const finish = this._value.finish * 1000
      const unit = this.unit

      // Custom unit type uses days to increment date
      const _unit = unit === 'custom' ? 'days' : unit

      // Increment date by current different between start and finish dates if customer unit type
      const _value = unit === 'custom' ? ((this.$dateFns.differenceInDays(finish, start) + 1) * value) : value

      this.set(start, _unit, _value)
    },
    set (date, unit, value) {
      const _unit = unit === 'quarter' ? 'months' : unit
      const duration = {}

      // Increment date by unit type and value
      duration[_unit] = value
      const start = this.$date.add(date, duration)

      // Increment date by twice the unit type and value, less one day
      let amount = value + (Math.abs(value) > 1 ? value - 1 : 1)

      if (unit === 'quarter') {
        amount = amount * 3
      }

      duration[_unit] = amount
      duration.days = duration.days || -1


      const finish = this.unit === 'days' ? start : this.$date.add(date, duration)

      this._value = {
        start: Math.round(start.getTime() / 1000),
        finish: Math.round(this.$dateFns.endOfDay(finish).getTime() / 1000) - 1
      }
    },
    handleStartChange () {
      const unit = this.unit
      const date = this._value.start

      this.set(date * 1000, unit, 0)
    },
    handleFinishChange (value) {
      if (value.old !== value.new) {
        this.unit = 'custom'
      }
    }
  }
}
</script>
