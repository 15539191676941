import Vue from 'vue'
import * as API from '~/api'

export const state = () => ({
  ready: true,
  auth: {
    email: '',
    redirect: null,
    invite: false
  },
  active: null,
  accounts: {},
  guids: []
})

export const mutations = {
  setAccountInfo (state, { guid, info }) {
    if (state.accounts[guid]) {
      state.accounts[guid].info = info
    } else if (state.accounts[state.active]) {
      state.accounts[state.active].info = info
    }
  },
  setActive (state, guid) {
    state.active = guid
    state.guids = Object.keys(state.accounts)
  },
  setReady (state, ready) {
    state.ready = ready
  },
  setLock (state, { guid, locked }) {
    if (state.accounts[guid]) {
      state.accounts[guid].locked = locked
    } else if (state.accounts[state.active]) {
      state.accounts[state.active].locked = locked
    }
  },
  setHome (state, { guid, home }) {
    if (state.accounts[guid]) {
      state.accounts[guid].home = home
    } else if (state.accounts[state.active]) {
      state.accounts[state.active].home = home
    }
  },
  setSort (state, { guid, model, value }) {
    if (state.accounts[guid]) {
      if (!state.accounts[guid].sort) {
        state.accounts[guid].sort = {}
      }

      state.accounts[guid].sort[model] = value
    } else if (state.accounts[state.active]) {
      if (!state.accounts[state.active].sort) {
        state.accounts[state.active].sort = {}
      }

      state.accounts[state.active].sort[model] = value
    }
  },
  setHistory (state, { guid, path }) {
    if (state.accounts[guid]) {
      state.accounts[guid].history = path || '/'
    } else if (state.accounts[state.active]) {
      state.accounts[state.active].history = path || '/'
    }
  },
  setAttempts (state, { guid, value }) {
    if (state.accounts[guid]) {
      state.accounts[guid].attempts = value || 0
    } else if (state.accounts[state.active]) {
      state.accounts[state.active].attempts = value || 0
    }
  },
  setPanel (state, { guid, mode }) {
    if (state.accounts[guid]) {
      state.accounts[guid].panel = mode || 'panel'
    } else if (state.accounts[state.active]) {
      state.accounts[state.active].panel = mode || 'panel'
    }
  },
  addAccount (state, account) {
    const guid = account.guid

    state.accounts = {
      ...state.accounts,
      [guid]: { ...account }
    }

    if (guid) {
      state.active = guid
    }

    state.guids = Object.keys(state.accounts)
  },
  removeAccount (state, account) {
    const guid = account?.guid

    if (guid) {
      delete state.accounts[account.guid]
    }

    state.guids = Object.keys(state.accounts)
  },
  set (state, payload) {
    Vue.set(state.auth, payload.key, payload.value)
  }
}

export const getters = {
  info (state) {
    const accounts = state.accounts
    const active = state.active

    const account = accounts?.[active]

    return account ? API.AuthInfo.ensure(account.info) : null
  },
  locked (state) {
    const accounts = state.accounts
    const active = state.active

    const account = accounts?.[active]

    if (account) {
      return account.locked && !!account.info?.employee?.pinHash
    }

    return false
  },
  home (state) {
    const accounts = state.accounts
    const active = state.active

    const account = accounts?.[active]

    if (account) {
      return account.home
    }

    return null
  },
  sort (state) {
    const accounts = state.accounts
    const active = state.active

    const account = accounts?.[active]

    if (account) {
      return account.sort
    }

    return {}
  },
  active (state) {
    const accounts = state.accounts
    const active = state.active

    const account = accounts?.[active]

    return account
  },
  attempts (state) {
    const accounts = state.accounts
    const active = state.active

    const account = accounts?.[active]

    return account.attempts
  },
  ready (state) {
    return state.ready
  },
  panel (state) {
    const accounts = state.accounts
    const active = state.active

    const account = accounts?.[active]

    return account.panel
  },
  accounts (state) {
    const accounts = Object.values(state.accounts)
    return API.AuthInfo.ensure(accounts)
  },
  grants (state) {
    const accounts = state.accounts
    const active = state.active

    const account = accounts?.[active]

    if (account) {
      const grants = account.info?.employee?.role?.grants
      const array = []

      for (const grant of grants) {
        const path = grant
        const item = path.split('?')[0]
        const [, , model, action] = item.split('/')

        if (!array[model]) {
          array[model] = []
        }

        array[model].push(action)
      }

      return array
    }

    return {}
  },
  guids (state) {
    return state.guids
  }
}

export const actions = {

}
