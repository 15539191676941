var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full flex flex-col"},[_c('div',{staticClass:"flex-shrink h-10 w-full",class:[
      'flex items-center flex-row',
      _vm.navClass
    ]},[_vm._t("leading"),_vm._v(" "),(_vm.showArrows)?[_c('o-button',{attrs:{"flat":"","size":"sm","icon":"chevronLeft"},on:{"click":function($event){return _vm.handleMoveScroll(-1)}}}),_vm._v(" "),_vm._m(0)]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex-grow flex-shrink w-10 h-10"},[_c('o-scroll',{ref:"scroll",attrs:{"x-axis":"","y-axis":false,"options":{
          visibility: 'hidden'
        },"draggable":""}},[_c('div',{staticClass:"flex relative h-full items-center px-2"},[_vm._l((_vm.tabs),function(tab,i){return [(tab.isVisible)?_c('button',{key:i,ref:tab.hash,refInFor:true,staticClass:"z-10 flex h-8 flex-shrink-0 items-center text-sm -mb-px leading-none hover:text-green-600 focus:outline-none relative",class:[
                _vm.pill ? 'px-4 mr-px' : 'px-2 mr-2 md:mr-4',
                tab.isActive ? (_vm.pill ? 'text-gray-600 font-medium' : 'text-green-600 font-medium') : 'text-gray-600',
                tab.tabClass
              ],on:{"click":function($event){return _vm.selectTab(tab.hash, $event)}}},[(tab.icon)?_c('o-icon',{staticClass:"lg:mr-3 p-2 md:p-0 hidden lg:block",attrs:{"icon":tab.icon,"size":16}}):_vm._e(),_vm._v(" "),_c('span',{},[_vm._v(_vm._s(tab.name))]),_vm._v(" "),(tab.alert)?_c('span',{staticClass:"block mb-px ml-2 bg-gray-400 text-gray-600 p-1 leading-none rounded text-xs font-medium"},[_vm._v("\n                "+_vm._s(tab.alert)+"\n              ")]):_vm._e()],1):_vm._e()]}),_vm._v(" "),_c('span',{class:[
              'absolute transition-all ease-in-out duration-200',
              _vm.pill ? 'h-8 bg-gray-200 rounded-md top-1/2 transform -translate-y-1/2' : 'h-2px bottom-0 bg-green-600 shadow'
            ],style:(_vm.underline)})],2)])],1),_vm._v(" "),(_vm.showArrows)?[_vm._m(1),_vm._v(" "),_c('o-button',{attrs:{"flat":"","size":"sm","icon":"chevronRight"},on:{"click":function($event){return _vm.handleMoveScroll(1)}}})]:_vm._e(),_vm._v(" "),_vm._t("trailing")],2),_vm._v(" "),_c('o-scroll',{class:[
      _vm.containerClass
    ],attrs:{"y-axis":_vm.scrollable}},[_vm._t("default")],2)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-2 pl-2 h-full"},[_c('span',{staticClass:"border-r h-full"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-2 pr-2 h-full"},[_c('span',{staticClass:"border-r h-full"})])}]

export { render, staticRenderFns }