<template>
  <div class="flex flex-col">
    <div v-if="variables && variables.length" class="flex items-center h-12 w-full border-t px-6">
      <o-dropdown ref="dropdown" class="h-7">
        <o-button size="sm" class="my-auto">
          Insert placeholder
        </o-button>

        <template #content>
          <o-scroll class="max-h-56">
            <ul>
              <li
                v-for="(variable, index) in variables"
                :key="index"
                class="text-gray-900 cursor-pointer select-none relative py-2 px-3 hover:bg-gray-200 transition-colors"
                role="option"
                @click="handleVariableClick(variable)"
              >
                {{ variable }}
              </li>
            </ul>
          </o-scroll>
        </template>
      </o-dropdown>
    </div>

    <text-area-field
      v-model="_value"
      :placeholder="placeholder"
      class="h-full flex-grow"
      rows="4"
      :base-class="['w-full block bg-white border border-gray-300 text-sm text-gray-800 p-3', variables && variables.length ? '' : 'rounded-t']"
    />

    <div class="border border-t-0 rounded-b w-full bg-gray-100 px-2 py-1 flex items-center justify-between">
      <div>
        <o-tooltip v-if="counter && counter.messages > 1" text="Based on the text you have entered, this message will send more than one SMS. This estimate may vary based on the placeholders used.">
          <o-chip variant="info" flat size="sm" square>
            Your message may use more than 1 SMS
            <o-icon icon="info" class="inline-block ml-3" />
          </o-chip>
        </o-tooltip>
      </div>
      <div class="flex items-center space-x-4">
        <div>
          <o-tooltip text="Please note: a single SMS contains 160 characters (special characters, such as emojis, reduce this to 80 characters)">
            <o-chip v-if="counter && counter.special" variant="warning" flat size="sm" square>
              Your message contains special characters: {{ counter.special }}
            </o-chip>
          </o-tooltip>
        </div>
        <div class="text-sm font-semibold text-gray-700">
          <template v-if="counter">
            {{ counter.remaining }} / {{ counter.messages }}
          </template>
          <template v-else>
            160 / 0
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  TextAreaField
} from '@/components/fields'

import * as API from '~/api'

export default {
  name: 'OSms',
  components: {
    TextAreaField
  },
  props: {
    value: {
      default: '',
      type: String
    },
    placeholder: {
      type: String,
      default: null
    },
    variables: {
      type: Array,
      default: () => []
    }
  },
  data () {
    const gsm7bitChars = '@£$¥èéùìòÇ\\nØø\\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\\"#¤%&\'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà'
    const gsm7bitExChar = '\\^{}\\\\\\[~\\]|€'

    return {
      article: false,
      counter: null,
      credits: null,
      gsm7bitRegExp: RegExp('^[' + gsm7bitChars + ']*$'),
      gsm7bitExRegExp: RegExp('^[' + gsm7bitChars + gsm7bitExChar + ']*$'),
      gsm7bitExRegExpRev: RegExp('[' + gsm7bitChars + gsm7bitExChar + ']+', 'g'),
      gsm7bitExOnlyRegExp: RegExp('^[\\' + gsm7bitExChar + ']*$')
    }
  },
  computed: {
    _value: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    _value: {
      handler () {
        this.counter = this.count()
      },
      immediate: true
    }
  },
  mounted () {
    // this.fetchSMSCredits()
  },
  methods: {
    async fetchSMSCredits () {
      try {
        const response = await API.Settings.smsCountry()

        if (response) {
          this.credits = response
        }
      } catch (e) {
        console.log(e)
      }
    },
    handleVariableClick (variable) {
      this._value += ` ${variable} `
      this.$refs.dropdown.close()
    },
    count () {
      const text = this._value.trim()
      const encoding = this.detectEncoding(text)
      let special

      const messageLength = {
        GSM_7BIT: 160,
        GSM_7BIT_EX: 160,
        UTF16: 70
      }

      const multiMessageLength = {
        GSM_7BIT: 153,
        GSM_7BIT_EX: 153,
        UTF16: 67
      }

      let length = text.length
      if (encoding === 'GSM_7BIT_EX') {
        length += this.countGsm7bitEx(text)
      }

      if (encoding === 'UTF16') {
        const unmatched = text.replace(this.gsm7bitExRegExpRev, '').replace(/\s/g, '')
        const unique = [...new Set(unmatched)]
        special = unique.join(', ')
      }

      let per_message = messageLength[encoding]
      if (length > per_message) {
        per_message = multiMessageLength[encoding]
      }

      const messages = Math.ceil(length / per_message)

      let remaining = (per_message * messages) - length
      if (remaining === 0 && messages === 0) {
        remaining = per_message
      }

      return {
        encoding,
        length,
        per_message,
        remaining,
        messages,
        special
      }
    },
    detectEncoding (text) {
      switch (false) {
        case text.match(this.gsm7bitRegExp) == null:
          return 'GSM_7BIT'
        case text.match(this.gsm7bitExRegExp) == null:
          return 'GSM_7BIT_EX'
        default:
          return 'UTF16'
      }
    },
    countGsm7bitEx (text) {
      let char2
      const chars = (function () {
        let _i, _len
        const _results = []
        for (_i = 0, _len = text.length; _i < _len; _i++) {
          char2 = text[_i]
          if (char2.match(this.gsm7bitExOnlyRegExp) != null) {
            _results.push(char2)
          }
        }
        return _results
      }.call(this))
      return chars.length
    }
  }
}
</script>

<!--// Todo - highlight placeholders and insert at position

<template>
  <div class="border rounded-md w-full bg-white">
    <div class="w-full border-b h-10 flex items-center px-1 py-2">
      <o-dropdown ref="dropdown">
        <o-button flat translucent size="slim" :hover="false" class="h-8">
          <o-icon icon="variable" />
        </o-button>

        <template v-slot:content>
          <div class="w-100 max-w-95vw p-2">
            <o-chip
              v-for="placeholder in placeholders"
              :key="placeholder"
              variant="info"
              flat
              square
              hover
              class="mb-1 mr-1 cursor-pointer"
              @click.native="insert(placeholder)"
            >
              {{ placeholder }}
            </o-chip>
          </div>
        </template>
      </o-dropdown>
    </div>
    <p
      ref="editable"
      contenteditable
      class="w-full p-2 h-48"
      v-on="listeners"
    >
      {{ value }}
    </p>
    <div class="border-t w-full bg-gray-100 px-2 py-1 flex items-center justify-between">
      <div>
        <o-tooltip text="Please note: a single SMS contains 160 characters (special characters, such as emojis, reduce this to 80 characters)">
          <o-chip v-if="counter && counter.special" variant="warning" flat size="sm" square>
            Your message contains special characters: {{ counter.special }}
          </o-chip>
        </o-tooltip>
      </div>
      <div class="text-sm font-semibold text-gray-700">
        <template v-if="counter">
          {{ counter.remaining }} / {{ counter.messages }}
        </template>
        <template v-else>
          160 / 0
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import placeholders from '@/mixins/placeholders'
import {
  ODropdown
} from '@/components/elements'


export default {
  name: 'o-sms',
  components: {
    ODropdown
  },
  mixins: [placeholders],
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    const gsm7bitChars = '@£$¥èéùìòÇ\\nØø\\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\\"#¤%&\'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà'
    const gsm7bitExChar = '\\^{}\\\\\\[~\\]|€'

    return {
      counter: null,
      gsm7bitRegExp: RegExp('^[' + gsm7bitChars + ']*$'),
      gsm7bitExRegExp: RegExp('^[' + gsm7bitChars + gsm7bitExChar + ']*$'),
      gsm7bitExRegExpRev: RegExp('[' + gsm7bitChars + gsm7bitExChar + ']+', 'g'),
      gsm7bitExOnlyRegExp: RegExp('^[\\' + gsm7bitExChar + ']*$')
    }
  },
  computed: {
    listeners () {
      return { ...this.$listeners, input: this.onInput }
    }
  },
  watch: {
    value () {
      this.counter = this.count()
    }
  },
  mounted () {
    this.$refs.editable.textContent = this.value
  },
  methods: {
    onInput (e) {
      this.$emit('input', e.target.textContent)
    },
    insert (placeholder) {
      this.$refs.dropdown.hide()
      this.$refs.editable.textContent += `{{${placeholder}}}`
    },
    count () {
      const text = this.value.trim()
      const encoding = this.detectEncoding(text)
      let special

      const messageLength = {
        GSM_7BIT: 160,
        GSM_7BIT_EX: 160,
        UTF16: 70
      }

      const multiMessageLength = {
        GSM_7BIT: 153,
        GSM_7BIT_EX: 153,
        UTF16: 67
      }

      let length = text.length
      if (encoding === 'GSM_7BIT_EX') {
        length += this.countGsm7bitEx(text)
      }

      if (encoding === 'UTF16') {
        const unmatched = text.replace(this.gsm7bitExRegExpRev, '').replace(/\s/g, '')
        const unique = [...new Set(unmatched)]
        special = unique.join(', ')
      }

      let per_message = messageLength[encoding]
      if (length > per_message) {
        per_message = multiMessageLength[encoding]
      }

      const messages = Math.ceil(length / per_message)

      let remaining = (per_message * messages) - length
      if (remaining === 0 && messages === 0) {
        remaining = per_message
      }

      return {
        encoding,
        length,
        per_message,
        remaining,
        messages,
        special
      }
    },
    detectEncoding (text) {
      switch (false) {
        case text.match(this.gsm7bitRegExp) == null:
          return 'GSM_7BIT'
        case text.match(this.gsm7bitExRegExp) == null:
          return 'GSM_7BIT_EX'
        default:
          return 'UTF16'
      }
    },
    countGsm7bitEx (text) {
      let char2
      const chars = (function () {
        let _i, _len
        const _results = []
        for (_i = 0, _len = text.length; _i < _len; _i++) {
          char2 = text[_i]
          if (char2.match(this.gsm7bitExOnlyRegExp) != null) {
            _results.push(char2)
          }
        }
        return _results
      }.call(this))
      return chars.length
    }
  }
}
</script>

<style scoped>
.sms-placeholder {
  background: red
}
</style>

-->
