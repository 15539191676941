import Vue from 'vue'

import {
  format as Format,
  parse as Parse,
  add as Add,
  sub as Sub,
  addMinutes,
  subMinutes,
  startOfDay,
  startOfWeek,
  startOfMonth,
  startOfYear,
  startOfQuarter,
  getTime,
  isBefore,
  differenceInMinutes
} from 'date-fns'

import { TimeFormat } from '../api'

export const plugin = {
  format (date, format = 'yyyyMMdd') {
    return Format(date, format)
  },
  parse (date, format = 'yyyyMMdd') {
    return Parse(date, format, new Date())
  },
  convert (date, inputFormat, outputFormat) {
    const parsed = this.parse(date, inputFormat)
    return this.format(parsed, outputFormat)
  },
  utc (date) {
    return addMinutes(date, date.getTimezoneOffset())
  },
  timezone (date) {
    return subMinutes(date, date.getTimezoneOffset())
  },
  local (date) {
    const offset = Vue?.prototype?.authPlugin?.info?.location?.timeZoneOffset || 0
    const utc = new Date(new Date(date).toUTCString().slice(0, -4))
    const local = this.add(utc, { seconds: offset })

    return local
  },
  timestamp (date) {
    return Math.floor(getTime(date) / 1000)
  },
  now () {
    return Date.now()
  },
  add (date, duration, inputFormat, outputFormat) {
    if (inputFormat) {
      date = this.parse(date, inputFormat)
    }

    date = Add(date, duration)

    if (outputFormat) {
      date = this.format(date, outputFormat)
    }

    return date
  },
  sub (date, duration, inputFormat, outputFormat) {
    if (inputFormat) {
      date = this.parse(date, inputFormat)
    }

    date = Sub(date, duration)

    if (outputFormat) {
      date = this.format(date, outputFormat)
    }

    return date
  },
  minutes (total, format = this.presets.time) {
    /* const day = startOfDay(new Date())
    const time = this.add(day, { minutes })

    console.log({ day, minutes, time })

    if (format) {
      return this.format(time, format)
    } */

    const hours = Math.floor(total / 60)
    const minutes = total % 60

    const date = new Date(null, null, null, hours, minutes)

    if (format) {
      return this.format(date, format)
    }

    return date
  },
  getMinutes (val) {
    const date = startOfDay(val)
    const time = differenceInMinutes(val, date)

    return time
  },
  getStartOfDayUTC (val) {
    const utc = startOfDay(val)
    const date = Sub(utc, { minutes: utc.getTimezoneOffset() })

    return date
  },
  isBefore (a, b) {
    return isBefore(a, b)
  },
  startOf (date, unit) {
    if (['day', 'days'].includes(unit)) {
      return startOfDay(date)
    }
    if (['week', 'weeks'].includes(unit)) {
      return startOfWeek(date)
    }
    if (['month', 'months'].includes(unit)) {
      return startOfMonth(date)
    }
    if (['quarter', 'quarters'].includes(unit)) {
      return startOfQuarter(date)
    }
    if (['year', 'years'].includes(unit)) {
      return startOfYear(date)
    }
  },
  presets: {
    get time () {
      const format = Vue?.prototype?.authPlugin?.info?.location?.timeFormat

      if (format && format === TimeFormat['24']) {
        return 'HH:mm'
      }

      return 'h:mm a'
    },
    get full () {
      return `EEEE, MMMM do yyyy ${this.time}`
    },
    get compact () {
      return `EEE, do MMM yy ${this.time}`
    },
    long: 'EEEE, MMMM do yyyy',
    short: 'dd/MM/yyyy'
  }
}

export default (context, inject) => {
  inject('date', plugin)
}
