import { extend } from 'vee-validate'
import { required, email, min_value, between, numeric, min, max, length, digits, integer, alpha_num } from 'vee-validate/dist/rules'

extend('alpha_num', {
  ...alpha_num,
  message: '{_field_} must only contain alpha-numeric characters'
})

extend('email', {
  ...email,
  message: '{_field_} is not a valid email address'
})

extend('required', {
  ...required,
  message: 'This field is required'
})

extend('min_value', {
  ...min_value,
  message: '{_field_} must be greater than {min}'
})

extend('between', {
  ...between,
  message: '{_field_} must be between {min} and {max}'
})

extend('numeric', {
  ...numeric,
  message: '{_field_} must be a number'
})

extend('min', {
  ...min,
  message: '{_field_} must be a longer than {min} characters'
})

extend('max', {
  ...max,
  message: '{_field_} must be a shorter than {length} characters'
})

extend('length', {
  ...length,
  message: '{_field_} must be a exactly {length} characters'
})

extend('digits', {
  ...digits,
  message: 'The {_field_} must be numeric and exactly contain {digits} digits'
})

extend('integer', {
  ...integer,
  message: 'The {_field_} must only include numbers'
})

extend('greater', {
  params: ['target'],
  validate (value, { target }) {
    return value > target
  },
  message: '{_field_} must be greater than {target}'
})

extend('timeStartFinish', {
  params: ['target'],
  validate (value, { target }) {
    return value > target
  },
  message: '{_field_} must be later than {target}'
})

extend('password', {
  params: ['target'],
  validate (value, { target }) {
    return value === target
  },
  message: 'Password confirmation does not match'
})

extend('pin', {
  params: ['target'],
  validate (value, { target }) {
    return value === target
  },
  message: 'Pin confirmation does not match'
})

extend('currency', {
  validate (value) {
    const regex = /\d{0,2}(\.\d{1,2})?/
    return regex.test(value)
  },
  message: '{_field_} is not a valid amount'
})
