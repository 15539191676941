<template>
  <li class="my-px">
    <a
      class="flex flex-row items-center h-10 rounded-lg text-gray-300 relative -mx-1 hover:bg-white hover:bg-opacity-15 transition-colors cursor-pointer"
      :class="[
        collapse ? 'px-3' : 'px-4',
      ]"
      @click="toggle"
    >
      <div class="w-full flex items-center">
        <span
          class="flex items-center justify-center text-lg text-gray-400"
          :class="[
            collapse ? '-ml-px' : '',
          ]"
        >
          <o-icon
            icon="message"
            :size="18"
          />
        </span>
        <span class="ml-5 text-sm" :class="[ collapse? 'sm:hidden' : '']">Support</span>
      </div>
    </a>
  </li>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      collapse: 'menu'
    })
  },
  methods: {
    toggle () {
      if (window.Beacon) {
        window.Beacon('toggle')
      }
    }
  }
}

</script>
