<template>
  <div class="list-complete-item pointer-events-auto w-full max-w-xs p-4 text-gray-700 bg-white rounded-lg shadow mb-4 mx-auto relative z-80000">
    <div class="flex items-start justify-between w-full">
      <div class="flex">
        <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg" :class="[colour, background]">
          <o-icon :icon="icon" :size="22" />
        </div>
        <div class="ml-3 text-sm font-normal">
          <span class="mb-1 text-sm font-medium text-gray-900">
            {{ item.title || 'Notification' }}
          </span>
          <div v-if="item.text" class="text-sm font-normal">
            {{ item.text }}
          </div>
          <div v-if="item.action" class="mt-3">
            <o-button :variant="item.variant" size="xs" @click="handleClick()">
              {{ item.action.label }}
            </o-button>

            <o-button outline size="xs" @click="$emit('close')">
              Dismiss
            </o-button>
          </div>
        </div>
      </div>
      <o-icon
        icon="x"
        class="cursor-pointer"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    icon () {
      switch (this.item.variant) {
        case 'success':
          return 'success'
        case 'danger':
          return 'danger'
        case 'warning':
          return 'warning'
        case 'info':
          return 'info'
        default:
          return 'notification'
      }
    },
    colour () {
      switch (this.item.variant) {
        case 'success':
          return 'text-green-500'
        case 'danger':
          return 'text-red-500'
        case 'warning':
          return 'text-yellow-500'
        case 'info':
          return 'text-blue-500'
        default:
          return 'text-blue-500'
      }
    },
    background () {
      switch (this.item.variant) {
        case 'success':
          return 'bg-green-100'
        case 'danger':
          return 'bg-red-100'
        case 'warning':
          return 'bg-yellow-100'
        case 'info':
          return 'bg-blue-100'
        default:
          return 'bg-blue-100'
      }
    }
  },
  methods: {
    handleClick () {
      this.item.action.click()
      this.$emit('close')
    }
  }
}
</script>
