<template>
  <div>
    <div>
      <o-dropdown
        ref="dropdown"
        class="w-full"
        :arrow="false"
        placement="bottom-start"
        detect-overflow
        same-width
        :disabled="disabled"
        :trigger="[]"
        :clickaway="false"
      >
        <o-field-group label="Address line 1" class="mb-4 w-full">
          <text-field
            v-model="_value.address"
            leading="address"
            autocomplete="new-password"
            :loading="loading"
            @input="fetchPredictions"
            @blur="handleInputBlur"
            @focus="handleInputFocus"
            @keyup.up="handleInputKeyUp(-1)"
            @keyup.down="handleInputKeyUp(1)"
            @keyup.enter="handleInputEnter"
          />
        </o-field-group>

        <template #content="{ overflow }">
          <div
            class="text-sm flex flex-col"
            :style="{
              maxHeight: overflow && overflow.bottom > 0 ? `${400 - overflow.bottom}px` : '400px'
            }"
          >
            <o-scroll v-if="result.predictions && result.predictions.length">
              <ul
                v-for="(prediction, index) in result.predictions"
                :key="index"
              >
                <li
                  :class="[
                    'cursor-pointer select-none relative py-2 px-3  transition-colors border-b last:border-none',
                    focused === index ? 'bg-blue-100 text-blue-700' : 'text-gray-900'
                  ]"
                  role="option"
                  @click="handlePredictionClick(prediction)"
                  @mouseenter="focused = index"
                >
                  {{ prediction.description }}
                </li>
              </ul>
            </o-scroll>

            <p v-else class="text-center px-4 py-2 text-gray-600">
              No matching address found...
            </p>
          </div>
        </template>
      </o-dropdown>

      <div class="flex space-x-4 mb-4">
        <o-field-group class="w-full" label="Address Line 2">
          <text-field v-model="_value.address2" />
        </o-field-group>
      </div>

      <div class="flex space-x-4 mb-4">
        <o-field-group class="w-full md:w-1/2" label="Suburb">
          <text-field v-model="_value.suburb" />
        </o-field-group>
        <o-field-group class="w-full md:w-1/2" label="City">
          <text-field v-model="_value.city" />
        </o-field-group>
      </div>
      <div class="flex space-x-4 mb-4">
        <o-field-group class="w-full md:w-1/2" label="Region">
          <text-field v-model="_value.region" />
        </o-field-group>
        <o-field-group class="w-full md:w-1/2" label="Postcode">
          <text-field v-model="_value.postcode" />
        </o-field-group>
      </div>
      <div class="flex space-x-4 mb-4">
        <o-field-group class="w-full md:w-1/2" label="Country">
          <text-field v-model="_value.country" />
        </o-field-group>
      </div>
    </div>

    <div id="address-autocomplete-tag" />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

import {
  TextField
} from '@/components/fields'

const apiKey = 'AIzaSyABQHXjNMJzrbZqVU0F5DOUVIbWidGb-MU'

export default {
  name: 'OAddress',
  components: {
    TextField
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      result: null,
      sessionToken: null,
      focused: -1
    }
  },
  computed: {
    _value: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  mounted () {
    if (typeof google === 'undefined') {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`
      document.head.appendChild(script)
    }
  },
  methods: {
    fetchPredictions: debounce(async function () {
      this.loading = true

      try {
        if (!this.sessionToken) {
          this.sessionToken = new window.google.maps.places.AutocompleteSessionToken()
        }

        const request = {
          input: this._value.address,
          types: ['address'],
          sessionToken: this.sessionToken
        }

        /* if (location) {
          request.location = {
            let: '',
            lng: ''
          }
        } */

        const AutocompleteService = new window.google.maps.places.AutocompleteService()
        const result = await AutocompleteService.getPlacePredictions(request)

        this.result = result
        this.loading = false

        this.$refs.dropdown.open()
      } catch (e) {
        console.log(e)
      }
    }, 500),
    fetchPlace (prediction) {
      try {
        const request = {
          placeId: prediction.place_id,
          sessionToken: this.sessionToken
        }

        const element = document.getElementById('address-autocomplete-tag')
        const PlacesService = new window.google.maps.places.PlacesService(element)

        PlacesService.getDetails(request, (result) => {
          if (result) {
            this.setAddress(result)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    setAddress (result) {
      let address = ''
      // let suburb = ''
      let city = ''
      let region = ''
      let postcode = ''
      let country = ''

      for (const component of result.address_components) {
        const componentType = component.types[0]

        switch (componentType) {
          case 'street_number': {
            address = `${component.long_name} ${address}`
            break
          }

          case 'route': {
            address += component.short_name
            break
          }

          case 'postal_code_prefix': {
            postcode = `${component.long_name}-${postcode} `
            break
          }

          case 'postal_code': {
            postcode = `${component.long_name}${postcode}`
            break
          }

          case 'postal_code_suffix': {
            postcode = `${postcode}-${component.long_name}`
            break
          }

          case 'locality': {
            city = component.long_name
            break
          }

          case 'postal_town': {
            region = component.long_name
            break
          }

          case 'administrative_area_level_1': {
            // State
            if (!region) {
              region = component.short_name
            }
            break
          }

          case 'country': {
            country = component.long_name
            break
          }
        }
      }

      this._value.address = address
      this._value.address2 = ''
      this._value.suburb = ''
      this._value.city = city
      this._value.region = region
      this._value.postcode = postcode
      this._value.country = country
    },
    handlePredictionClick (prediction) {
      this.fetchPlace(prediction)
      this.$refs.dropdown.close()
    },
    handleInputBlur () {
      setTimeout(() => {
        this.$refs.dropdown.close()
      }, 200)
    },
    handleInputFocus () {
      if (this.result?.predictions) {
        this.$refs.dropdown.open()
      }
    },
    handleInputKeyUp (val) {
      const predictions = this.result?.predictions

      if (predictions) {
        const focused = this.focused
        const next = focused + val

        if (next > -1 && next < predictions.length) {
          this.focused = next
        }
      }
    },
    handleInputEnter () {
      const predictions = this.result?.predictions

      if (predictions) {
        const focused = this.focused
        const prediction = predictions[focused]
        this.fetchPlace(prediction)
        this.$refs.dropdown.close()
      }
    }
  }
}
</script>
