import UAParser from 'ua-parser-js'

import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'

import Location from './location'
import Employee from './employee'

export default class Auth extends Model {
  static modelName () {
    return 'auth'
  }

  static device (user_agent) {
    const parser = new UAParser(user_agent)
    const agent = parser.getResult()
    const device_name = `${agent.os.name} ${agent.os.version} (${agent.browser.name} ${agent.browser.major})`

    return device_name
  }

  static domain (domain) {
    const url = this.modelBaseURL() + '/domain?domain=' + domain
    return this.requestItem(Request.get(url), AuthDomain)
  }

  static login ({ oauth_client, location, email, password }) {
    const url = this.modelBaseURL() + '/login'
    const device_name = this.device()

    const params = {
      oauth_client,
      location,
      email,
      password,
      device_name
    }

    return this.requestAuth(Request.post(url, JSON.stringify(params)))
  }

  static requestCode ({ oauth_client, location, email }) {
    const url = this.modelBaseURL() + '/requestCode'
    const params = {
      oauth_client,
      location,
      email
    }

    return this.requestData(Request.post(url, JSON.stringify(params)))
  }

  static codeLogin ({ oauth_client, location, email, token, code }) {
    const url = this.modelBaseURL() + '/codeLogin'
    const device_name = this.device()

    const params = {
      oauth_client,
      location,
      email,
      token,
      code,
      device_name
    }

    return this.requestAuth(Request.post(url, JSON.stringify(params)))
  }

  static tokenLogin ({ oauth_client, token }) {
    const url = this.modelBaseURL() + '/tokenLogin'
    const device_name = this.device()

    const params = {
      oauth_client,
      token,
      device_name
    }

    return this.requestAuth(Request.post(url, JSON.stringify(params)))
  }

  /* static forgotDomain ({ oauth_client, email, token, code }) {
    const url = this.modelBaseURL() + '/forgotDomain'
    const params = {
      oauth_client,
      email,
      token,
      code
    }

    return this.requestData(Request.post(url, JSON.stringify(params)))
  } */

  static accounts ({ oauth_client, email, token, code }) {
    const url = this.modelBaseURL() + '/accounts'
    const params = {
      oauth_client,
      email,
      token,
      code
    }

    return this.requestData(Request.post(url, JSON.stringify(params)))
  }

  static resetPassword ({ oauth_client, location, email, password, confirm, token, code }) {
    const url = this.modelBaseURL() + '/resetPassword'
    const params = {
      oauth_client,
      location,
      email,
      password,
      confirm,
      token,
      code
    }

    return this.requestSuccess(Request.post(url, JSON.stringify(params)))
  }

  static changePassword ({ oldPassword, newPassword, confirmPassword }) {
    const url = this.modelBaseURL() + '/changePassword'
    const params = {
      old_password: oldPassword,
      new_password: newPassword,
      new_confirm: confirmPassword
    }

    return this.requestSuccess(Request.post(url, JSON.stringify(params)))
  }

  static changePin ({ password, newPin, confirmPin }) {
    const url = this.modelBaseURL() + '/changePin'
    const params = {
      password,
      pin: newPin,
      confirm: confirmPin
    }

    return this.requestSuccess(Request.post(url, JSON.stringify(params)))
  }

  static tokenFromAuthorizationCode (client_id, redirect_uri, scope, code, user_agent) {
    const url = this.modelBaseURL() + '/token'
    const device_name = this.device(user_agent)

    const params = {
      grant_type: 'authorization-code',
      client_id,
      redirect_uri,
      code,
      scope,
      device_name
    }

    return this.requestAuth(Request.post(url, JSON.stringify(params)))
  }

  static tokenFromRefreshToken (client_id, scope, refreshToken, user_agent) {
    const url = this.modelBaseURL() + '/token'
    const device_name = this.device(user_agent)

    const params = new URLSearchParams()
    params.append('grant_type', 'refresh-token')
    params.append('client_id', client_id)
    params.append('refresh_token', refreshToken)
    params.append('scope', scope)
    params.append('device_name', device_name)

    const request = Request.post(url, params)

    request.authenticated = false
    request.headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
    return this.requestAuth(request)
  }

  static checkPin ({ pin }) {
    const url = this.modelBaseURL() + '/checkPin?pin=' + pin
    return this.requestSuccess(Request.get(url))
  }

  static info () {
    const url = this.modelBaseURL() + '/info'

    return this.requestItem(Request.get(url), Info)
  }

  static plans () {
    const url = this.modelBaseURL() + '/plans'
    return this.requestList(Request.get(url), PlanInfo)
  }

  static resendVerificationEmail () {
    const url = this.modelBaseURL() + '/resendVerificationEmail'
    return this.requestData(Request.get(url))
  }

  static verifyEmail ({ location, code } = {}) {
    const url = this.modelBaseURL() + '/verifyEmail?location=' + location + '&code=' + code
    return this.requestSuccess(Request.get(url))
  }
}

export class AuthDomain extends APIObject {

}

export class AuthResponse extends APIObject {
}

export class Info extends APIObject {
  relations () {
    return {
      location: { type: Location },
      employee: { type: Employee },
      plan: { type: Plan },
      panels: { type: Panel }
    }
  }

  get user () {
    const item = this.employee ? this.employee : this.location
    if (item) {
      return {
        firstName: item.firstName,
        lastName: item.lastName,
        image: item.images ? item.images.square : null
      }
    }

    return null
  }

  get minuteSize () {
    const scheduleDisplaySize = this.location.scheduleDisplaySize
    return 2 / (scheduleDisplaySize / 15)
  }

  get slotSize () {
    const scheduleDisplaySize = this.location.scheduleDisplaySize
    return scheduleDisplaySize
  }
}

export class Plan extends APIObject {
  relations () {
    return {
      state: { type: PlanSubscriptionState }
    }
  }

  get isPlanSubscriptionStateTrial () {
    return this.state === PlanSubscriptionState.trial
  }

  get isPlanSubscriptionStateActive () {
    return this.state === PlanSubscriptionState.active
  }

  get isPlanSubscriptionStateWarning () {
    return this.state === PlanSubscriptionState.warning
  }

  get isPlanSubscriptionStatePaymentError () {
    return this.state === PlanSubscriptionState.paymentError
  }

  get isPlanSubscriptionStateCancelled () {
    return this.state === PlanSubscriptionState.cancelled
  }

  get isPlanSubscriptionStateSuspended () {
    return this.state === PlanSubscriptionState.suspended
  }

  get isPlanSubscriptionStateInactive () {
    return this.state === PlanSubscriptionState.inactive
  }

  get isPlanSubscriptionStatePrepaid () {
    return this.state === PlanSubscriptionState.prepaid
  }

  get isPlanSubscriptionStateTemporary () {
    return this.state === PlanSubscriptionState.temporary
  }
}

export class Panel extends APIObject {
  relations () {
    return {
      action: { type: PanelAction }
    }
  }
}

export class PanelAction extends APIObject {
}

export class PlanInfo extends APIObject {
}

export const PlanSubscriptionState = new Enum({
  trial: { value: 0, label: 'Trial' },
  active: { value: 1, label: 'Active' },
  warning: { value: 2, label: 'Warning' },
  payment_error: { value: 3, label: 'Payment Error' },
  cancelled: { value: 4, label: 'Cancelled' },
  suspended: { value: 7, label: 'Suspended' },
  inactive: { value: 5, label: 'Inactive' },
  free: { value: 6, label: 'Free' },
  temporary: { value: 8, label: 'Temporary' }
})
