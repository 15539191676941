<template>
  <div class="h-screen w-screen fixed inset-0 bg-gray-100 flex items-center gradient-background z-80000">
    <div class="mx-auto max-w-md px-5 text-gray-700 text-center">
      <img src="~/assets/images/illustrations/error-404.svg" class="mb-12 mx-auto">
      <div class="text-6xl font-dark font-bold leading-tight">
        {{ error.statusCode || 'Error' }}
      </div>
      <p class="text-2xl font-light leading-normal">
        {{ error.message || 'Sorry, something went wrong...' }}
      </p>
      <p class="mb-8 mt-8">
        Contact hello@ovatu.com if you need some help
      </p>

      <o-button to="/" size="lg" variant="info">
        Back to safety
      </o-button>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    error: {
      type: [Number, String, Array, Object, Boolean]
    }
  },
  mounted () {
    console.log(this.error)
  }
}
</script>

<style lang="scss">
  $gradient-2: rgb(0, 154, 255);
  $gradient-1: rgb(24, 198, 82);
  $gradient-3: rgb(68, 93, 140);
  $gradient-4: yellow;

  .gradient-background {
    background: {
      image:
        radial-gradient(rgba($gradient-1, 0.5), rgba($gradient-1, 0) 40vw),
        radial-gradient(rgba($gradient-2, 0.3), rgba($gradient-2, 0) 40vw),
        radial-gradient(rgba($gradient-3, 0.5), rgba($gradient-3, 0) 40vw);
      position:
        -40vw 14rem,
        50% 10rem,
        60vw 14rem,
        -10vw calc(14rem + 20vw),
        30vw calc(14rem + 20vw);
      size: 80vw 80vw;
      repeat: no-repeat;
    }
  }
</style>
