import { startOfMonth, endOfMonth, startOfDay, endOfDay, sub } from 'date-fns'

export default {
  today: {
    name: 'Today',
    value: {
      start: startOfDay(new Date()),
      finish: endOfDay(new Date())
    }
  },
  oneWeek: {
    name: '1 Week',
    value: {
      start: startOfDay(sub(new Date(), { days: 7 })),
      finish: endOfDay(new Date())
    }
  },
  twoWeeks: {
    name: '2 Weeks',
    value: {
      start: startOfDay(sub(new Date(), { days: 14 })),
      finish: endOfDay(new Date())
    }
  },
  threeWeeks: {
    name: '3 Weeks',
    value: {
      start: startOfDay(sub(new Date(), { days: 21 })),
      finish: endOfDay(new Date())
    }
  },
  thisMonth: {
    name: 'This Month',
    value: {
      start: startOfDay(startOfMonth(new Date())),
      finish: endOfDay(endOfMonth(new Date()))
    }
  },
  lastMonth: {
    name: 'Last Month',
    value: {
      start: startOfDay(startOfMonth(sub(new Date(), { months: 1 }))),
      finish: endOfDay(endOfMonth(sub(new Date(), { months: 1 })))
    }
  },
  future: {
    name: 'Future',
    value: {
      start: startOfDay(startOfMonth(sub(new Date(), { months: 1 }))),
      finish: null
    }
  },
  past: {
    name: 'Past',
    value: {
      start: null,
      finish: endOfDay(endOfMonth(sub(new Date(), { months: 1 })))
    }
  }
}
