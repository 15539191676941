var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col justify-between h-full fixed z-20 sm:relative transition-all",class:[
    _vm.collapse ? 'w-60 sm:w-16' : 'min-w-60 w-60',
    _vm.mobile ? 'left-0' : '-left-60 sm:left-0',
    _vm.backgroundColour
  ]},[_c('o-scroll',[_c('div',{staticClass:"flex flex-col justify-between h-full"},[_c('div',[_c('div',{staticClass:"p-4 flex items-center justify-between",class:[ _vm.collapse ? 'sm:flex-col sm:items-center' : '']},[_c('img',{staticClass:"block h-9 opacity-85 -ml-1",class:[ _vm.collapse ? 'sm:hidden' : ''],attrs:{"src":require("assets/images/logo/logo-white-full.png")}}),_vm._v(" "),_c('img',{staticClass:"block w-9 opacity-85 mb-6",class:[ _vm.collapse ? 'hidden sm:block' : 'hidden' ],attrs:{"src":require("assets/images/logo/logo-white-icon.png")}}),_vm._v(" "),_c('button',{staticClass:"hidden sm:flex opacity-85 hover:bg-white hover:bg-opacity-15 p-2 rounded-md transition-all",class:[ _vm.collapse ? 'ml-px' : '0' ],on:{"click":_vm.toggleMenu}},[_c('o-icon',{staticClass:"text-white opacity-75",attrs:{"icon":"menuHamburger","size":18}})],1),_vm._v(" "),_c('o-button',{staticClass:"flex sm:hidden opacity-85",class:[ _vm.collapse ? 'ml-1' : '0' ],attrs:{"icon":"x","transparent":"","icon-size":20},on:{"click":_vm.toggleMobile}})],1),_vm._v(" "),(!_vm.collapse && _vm.admin)?_c('div',{staticClass:"p-4"},[_c('div',{staticClass:"p-3 rounded-lg bg-white bg-opacity-15 flex items-center text-white text-sm text-opacity-75 cursor-pointer leading-none"},[_c('o-icon',{class:[
                _vm.collapse ? '' : 'mr-3 ml-1' ],attrs:{"icon":"warning","size":14}}),_vm._v(" "),_c('div',{class:[ _vm.collapse? 'sm:hidden' : '']},[_c('p',[_vm._v("You are currently logged in as an admin user")])])],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"p-4"},[_c('div',{staticClass:"rounded-lg bg-white bg-opacity-15 flex items-center text-white text-sm text-opacity-75 cursor-pointer hover:bg-white hover:text-secondary transition leading-none",class:[
              _vm.collapse ? '-ml-px py-3 px-3 sm:p-0 sm:h-9 sm:w-9 sm:justify-center' : 'py-3 px-3' ],on:{"click":_vm.toggleSearch}},[_c('o-icon',{class:[
                _vm.collapse ? '' : 'mr-3 ml-1' ],attrs:{"icon":"search","size":14}}),_vm._v(" "),_c('span',{class:[ _vm.collapse? 'sm:hidden' : '']},[_vm._v("Search")])],1)]),_vm._v(" "),_c('ul',{staticClass:"flex flex-col w-full p-4"},[_vm._l((_vm.items.primary),function(item){return [_c('item',{key:item.slug,attrs:{"item":item}})]})],2)]),_vm._v(" "),_c('div',[_c('ul',{staticClass:"flex flex-col w-full p-4"},[_c('alerts'),_vm._v(" "),_vm._l((_vm.items.secondary),function(item){return [_c('item',{key:item.slug,attrs:{"item":item}})]}),_vm._v(" "),(!_vm.demo)?_c('support'):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"w-full text-white opacity-85 p-4 border-t border-opacity-10 border-white"},[_c('account')],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }