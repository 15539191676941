

export default async function (session, firebase) {
  // Can only be initiated on client side
  if (!process.client) {
    return
  }

  await import('firebase/compat/messaging')

  if (firebase.messaging.isSupported()) {
    const messagingService = session.messaging()

    messagingService.getToken({vapidKey: "BNLYh60ZbKfPVL2CYM9n5k0pyEgrIvunF4y_pf0odLu55V7hndKqVxyOHiPITaleHvajtLphJ-6JahE9zG6EGik"})

    return messagingService
  }
}
