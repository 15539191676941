import Model, { Request } from '../model'
import Enum from '../enums'

export default class CustomerCreditCard extends Model {
  static modelName () {
    return 'customerCreditCard'
  }

  objectID () {
    return this.idCustomerCreditCard
  }

  relations () {
    return {
      processor: { type: CustomerCreditCardProcessor }
    }
  }

  get isProcessorStripe () {
    return this.processor === CustomerCreditCardProcessor.stripe
  }

  get isProcessorSquare () {
    return this.processor === CustomerCreditCardProcessor.square
  }
}

export const CustomerCreditCardProcessor = new Enum({
  stripe: { value: 0, description: 'Stripe' },
  square: { value: 1, description: 'Square' }
})
