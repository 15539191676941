<template>
  <div class="bg-white border-t">
    <div class="sm:hidden h-16 w-full grid grid-cols-5 py-1">
      <nuxt-link to="/schedule/day" class="text-secondary text-center flex flex-col items-center py-2 leading-none mx-2 mt-1">
        <o-icon class="mb-2 opacity-85" :size="18" icon="menuBookings" />
        <span class="text-xs text-opacity-75">Appointments</span>
      </nuxt-link>

      <nuxt-link to="/customer" class="text-secondary text-center flex flex-col items-center py-2 leading-none mx-2 mt-1">
        <o-icon class="mb-2 opacity-85" :size="18" icon="menuCustomers" />
        <span class="text-xs text-opacity-75">Customers</span>
      </nuxt-link>

      <div class="flex items-center">
        <button class="text-secondary text-center flex flex-col items-center justify-center leading-none mx-auto w-13 h-13 bg-white bg-opacity-15 rounded-lg" @click="setMobile(true)">
          <o-icon class="mb-2 opacity-85" :size="18" icon="menuHamburger" />
          <span class="text-xs text-opacity-75">Menu</span>
        </button>
      </div>

      <nuxt-link to="/roster" class="text-secondary text-center flex flex-col items-center py-2 leading-none mx-2 mt-1">
        <o-icon class="mb-2 opacity-85" :size="18" icon="menuRoster" />
        <span class="text-xs text-opacity-75">Roster</span>
      </nuxt-link>

      <button class="text-secondary text-center flex flex-col items-center py-2 leading-none mx-2 mt-1" @click="toggleSearch">
        <o-icon class="mb-2 opacity-85" :size="18" icon="menuSearch" />
        <span class="text-xs text-opacity-75">Search</span>
      </button>
    </div>
  </div>
</template>

<script>
import {  mapActions, mapMutations } from 'vuex'

export default {
  methods: {
    ...mapMutations([
      'setMobile'
    ]),
    ...mapActions([
      'toggleSearch'
    ])
  }
}
</script>
