var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center space-x-2"},[_c('div',{class:[ _vm.containerClass, _vm.statusClasses, { 'border-none' : _vm.group } ]},[_c('div',{staticClass:"w-1/3 flex-shrink flex-grow pr-3 v-80000"},[_c('v-date-picker',{ref:"start-date",attrs:{"value":_vm.date.start,"max-date":_vm.limit ? _vm.date.finish : null,"first-day-of-week":_vm.firstDayOfWeek,"popover":{
          visibility: 'focus',
          positionFixed: true
        }},on:{"input":_vm.handleStartChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var inputValue = ref.inputValue;
        var inputEvents = ref.inputEvents;
return [_c('div',{staticClass:"relative group flex items-center justify-between"},[_c('input',_vm._g({staticClass:"w-full bg-transparent text-gray-800 text-sm",attrs:{"type":"text","placeholder":"From"},domProps:{"value":inputValue}},inputEvents)),_vm._v(" "),_c('div',{staticClass:"absolute right-0 transition duration-200 opacity-0 h-4 text-red-600",class:{ 'group-hover:opacity-100 cursor-pointer' : _vm.date.start },on:{"click":function($event){_vm._value.start = null}}},[_c('o-icon',{attrs:{"icon":"x"}})],1)])]}}])})],1),_vm._v(" "),_c('div',{staticClass:"pr-3 -ml-3 opacity-50"},[_c('o-icon',{attrs:{"icon":"chevronRight","size":10}})],1),_vm._v(" "),_c('div',{staticClass:"w-1/3 flex-shrink flex-grow"},[_c('v-date-picker',{ref:"finish-date",attrs:{"value":_vm.date.finish,"min-date":_vm.limit ? _vm.date.start : null,"first-day-of-week":_vm.firstDayOfWeek,"popover":{
          visibility: 'focus'
        }},on:{"input":_vm.handleFinishChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var inputValue = ref.inputValue;
        var inputEvents = ref.inputEvents;
return [_c('div',{staticClass:"relative group flex items-center justify-between"},[_c('input',_vm._g({staticClass:"w-full bg-transparent text-gray-800 text-sm",attrs:{"type":"text","placeholder":"To"},domProps:{"value":inputValue}},inputEvents)),_vm._v(" "),_c('div',{staticClass:"absolute right-0 transition duration-200 opacity-0 h-4 text-red-600",class:{ 'group-hover:opacity-100 cursor-pointer' : _vm.date.finish },on:{"click":function($event){_vm._value.finish = null}}},[_c('o-icon',{attrs:{"icon":"x"}})],1)])]}}])})],1)]),_vm._v(" "),(_vm.options.length)?_c('o-dropdown',{scopedSlots:_vm._u([{key:"content",fn:function(){return _vm._l((_vm.options),function(preset,index){return _c('o-dropdown-item',{key:index,nativeOn:{"click":function($event){return _vm.set(preset)}}},[_vm._v("\n        "+_vm._s(preset.name)+"\n      ")])})},proxy:true}],null,false,2563030190)},[_c('o-button',{attrs:{"icon":"preset","flat":"","size":"sm"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }