import Model, { Request } from '../model'
import APIObject from '../object'

export default class Updates extends Model {
  static modelName () {
    return 'Updates'
  }

  static newsCount () {
    const url = this.modelBaseURL() + '/newsCount'
    return this.requestItem(Request.get(url), UpdatesNewsCount)
  }
}

export class UpdatesNewsCount extends APIObject {
}
