<template>
  <keep-alive>
    <component
      :is="isActive && 'TabKeeper'"
      :id="computedId"
      :aria-hidden="! isActive"
      class="tabs-component-panel h-full"
      role="tabpanel"
    >
      <div v-if="showHeader" class="w-full flex items-center justify-between px-5 py-2 relative">
        <div>
          <h2 class="font-medium text-gray-700">
            {{ name }}
          </h2>
          <p v-if="info" class="text-sm text-gray-600 font-normal">
            {{ info }}
          </p>
        </div>

        <slot name="control" />
      </div>
      <slot />
    </component>
  </keep-alive>
</template>

<script>
import TabKeeper from './TabKeeper'

export default {
  components: {
    TabKeeper
  },
  props: {
    id: {
      type: String,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    info: {
      type: String,
      default: null
    },
    icon: {
      type: [String, Array],
      default: null
    },
    alert: {
      type: [String, Number],
      default: null
    },
    prefix: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    tabClass: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isVisible: {
      type: Boolean,
      default: true
    },
    initial: {
      type: Boolean,
      default: false
    },
    scroll:{
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isActive: false,
    showHeader: false
  }),
  computed: {
    _isTab () {
      return true
    },
    header () {
      return this.prefix + this.name + this.suffix
    },
    computedId () {
      return this.id ? this.id : this.name.toLowerCase().replace(/ /g, '-')
    },
    hash () {
      if (this.isDisabled) {
        return '#'
      }
      return '#' + this.computedId
    }
  }
}
</script>
