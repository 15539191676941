import Model, { Request } from '../model'
import Enum from '../enums'
import FormItem from './formItem'
import ServiceForm from './serviceForm'

export default class Form extends Model {
  static modelName () {
    return 'form'
  }

  objectID () {
    return this.idForm
  }

  relations () {
    return {
      items: { type: FormItem },
      permission: { type: FormPermission },
      services: { type: ServiceForm }
    }
  }

  editItem (item) {
    const url = this.constructor.modelBaseURL() + '/editItem?id=' + this.objectID()
    return this.constructor.requestItem(Request.post(url, item.toJSON()), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  addItem (item) {
    const url = this.constructor.modelBaseURL() + '/addItem?id=' + this.objectID()
    return this.constructor.requestItem(Request.post(url, item.toJSON()), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  deleteItem (item) {
    const url = this.constructor.modelBaseURL() + '/deleteItem?id=' + this.objectID()
    return this.constructor.requestItem(Request.post(url, item.toJSON()), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  duplicate () {
    const url = this.constructor.modelBaseURL() + '/duplicate?id=' + this.objectID()
    return this.constructor.requestItem(Request.post(url), this.constructor)
  }
}

export const FormPermission = new Enum({
  open: { value: 0, description: 'Open' },
  secure: { value: 1, description: 'Secure' }
})
