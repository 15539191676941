<template>
  <div class="flex items-center justify-center space-x-2">
    <select-field v-if="week" v-model="_week" :options="weekOptions" leading="Week">
      <template #leading>
        <span class="text-sm text-gray-500 px-3 mr-2 border-r">Week</span>
      </template>
    </select-field>
    <select-field v-if="month" v-model="_month" :options="monthOptions" leading="Month">
      <template #leading>
        <span class="text-sm text-gray-500 px-3 mr-2 border-r">Month</span>
      </template>
    </select-field>
    <select-field v-if="quarter" v-model="_quarter" :options="quarterOptions" leading="Quarter">
      <template #leading>
        <span class="text-sm text-gray-500 px-3 mr-2 border-r">Quarter</span>
      </template>
    </select-field>
    <select-field v-model="_year" :options="yearOptions" leading="Year">
      <template #leading>
        <span class="text-sm text-gray-500 px-3 mr-2 border-r">Year</span>
      </template>
    </select-field>
  </div>
</template>

<script>
import SelectField from './Select'

export default {
  components: {
    SelectField
  },
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    week: {
      type: Boolean,
      default: false
    },
    month: {
      type: Boolean,
      default: false
    },
    quarter: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    _week: {
      get () {
        const day = this.$auth?.info?.location?.startDayOfWeek?.value
        const weekStartsOn = day === 7 ? 0 : day
        return this.$dateFns.getWeek(this._value, { weekStartsOn })
      },
      set (value) {
        const day = this.$auth?.info?.location?.startDayOfWeek?.value
        const weekStartsOn = day === 7 ? 0 : day
        let result = this.$dateFns.setWeek(this._value, value, { weekStartsOn })
        result = this.$dateFns.startOfWeek(result, { weekStartsOn })
        result = this.$date.add(result, { days: 1 })
        this._value = this.$date.getStartOfDayUTC(result)
      }
    },
    _month: {
      get () {
        const day = this.$auth?.info?.location?.startDayOfWeek?.value
        return this.$dateFns.getMonth(this._value)
      },
      set (value) {
        let result = this.$dateFns.setMonth(this._value, value)
        result = this.$dateFns.startOfMonth(result)
        result = this.$date.add(result, { days: 1 })
        this._value = this.$date.getStartOfDayUTC(result)
      }
    },
    _quarter: {
      get () {
        return this.$dateFns.getQuarter(this._value)
      },
      set (value) {
        let result = this.$dateFns.setQuarter(this._value, value)
        result = this.$dateFns.startOfQuarter(result)
        result = this.$date.add(result, { days: 1 })
        this._value = this.$date.getStartOfDayUTC(result)
      }
    },
    _year: {
      get () {
        return this.$dateFns.getYear(this._value)
      },
      set (value) {
        let result = this.$dateFns.setYear(this._value, value)
        result = this.$dateFns.endOfYear(result)
        result = this.$date.add(result, { days: 1 })
        this._value = this.$date.getStartOfDayUTC(result)
      }
    },
    _value: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    weekOptions () {
      const weekStartsOn = 1
      const _value = this._value
      const date = this.$dateFns.setWeek(_value, 1, { weekStartsOn })

      const options = [...Array(53).keys()]
        .map((key) => {
          const value = this.$dateFns.setWeek(date, key + 1, { weekStartsOn })
          const format = 'do MMM'

          const start = this.$dateFns.startOfWeek(value, { weekStartsOn })
          const startLabel = this.$date.format(start, format)

          const finish = this.$dateFns.endOfWeek(value, { weekStartsOn })
          const finishLabel = this.$date.format(finish, format)
          return {
            label: `Week ${key + 1} - ${startLabel} to ${finishLabel}`, // new Date(0, key).toLocaleString('en', { month: 'long' }),
            value: key + 1
          }
        })

      return options
    },
    monthOptions () {
      const options = [...Array(12).keys()]
        .map((key) => {
          return {
            label: new Date(0, key).toLocaleString('en', { month: 'long' }),
            value: key
          }
        })

      return options
    },
    quarterOptions () {
      return [
        { label: 'First', value: 1 },
        { label: 'Second', value: 2 },
        { label: 'Third', value: 3 },
        { label: 'Fourth', value: 4 }
      ]
    },
    yearOptions () {
      const max = new Date().getFullYear()
      const min = max - 15
      const options = []

      for (let i = max; i >= min; i--) {
        options.push(i)
      }

      return options
    }
  }
}
</script>
