<template>
  <div class="flex items-center" :class="{ 'pointer-events-none opacity-50' : disabled }">
    <div
      class="relative rounded-full w-12 h-6 transition duration-200 ease-linear"
      :class="[isChecked ? `bg-${checkedColour}` : `bg-${uncheckedColour}`]"
    >
      <label
        :for="id"
        class="absolute left-0 bg-white border-2 mb-2 w-6 h-6 rounded-full transition transform duration-100 ease-linear cursor-pointer"
        :class="[isChecked ? ['translate-x-full', `border-${checkedColour}`] : ['translate-x-0', `border-${uncheckedColour}`]]"
      />
      <input
        :id="id"
        v-model="_model"
        :value="value"
        :true-value="value"
        :false-value="uncheckedValue"
        type="checkbox"
        name="toggle"
        class="appearance-none w-full h-full active:outline-none focus:outline-none"
      >
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'model',
    event: 'input'
  },
  props: {
    value: {
      type: [String, Object, Number, Boolean, Array],
      default: true
    },
    uncheckedValue: {
      type: [String, Object, Number, Boolean, Array],
      default: false
    },
    model: {
      type: [String, Object, Number, Boolean, Array],
      default: null
    },
    checkedColour: {
      type: String,
      default: 'green-400'
    },
    uncheckedColour: {
      type: String,
      default: 'gray-400'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      id: this._uid
    }
  },
  computed: {
    _model: {
      get () {
        return this.model
      },
      set (model) {
        this.$emit('input', model)
        this.$emit('change', model)
      }
    },
    isArray () {
      return Array.isArray(this.model)
    },
    isChecked () {
      if (this.isArray) {
        return this._model.includes(this.value)
      }

      return this._model === this.value
    }
  }
}
</script>
