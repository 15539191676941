<template>
  <component
    :is="component"
    :placement="placement"
    :auto-hide="false"
    padding="p-0"
  >
    <slot v-if="!inline" :label="label" />

    <template v-slot:content="{ close }">
      <div
        :class="[
          inline ? 'border rounded' : 'md:w-80 max-w-full'
        ]"
      >
        <div class="flex items-center justify-between p-2">
          <o-button flat outline icon="chevronLeftDouble" size="sm" @click="handleYearClick(-1)" />
          <p class="font-medium text-lg text-gray-700">
            {{ _year }}
          </p>
          <o-button flat outline icon="chevronRightDouble" size="sm" @click="handleYearClick(1)" />
        </div>
        <div class="p-2">
          <div v-for="(row, index) in options" :key="index" class="flex items-center">
            <o-button
              v-for="month in row"
              :key="month.value"
              flat
              :variant="_month === month.value ? 'info' : undefined"
              class="flex-1 m-px"
              @click="handleMonthClick(month.value); close()"
            >
              {{ month.label }}
            </o-button>
          </div>
        </div>
      </div>
    </template>
  </component>
</template>

<script>
import { ODropdown } from '@/components/elements'

export default {
  components: {
    ODropdown
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    inline: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'bottom'
    }
  },
  data () {
    return {
      months: [
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 }
      ]
    }
  },
  computed: {
    _month: {
      get () {
        const month = this.value.month
        if (month && month > 0 && month < 13) {
          return this.value.month
        }

        return 1
      },
      set (value) {
        const month = value
        const year = this._year

        this.$emit('input', { month, year })
      }
    },
    _year: {
      get () {
        return this.value.year
      },
      set (value) {
        const year = value
        const month = this._month

        this.$emit('input', { month, year })
      }
    },
    component () {
      if (this.inline) {
        return 'div'
      }

      return ODropdown
    },
    options () {
      const months = this.months

      return months.reduce((acc, _, i) => {
        if (i % 3 === 0) {
          acc.push(months.slice(i, i + 3))
        }
        return acc
      }, [])
    },
    label () {
      const month = this._month
      const year = this._year

      const monthLabel = this.months.find(({ value }) => value === month).label

      return `${monthLabel} ${year}`
    }
  },
  methods: {
    handleMonthClick (value) {
      this._month = value
    },
    handleYearClick (increment) {
      this._year = this._year + increment
    }
  }
}
</script>
