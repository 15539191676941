<template>
  <transition name="slide-panel" appear>
    <div
      v-if="component"
      :class="[
        'o-panel overflow-hidden left-0 top-0 bottom-0 right-0 w-screen h-full fixed flex items-center pointer-events-none',
        isModeModal ? 'model-panel' : 'right-panel'
      ]"
    >
      <div :class="classes" :style="styles">
        <div
          v-if="order"
          class="h-full w-full absolute inset-0"
          @click="focus()"
        />
        <portal-target ref="extension" :name="extension" slim @change="handleExtensionPortalChange" />
        <div class="flex-grow bg-white rounded-xl shadow-lg overflow-hidden relative h-full" :class="order ? 'pointer-events-none' : 'pointer-events-auto'">
          <component
            :is="component"
            v-if="component"
            ref="component"
            :route="route"
            :panel="panel"
            :focused="focused"
            v-bind="panel.props"
            v-on="panel.events"
            @close="close()"
          />
          <portal-target :name="portal" multiple />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  provide () {
    return {
      panel: this
    }
  },
  props: {
    panel: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    count: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      component: null,
      route: null,
      panelMode: 'panel',
      extensionActive: false
    }
  },
  computed: {
    order () {
      return this.count - this.index
    },
    portal () {
      return this.panel.portal
    },
    extension () {
      return this.panel.extension
    },
    classes () {
      const classes = ['o-panel--container pointer-events-auto transition-all duration-200 flex']

      if (this.isModeModal) {
        classes.push('absolute inset-0 mx-auto h-90vh max-h-2xl my-auto xl:py-22 w-full')

        if (this.extensionActive) {
          classes.push('max-w-3xl')
        } else {
          classes.push('max-w-xl')
        }
      } else {
        classes.push('absolute right-0 top-0 my-0 h-full p-2 pt-10 md:p-4 md:pl-0 w-full')

        if (this.extensionActive) {
          classes.push('md:w-3/4 md:min-w-180 md:max-w-2xl')
        } else {
          classes.push('md:w-1/2 md:min-w-180 md:max-w-210')
        }
      }

      if (this.order) {
        classes.push('o-panel-stacked')
      } else {
        classes.push('o-panel-focused')
      }

      return classes
    },
    styles () {
      const order = this.order
      if (order) {
        return {
          '--transform': `-${50 * order}px`,
          '--scale' : 1 - (0.06 * order)
        }
      }

      return false
    },
    options () {
      return this.component ? this.component.meta.panel : false
    },
    focused () {
      return !this.order
    },
    isModePanel () {
      return this.panelMode === 'panel'
    },
    isModeModal () {
      return this.panelMode === 'modal'
    }
  },
  beforeMount () {
    const hash = this.panel.hash
    // const reference = this.$panel.panels.find(({ path }) => panel.path === path)
    const panel = this.$panel.findPanelByHash(hash)

    panel.component = this
  },
  created () {
    const account = this.$auth.active
    const mode = account.panel
    this.panelMode = mode

    setTimeout(() => {
      this.open()
    }, 100)
  },
  methods: {
    async open () {
      if (this.panel) {
        const resolvedPanel = this.$router.resolve(this.panel.path)
        const components = this.$router.getMatchedComponents(resolvedPanel.location)
        const [component] = components

        let resolvedComponent = null
        if (typeof component === 'function') {
          resolvedComponent = await new component()
        } else {
          resolvedComponent = component
        }

        if (resolvedComponent && resolvedComponent.meta && resolvedComponent.meta.panel) {
          this.component = resolvedComponent
          this.route = resolvedPanel.route
        } else {
          this.component = null
        }
      } else {
        this.component = null
      }
    },
    close (force = false) {
      return this.panel?.close(force)
    },
    mode () {
      const account = this.$auth.active
      const mode = account.panel === 'panel' ? 'modal' : 'panel'
      this.panelMode = mode
      return this.setPanelMode(mode)
    },
    setPanelMode (mode) {
      this.$store.commit('auth/setPanel', { mode })
    },
    focus () {
      this.$emit('focus')
    },
    handleExtensionPortalChange (value) {
      this.extensionActive = value
    }
  }
}
</script>

<style lang="scss">
/* Slide right in/out - right */

.slide-panel-leave-active,
.slide-panel-enter-active {
  transition: 0.2s;
}

.slide-panel-enter {
  opacity: 0;
  transform: translate(0, 200px);
}

.slide-panel-leave-to {
  opacity: 0;
  transform: translate(0, 200px);
}

.o-panel-stacked {
  transform: translateY(var(--transform)) scale(var(--scale));
  filter: brightness(0.8)
}

.o-panel-stacked:hover {
  bottom: 20px !important;
  filter: brightness(1) !important
}

@media (min-width: 768px) {
  .right-panel .o-panel-stacked {
    transform: translateX(var(--transform)) scale(var(--scale));
  }

  .right-panel .o-panel-stacked:hover {
    bottom: 0px !important;
    right: 20px !important;
    transform: translateX(var(--transform)) scale(var(--scale));
  }

  .right-panel.slide-panel-enter {
    opacity: 0;
    transform: translate(200px, 0);
  }

  .right-panel.slide-panel-leave-to {
    opacity: 0;
    transform: translate(200px, 0);
  }
}
</style>
