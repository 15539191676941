<template>
  <div class="h-full flex flex-col">
    <!--<div>
      <o-scroll
        class="flex overflow-x-auto overflow-y-hidden relative flex-row border-b border-gray-300"
        :class="[
          navClass
        ]"
        :y-axis="false"
        :x-axis="true"
      >
        <span
          class="absolute h-2px bottom-0 bg-blue-600 shadow transition-all ease-in-out duration-200"
          :style="underline"
        />

        <template v-for="(section, index) in sections">
          <button
            v-if="section.visible == undefined || section.visible"
            :key="index"
            :ref="'button-' + index"
            class="flex h-8 flex-shrink-0 items-center text-gray-600 text-sm mr-2 md:mr-6 -mb-px px-2 leading-none hover:text-blue-600 focus:outline-none"
            :class="[
              { 'text-blue-600 font-medium' : active === index },
            ]"
            @click="move(index)"
          >
            <o-icon v-if="section.icon" :icon="section.icon" class="lg:mr-3 p-2 md:p-0" />
            <span class="hidden lg:block">{{ section.name }}</span>
          </button>
        </template>
      </o-scroll>
    </div>-->

    <div
      class="flex-shrink h-10 flex-row border-b border-gray-300"
      :class="navClass"
    >
      <o-scroll x-axis :y-axis="false">
        <div
          class="flex overflow-x-auto overflow-y-hidden relative h-full items-center"
        >
          <span
            class="absolute h-px bottom-0 bg-blue-600 shadow transition-all ease-in-out duration-200"
            :style="underline"
          />
          <template v-for="(section, index) in sections">
            <button
              v-if="section.visible == undefined || section.visible"
              :key="index"
              :ref="'button-' + index"
              class="flex h-8 flex-shrink-0 items-center text-gray-600 text-sm mr-2 md:mr-6 -mb-px px-2 leading-none hover:text-blue-600 focus:outline-none"
              :class="[
                { 'text-blue-600 font-medium' : active === index },
              ]"
              @click="move(index)"
            >
              <o-icon v-if="section.icon" :icon="section.icon" class="lg:mr-3 p-2 md:p-0" />
              <span class="hidden lg:block">{{ section.name }}</span>
            </button>
          </template>
        </div>
      </o-scroll>
    </div>

    <o-scroll ref="scroll" class="flex-grow flex-shrink w-full h-full overflow-y-auto text-secondary" @onScroll="onScroll">
      <div ref="sections" class="divide-y divide-gray-300">
        <slot />
      </div>
    </o-scroll>
  </div>
</template>

<script>
export default {
  props: {
    navClass: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      active: 0,
      underline: {},
      children: []
    }
  },
  computed: {
    sections () {
      return this.getChildSections(this.children)
    },
    offsets () {
      const parent = this.$refs.scroll.$el.getBoundingClientRect().top
      const elements = this.$refs.sections.children
      const offsets = []

      for (let i = 0; i < elements.length; i++) {
        const offset = elements[i].offsetTop
        if (offset >= 0) {
          offsets.push(offset)
        }
      }

      return offsets
    }
  },
  watch: {
    active () {
      this.calc()
    }
  },
  created () {
    this.children = this.$children
  },
  mounted () {
    this.calc()
  },
  methods: {
    getChildSections (children) {
      const sections = []

      for (const child of children) {
        if (child._isSection) {
          sections.push(child)
        }

        sections.push(...this.getChildSections(child.$children))
      }

      return sections
    },
    select (index) {
      this.active = index
    },
    onScroll (val) {
      const scroll = val.target.scrollTop
      const offset = 0
      const position = scroll + offset
      let i = 0
      while (position >= this.offsets[i]) { i++ }
      this.active = (i ? i - 1 : 0)
    },
    move (index) {
      this.sections[index].$el.scrollIntoView()
    },
    calc () {
      const active = this.$refs['button-' + this.active]

      if (active !== undefined) {
        const left = active[0].offsetLeft + 'px'
        const width = active[0].getBoundingClientRect().width + 'px'
        this.underline = {
          left,
          width
        }
      }
    }
  }
}
</script>
