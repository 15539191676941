var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container',_vm._b({},'container',_vm.container,false),[_c('div',{staticClass:"h-full w-full flex items-center px-1"},[_c('v-date-picker',_vm._g({attrs:{"mode":"time","is24hr":_vm.is24hr,"popover":{
        placement: 'bottom-start',
        visibility: 'click'
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var inputValue = ref.inputValue;
      var inputEvents = ref.inputEvents;
      var togglePopover = ref.togglePopover;
return [_vm._t("default",function(){return [_c('input',_vm._g(_vm._b({staticClass:"h-full w-full bg-transparent",attrs:{"type":"text"},domProps:{"value":inputValue},on:{"click":togglePopover}},'input',_vm.attributes,false),inputEvents))]},null,{ inputValue: inputValue, inputEvents: inputEvents, togglePopover: togglePopover })]}}],null,true),model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}},_vm.handlers))],1)])}
var staticRenderFns = []

export { render, staticRenderFns }