<template>
  <div>
    <div class="w-full flex items-center justify-between text-sm font-medium mb-3">
      <div class="bg-green-200 text-green-600 rounded-full py-1 px-2 uppercase text-xs font-semibold tracking-wider">
        <span>{{ label }}</span>
      </div>

      <div class="text-green-500 leading-none">
        <span>{{ progress }}%</span>
      </div>
    </div>
    <div
      class="bg-gray-300 rounded h-3 w-full"
      role="progressbar"
      :aria-valuenow="progress"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <div
        class="relative overflow-hidden shimmer bg-green-400 rounded h-3 text-center text-white text-sm font-medium transition-all flex items-center justify-center"
        :style="`width: ${progress}%; transition: width 2s;`"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: 'Loading'
    }
  }
}
</script>

<style scoped>
.shimmer::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(233, 233, 233, 1) 0,
    rgba(233, 233, 233, 0.9) 50%,
    rgba(233, 233, 233, 0.8) 100%
  );
  animation: shimmer 2s ease-out infinite;
  content: "";
}

@keyframes shimmer {
  100% {
    transform: translateX(0%);
    opacity: 0;
  }
}

</style>
