var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container',_vm._b({staticClass:"relative w-full",attrs:{"base-class":[
    'w-full relative flex items-center h-9 text-gray-800 text-sm relative',
    _vm.disabled ? 'opacity-75 pointer-events-none' : ''
  ]},scopedSlots:_vm._u([{key:"leading",fn:function(){return [_vm._t("leading")]},proxy:true},{key:"trailing",fn:function(){return [_vm._t("trailing")]},proxy:true}],null,true)},'container',_vm.container,false),[_c('o-dropdown',{ref:"dropdown",staticClass:"w-full",attrs:{"arrow":false,"placement":"bottom-start","container-class":"-mx-2","detect-overflow":"","disabled":_vm.disabled},scopedSlots:_vm._u([{key:"content",fn:function(ref){
  var overflow = ref.overflow;
return [_c('div',{staticClass:"w-80 text-sm flex flex-col",style:({
          height: overflow && overflow.bottom > 0 ? ((400 - overflow.bottom) + "px") : '400px'
        })},[(_vm.search)?_c('div',{staticClass:"w-full flex items-center border-b"},[_c('o-icon',{staticClass:"text-gray-500 ml-4",attrs:{"icon":"search","size":14}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],staticClass:"py-2 px-3 w-full",attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.query)},on:{"input":function($event){if($event.target.composing){ return; }_vm.query=$event.target.value}}})],1):_vm._e(),_vm._v(" "),_vm._t("content",function(){return [(_vm.filtered)?_c('o-scroll',_vm._l((_vm.filtered),function(category,indexCategory){return _c('ul',{key:indexCategory},[(category.label)?_c('li',{staticClass:"text-gray-500 text-xs uppercase tracking-wider mt-3 mb-1 px-4"},[_vm._v("\n                "+_vm._s(category.label)+"\n              ")]):_vm._e(),_vm._v(" "),_vm._l((category.options),function(option,indexOption){return _c('li',{key:indexOption,staticClass:"text-gray-900 cursor-pointer select-none relative py-2 px-3  transition-colors",class:[
                  option.value === _vm.value ? 'bg-green-100 text-green-700 hover:bg-green-100' : 'hover:bg-gray-200',
                  option.class
                ],attrs:{"role":"option"},on:{"click":function($event){return _vm.handleOptionClick(option.value)}}},[_vm._t("option",function(){return [_vm._v("\n                  "+_vm._s(option.label)+"\n                ")]},{"option":option})],2)})],2)}),0):_c('p',{staticClass:"text-center p-4 text-gray-600"},[_vm._v("\n            No options found.\n          ")])]})],2)]}}],null,true)},[_c('div',{staticClass:"w-full h-9 relative flex items-center justify-between text-sm text-gray-800 cursor-pointer pr-4 px-2"},[(_vm._value !== null && _vm._value !== undefined)?_vm._t("value",function(){return [_vm._v("\n        "+_vm._s(_vm._value)+"\n      ")]},{"value":_vm.value}):_vm._e(),_vm._v(" "),(_vm._value === null || _vm._value === undefined)?_c('p',{staticClass:"text-sm text-gray-500"},[_vm._v("\n        "+_vm._s(_vm.placeholder)+"\n      ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"absolute inset-y-0 pointer-events-none right-0 flex items-center px-2 text-gray-600"},[_c('o-icon',{attrs:{"icon":"accordion","size":12}})],1)],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }