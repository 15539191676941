<template>
  <component
    :is="tagName"
    v-if="localShow"
    :class="currentClass"
  >
    <o-icon
      v-if="currentIcon"
      :icon="currentIcon"
      :class="accentClasses"
      fill
      class="mr-4"
      :size="18"
    />
    <div class="w-full">
      <h5 v-if="title" class="pt-1 font-medium text-current leading-none" :class="{ 'mb-2' : $slots.default }" v-html="title" />
      <div v-if="$slots.default">
        <slot />
      </div>
    </div>
    <span
      v-if="dismissible"
      ref="close"
      :class="closeButtonClass"
      @click="hide"
    >
      <slot name="close">
        <svg
          :class="closeIconClass"
          role="button"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <title>{{ closeButtonTitle }}</title>
          <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
      </slot>
    </span>
  </component>
</template>

<script>
export default {
  name: 'OAlert',
  props: {
    tagName: {
      type: String,
      default: 'div'
    },
    variant: {
      type: String,
      default: null,
      validator (value) {
        return value === null || ['danger', 'warning', 'success', 'info'].includes(value)
      }
    },
    dismissible: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    },
    timeout: {
      type: Number,
      default: null
    },
    closeButtonTitle: {
      type: String,
      default: 'Close'
    },
    title: {
      type: String,
      default: null
    },
    baseClass: {
      type: String,
      default: 'rounded p-4 relative flex items-start relative'
    }
  },
  data () {
    return {
      localShow: this.show,

      defaultClass: 'bg-gray-200 border-gray-500 text-gray-700',
      infoClass: 'bg-blue-100 border-blue-500 text-blue-700',
      successClass: 'bg-green-100 border-green-500 text-green-700',
      dangerClass: 'bg-red-100 border-red-500 text-red-700',
      warningClass: 'bg-yellow-100 border-yellow-500 text-yellow-700',

      accentBaseClass: 'pt-px',
      defaultAccentClass: 'text-gray-500',
      infoAccentClass: 'text-blue-500',
      successAccentClass: 'text-green-500',
      dangerAccentClass: 'text-red-500',
      warningAccentClass: 'text-yellow-500',

      closeButtonClass: 'absolute top-0 bottom-0 right-0 px-4 py-3',
      closeIconClass: 'fill-current h-6 w-6'
    }
  },
  computed: {
    statusClasses () {
      const classes = []

      switch (this.variant) {
        case 'info':
          classes.push(this.infoClass)
          break
        case 'danger':
          classes.push(this.dangerClass)
          break
        case 'success':
          classes.push(this.successClass)
          break
        case 'warning':
          classes.push(this.warningClass)
          break
        default:
          classes.push(this.defaultClass)
      }
      return classes
    },
    accentClasses () {
      const classes = [this.accentBaseClass]

      switch (this.variant) {
        case 'info':
          classes.push(this.infoAccentClass)
          break
        case 'danger':
          classes.push(this.dangerAccentClass)
          break
        case 'success':
          classes.push(this.successAccentClass)
          break
        case 'warning':
          classes.push(this.warningAccentClass)
          break
        default:
          classes.push(this.defaultAccentClass)
      }

      return classes
    },
    currentClass () {
      const classes = [
        `${this.$options._componentTag}`,
        `${this.$options._componentTag}-variant-${this.statusName}`
      ]
      if (this.baseClass) {
        classes.push(this.baseClass)
      }
      if (this.accent) {
        classes.push('pl-6')
      }
      return classes.concat(this.statusClasses)
    },
    currentIcon () {
      switch (this.variant) {
        case 'info':
          return 'infoBold'
        case 'danger':
          return 'dangerBold'
        case 'success':
          return 'successBold'
        case 'warning':
          return 'warningBold'
      }
      return false
    }
  },
  watch: {
    show (show) {
      this.localShow = show
    },
    localShow (localShow) {
      this.$emit('update:show', localShow)
      if (this.localShow) {
        this.$emit('shown')
        if (this.timeout) {
          this.initTimeout()
        }
      } else {
        this.$emit('hidden')
      }
    }
  },
  mounted () {
    if (this.localShow && this.timeout) {
      this.initTimeout()
    }
  },
  methods: {
    initTimeout () {
      setTimeout(() => {
        this.hide()
      }, this.timeout)
    },
    hide () {
      this.localShow = false
    }
  }
}
</script>
