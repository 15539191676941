<template>
  <div>
    <div class="flex -mx-1">
      <template v-for="index in 5">
        <div :key="index" class="w-1/5 px-1">
          <div
            class="h-1 rounded-lg transition-colors"
            :class="barClass(index)"
          />
        </div>
      </template>
    </div>
    <div v-if="suggestions || warning" class="text-sm mt-3 text-gray-600">
      <template v-if="suggestions && !warning">
        <span>
          {{ suggestions.join('. ') }}
        </span>
      </template>

      <template v-if="warning">
        <span>
          {{ warning }}
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import zxcvbn from 'zxcvbn'

export default {
  name: 'OPasswordStrength',
  props: {
    password: {
      type: [String, Number],
      default: null
    }
  },
  computed: {
    suggestions () {
      return this.password ? zxcvbn(this.password).feedback?.suggestions : null
    },
    warning () {
      return this.password ? zxcvbn(this.password).feedback?.warning : null
    },
    strength () {
      return this.password ? zxcvbn(this.password).score + 1 : null
    },
    secure () {
      return this.password ? this.password.length >= 7 : null
    },
    active () {
      return this.password && this.password.length > 0
    },
    count () {
      return this.password && (this.password.length > 7 ? '7+' : this.password.length)
    }
  },
  watch: {
    strength () {
      this.$emit('update:strength', this.strength)
    }
  },
  methods: {
    barClass (index) {
      if (index <= this.strength) {
        if (this.strength <= 2) {
          return 'bg-red-400'
        } else if (this.strength <= 4) {
          return 'bg-yellow-400'
        } else {
          return 'bg-green-500'
        }
      } else {
        return 'bg-gray-400'
      }
    }
  }
}
</script>
