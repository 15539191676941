import Vue from 'vue'
import axios from 'axios'
import md5 from 'md5'
import { v4 } from 'uuid'

export default (context, inject) => {
  const plugin = {
    state: {
      listening: false,
      permission: false,
      token: ''
    },

    async register ({ employee } = {}) {
      if (employee) {
        await this.startListeners()
        await this.requestPermission()

        if (this.permission) {
          await this.requestToken()

          if (this.token) {
            await this.registerDevice(employee)
          }
        }

        console.log({
          employee,
          listening: this.listening,
          permission: this.permission,
          token: this.token
        })

        // this.sendTestMessage()
      }
    },

    async unregister () {
      if (this.token) {
        await this.registerDevice()
      }
    },

    startListeners () {
      // Listen for service worker messages
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event?.data?.internalUrl) {
          context.$redirect.route(event.data.internalUrl)
        }
      })

      // Listen for firebase messages
      context.$fire.messaging.onMessage((payload) => {
        Vue.notification.toast({
          title: payload.notification.title,
          text: payload.notification.body,
          action: {
            label: 'View',
            click: () => { context.$redirect.route(payload.data?.internalUrl) }
          }
        })
      })

      this.listening = true
    },

    async requestPermission () {
      try {
        const permission = await Notification.requestPermission()
        this.permission = permission === 'granted'
      } catch (e) {
        console.error(e)
      }
    },

    async requestToken () {
      let currentToken

      try {
        // await context.$fire.messaging.deleteToken()
        currentToken = await context.$fire.messaging.getToken()
      } catch (e) {
        console.error('An error occurred while retrieving token. ', e)
        this.token = ''
      }

      if (currentToken) {
        this.token = currentToken
      } else {
        // Show permission request.
        console.info('No Instance ID token available. Request permission to generate one.')
        // Show permission UI.
        // updateUIForPushPermissionRequired();
        this.token = ''
      }
    },

    async registerDevice (employee) {
      const token = this.token
      const appIdentifier = 'com.ovatu.next'
      const appSecret = 'fc4589caa4434e53be731990bca3e5a2'

      const nonce = v4()
      const signature = md5(appIdentifier + appSecret + nonce)

      const deviceMeta = []

      if (employee) {
        deviceMeta.push({ key: 'idEmployee', value: employee.idEmployee })
      }

      const data = {
        appIdentifier,
        appVersion: '1.0',
        deviceUDID: 'N.A',
        deviceToken: token,
        deviceName: 'flutter',
        deviceModel: 'app',
        deviceVersion: '1',
        pushBadge: '1',
        pushAlert: '1',
        pushSound: '1',
        nonce,
        signature,
        deviceMeta
      }

      await axios({
        method: 'post',
        url: 'https://apns.ovatu.com/registerDevice',
        data
      })
    },

    sendTestMessage () {
      try {
        setTimeout(async () => {
          const data = JSON.stringify({
            from: '787814952555',
            messageId: 'be948149-b0d9-4549-a851-b36b2ac6401e',
            notification: {
              title: 'New reservation',
              body: 'Booked: Test 3 for Jarred Jenkinson with Kristian Test1 @ Tuesday, 14 March 2023 09:41'
            },
            data: {
              idEmployee: '165',
              internalUrl: 'ovatu://reservation/69800674/view?date=20230314'
            },
            to: this.token
          })

          const config = {
            method: 'post',
            url: 'https://fcm.googleapis.com/fcm/send',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'key=AAAAt21uLms:APA91bHrYNsO0HMytK4ZWylyOYD01D67O1TDkjruBPhqQxl8lqv6h-B9d6ZBlfuR515065F2ctDt0Y0jtX2Ztojr4TFhdR8TB3hv-PAFzu2qM9EQ-mKNhkzSGo_oomrHkmNmwoez8MH0'
            },
            data
          }
          const response = await axios(config)

          console.log({ response })
        }, 5000)
      } catch (e) {
        alert(e)
      }
    }
  }

  inject('messages', plugin)
}
