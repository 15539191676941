var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container',_vm._b({attrs:{"leading":"datepicker"},scopedSlots:_vm._u([{key:"leading",fn:function(){return [_vm._t("leading")]},proxy:true},{key:"trailing",fn:function(){return [_vm._t("trailing")]},proxy:true}],null,true)},'container',_vm.container,false),[_c('v-date-picker',_vm._g({ref:"calendar",staticClass:"block h-full w-full text-sm text-gray-800",attrs:{"min-date":_vm.minDate,"max-date":_vm.maxDate,"is-range":_vm.range,"first-day-of-week":_vm.firstDayOfWeek,"popover":{
      placement: 'bottom-start',
      visibility: _vm.visibility
    }},on:{"dayclick":_vm.handleDayClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var inputValue = ref.inputValue;
    var inputEvents = ref.inputEvents;
    var togglePopover = ref.togglePopover;
return [_vm._t("default",function(){return [_c('input',_vm._g(_vm._b({staticClass:"h-full w-full bg-transparent",attrs:{"type":"text"},domProps:{"value":_vm.displayValue(inputValue)},on:{"click":togglePopover}},'input',_vm.attributes,false),inputEvents))]},null,{ inputValue: inputValue, inputEvents: inputEvents, togglePopover: togglePopover })]}}],null,true),model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}},_vm.handlers))],1)}
var staticRenderFns = []

export { render, staticRenderFns }