<template>
  <div
    class="flex flex-col justify-between h-full fixed z-20 sm:relative transition-all"
    :class="[
      collapse ? 'w-60 sm:w-16' : 'min-w-60 w-60',
      mobile ? 'left-0' : '-left-60 sm:left-0',
      backgroundColour
    ]"
  >
    <o-scroll>
      <div class="flex flex-col justify-between h-full">
        <div>
          <div class="p-4 flex items-center justify-between" :class="[ collapse ? 'sm:flex-col sm:items-center' : '']">
            <img
              :class="[ collapse ? 'sm:hidden' : '']"
              src="~/assets/images/logo/logo-white-full.png"
              class="block h-9 opacity-85 -ml-1"
            >

            <img
              :class="[ collapse ? 'hidden sm:block' : 'hidden' ]"
              src="~/assets/images/logo/logo-white-icon.png"
              class="block w-9 opacity-85 mb-6"
            >

            <button
              class="hidden sm:flex opacity-85 hover:bg-white hover:bg-opacity-15 p-2 rounded-md transition-all"
              :class="[ collapse ? 'ml-px' : '0' ]"
              @click="toggleMenu"
            >
              <o-icon icon="menuHamburger" :size="18" class="text-white opacity-75" />
            </button>

            <o-button
              icon="x"
              transparent
              :icon-size="20"
              class="flex sm:hidden opacity-85"
              :class="[ collapse ? 'ml-1' : '0' ]"
              @click="toggleMobile"
            />
          </div>

          <div v-if="!collapse && admin" class="p-4">
            <div
              class="p-3 rounded-lg bg-white bg-opacity-15 flex items-center text-white text-sm text-opacity-75 cursor-pointer leading-none"
            >
              <o-icon
                icon="warning"
                :size="14"
                :class="[
                  collapse ? '' : 'mr-3 ml-1',
                ]"
              />
              <div :class="[ collapse? 'sm:hidden' : '']">
                <p>You are currently logged in as an admin user</p>
                <!--<p class="text-white/60 font-semibold mt-2 flex items-center space-x-2">
                  <span>Open admin panel</span>
                  <o-icon icon="externalLink" size="12" />
                </p>-->
              </div>
            </div>
          </div>

          <div class="p-4">
            <div
              class="rounded-lg bg-white bg-opacity-15 flex items-center text-white text-sm text-opacity-75 cursor-pointer hover:bg-white hover:text-secondary transition leading-none"
              :class="[
                collapse ? '-ml-px py-3 px-3 sm:p-0 sm:h-9 sm:w-9 sm:justify-center' : 'py-3 px-3',
              ]"
              @click="toggleSearch"
            >
              <o-icon
                icon="search"
                :size="14"
                :class="[
                  collapse ? '' : 'mr-3 ml-1',
                ]"
              />
              <span :class="[ collapse? 'sm:hidden' : '']">Search</span>
            </div>
          </div>

          <ul class="flex flex-col w-full p-4">
            <template v-for="item in items.primary">
              <item
                :key="item.slug"
                :item="item"
              />
            </template>
          </ul>
        </div>

        <div>
          <ul class="flex flex-col w-full p-4">
            <alerts />

            <template v-for="item in items.secondary">
              <item
                :key="item.slug"
                :item="item"
              />
            </template>

            <support v-if="!demo" />
          </ul>

          <div class="w-full text-white opacity-85 p-4 border-t border-opacity-10 border-white">
            <account />
          </div>
        </div>
      </div>
    </o-scroll>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Item from './MenuItem.vue'
import items from './pages.js'

import Account from './Account'
import Alerts from './Alerts'
import Support from './Support'

export default {
  components: {
    Item,
    Account,
    Alerts,
    Support
  },
  data () {
    return {
      items
    }
  },
  computed: {
    ...mapGetters({
      collapse: 'menu',
      mobile: 'mobile',
      search: 'search'
    }),
    activeUserInfo () {
      return this.$auth.info
    },
    primary () {
      const primary = items.primary

      return primary.filter((item) => {
        const grants = item.grants

        if (!grants) {
          return true
        }

        return Object.entries(grants)
          .every(([model, actions]) => {
            return this.$grants.get({ model, actions })
          })
      })
    },
    secondary () {
      const secondary = items.secondary

      return secondary.filter((item) => {
        const grants = item.grants

        if (!grants) {
          return true
        }

        return Object.entries(grants)
          .every(([model, actions]) => {
            return this.$grants.get({ model, actions })
          })
      })
    },
    account () {
      return this.$auth.info
    },
    dev () {
      return this.$config.instance === 'dev'
    },
    demo () {
      return this.$config.instance === 'demo'
    },
    admin () {
      return this.$auth?.info?.adminUser
    },
    backgroundColour () {
      if (this.admin) {
        return 'bg-amber-700'
      }

      if (this.dev) {
        return 'bg-teal-900'
      }

      return 'bg-secondary'
    }
  },
  methods: {
    ...mapActions([
      'toggleMenu',
      'toggleSearch',
      'toggleMobile'
    ])
  }
}
</script>
