import Model, { Request } from '../model'
import Enum from '../enums'

import { plugin as $date } from '../../plugins/date'

export default class Voucher extends Model {
  static modelName () {
    return 'voucher'
  }

  objectID () {
    return this.idVoucher
  }

  relations () {
    return {
      customerType: { type: VoucherCustomerType },
      discountType: { type: VoucherDiscountType },
      discountTaxType: { type: VoucherTaxType },
      multipleMode: { type: VoucherMultipleMode }
    }
  }

  static filter (page = 1, filter) {
    const url = this.modelBaseURL() + '/?page=' + page + '&filter=' + filter
    return this.requestList(Request.get(url), this)
  }

  get validityLabel () {
    const { validStartTime, validEndTime, validStartDate, validEndDate, validMonday, validTuesday, validWednesday, validThursday, validFriday, validSaturday, validSunday } = this

    let validityTimesLabel = ''

    if (validStartTime && validEndTime) {
      validityTimesLabel = `between ${$date.minutes(validStartTime)} and ${$date.minutes(validEndTime)}`
    } else if (validStartTime) {
      validityTimesLabel = `after ${$date.minutes(validStartTime)}`
    } else if (validEndTime) {
      validityTimesLabel = `before ${$date.minutes(validEndTime)}`
    }

    let validityDaysLabel = ''

    if (validMonday && validTuesday && validWednesday && validThursday && validFriday && validSaturday && validSunday) {
      // all days
      validityDaysLabel = 'all days'
    } else if (validMonday && validTuesday && validWednesday && validThursday && validFriday && !validSaturday && !validSunday) {
      // week days
      validityDaysLabel = 'weekdays'
    } else if (!validMonday && !validTuesday && !validWednesday && !validThursday && !validFriday && validSaturday && validSunday) {
      // weekends
      validityDaysLabel = 'weekends'
    } else {
      const daysNames = []
      if (validMonday) { daysNames.push('Mondays') }
      if (validTuesday) { daysNames.push('Tuesdays') }
      if (validWednesday) { daysNames.push('Wednesdays') }
      if (validThursday) { daysNames.push('Thursdays') }
      if (validFriday) { daysNames.push('Fridays') }
      if (validSaturday) { daysNames.push('Saturdays') }
      if (validSunday) { daysNames.push('Sundays') }

      validityDaysLabel = daysNames.join(', ')
    }

    let validityDatesLabel = ''

    if (validStartDate && validEndDate) {
      validityDatesLabel = `from ${$date.format(validStartDate * 1000, $date.presets.short)} to ${$date.format(validEndDate * 1000, $date.presets.short)}`
    } else if (validStartDate) {
      validityDatesLabel = `after ${$date.format(validStartDate * 1000, $date.presets.short)}`
    } else if (validEndDate) {
      validityDatesLabel = `before ${$date.format(validEndDate * 1000, $date.presets.short)}`
    }

    if (validityTimesLabel && validityDatesLabel) {
      return `Valid ${validityTimesLabel} on ${validityDaysLabel} ${validityDatesLabel}`
    } else if (validityTimesLabel) {
      return `Valid ${validityTimesLabel} on ${validityDaysLabel}`
    } else if (validityDatesLabel) {
      return `Valid on ${validityDaysLabel} ${validityDatesLabel}`
    } else {
      return `Valid on ${validityDaysLabel}`
    }
  }

  get publishedLabel () {
    let publishedDatesLabel = ''
    const { startDate, endDate } = this

    if (startDate && endDate) {
      publishedDatesLabel = `From ${$date.format(startDate * 1000, $date.presets.short)} to ${$date.format(endDate * 1000, $date.presets.short)}`
    } else if (startDate) {
      publishedDatesLabel = `After ${$date.format(startDate * 1000, $date.presets.short)}`
    } else if (endDate) {
      publishedDatesLabel = `Before ${$date.format(endDate * 1000, $date.presets.short)}`
    } else {
      publishedDatesLabel = 'Ongoing'
    }

    return publishedDatesLabel
  }

  get isDiscountTypeFixed () {
    return this.discountType === VoucherDiscountType.fixed
  }

  get isDiscountTypePercentage () {
    return this.discountType === VoucherDiscountType.percentage
  }

  get isCustomerTypeAll () {
    return this.customerType === VoucherCustomerType.all
  }

  get isCustomerTypeNew () {
    return this.customerType === VoucherCustomerType.new
  }

  get isCustomerTypeExisting () {
    return this.customerType === VoucherCustomerType.existing
  }

  get isCustomerTypeSegments () {
    return this.customerType === VoucherCustomerType.segments
  }
}

export const VoucherDiscountType = new Enum({
  fixed: { value: 0, description: 'Fixed' },
  percentage: { value: 1, description: 'Percentage' }
})

export const VoucherTaxType = new Enum({
  post: { value: 0, description: 'Post' },
  pre: { value: 1, description: 'Pre' }
})

export const VoucherCustomerType = new Enum({
  all: { value: 0, description: 'All' },
  new: { value: 1, description: 'New' },
  existing: { value: 2, description: 'Existing' },
  segments: { value: 3, description: 'Segments' }
})

export const VoucherMultipleMode = new Enum({
  any: { value: 0, description: 'All valid services' },
  singleFirst: { value: 1, description: 'First service only' },
  singleLowest: { value: 2, description: 'Lowest value service only' },
  singleHighest: { value: 3, description: 'Highest value service only' }
})
