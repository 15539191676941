<template>
  <div>
    <o-dropdown
      v-if="account"
      :modifiers="[
        {
          name: 'offset',
          options: {
            offset: [5, 5]
          }
        }
      ]"
      :arrow="false"
      padding="p-0"
      placement="right-end"
      class="w-full"
    >
      <div class="w-full flex items-center justify-between">
        <div class="flex items-center">
          <img
            v-if="account.employee.image"
            :src="account.employee.image"
            class="inline-block h-8 w-8 bg-opacity-75 rounded text-white mr-4"
            :class="[
              collapse ? 'ml-px' : ' ml-1',
            ]"
          >

          <div
            v-if="!account.employee.image"
            class="inline-flex h-8 w-8 rounded text-white mr-4 items-center justify-center bg-white bg-opacity-15"
            :class="[
              collapse ? 'ml-px' : ' ml-1',
            ]"
          >
            <span>{{ account.employee.initials }}</span>
          </div>

          <div :class="[ collapse? 'sm:hidden' : '']" class="text-left leading-none opacity-95 mt-1">
            <p class="leading-none font-medium text-sm mb-1">
              {{ account.employee.firstName }}
              {{ account.employee.lastName }}
            </p>
            <p class="text-2xs font-medium opacity-75 uppercase tracking-wider">
              {{ account.location.title }}
            </p>
          </div>
        </div>

        <o-button :class="[ collapse? 'sm:hidden' : '']" icon="accordion" translucent size="sm" variant="light" />
      </div>

      <template #content>
        <div class="w-full sm:w-80 sm:border sm:rounded-md overflow-hidden">
          <div class="px-3">
            <div class="flex items-center space-x-4 p-3 mt-2 -mb-1">
              <img
                v-if="account.employee.image"
                :src="account.employee.image"
                class="h-12 w-12 rounded-xl ring-4 ring-offset-2 ring-green-400"
              >
              <div>
                <p class="text-base font-medium">
                  {{ account.employee.firstName }} {{ account.employee.lastName }}
                </p>
                <p class="text-sm">
                  {{ account.location.title }}
                </p>
                <p class="text-xs text-gray-500">
                  Account ID: #{{ account.location.idLocation }}
                </p>
              </div>
            </div>
            <div v-if="$auth.info.adminUser" class="p-3 mt-2">
              <p class="text-xs text-gray-500">
                Admin
              </p>
              <o-history-link :item="$auth.info.location" model="Location" icon="history" label="Account History" size="sm" />
            </div>

            <div class="w-full h-px bg-gray-300 my-3" />

            <o-dropdown-item v-if="account.employee.pinHash" icon="login" :icon-size="16" @click="handleLock">
              Lock account
            </o-dropdown-item>

            <o-dropdown-item v-if="!demo" icon="switch" :icon-size="16" @click="handleSwitch">
              Switch user
            </o-dropdown-item>

            <!--<o-dropdown-item icon="user" :icon-size="16" @click="handleUserSettings">
              User settings
            </o-dropdown-item>-->

            <div class="w-full h-px bg-gray-300 my-3" />

            <o-dropdown-item
              v-if="!demo && $grants.all({ account: [ 'billing'] })"
              :description="planLabel"
              icon="menuPlan"
              :icon-size="16"
              tag-name="nuxt-link"
              to="/account/billing"
            >
              Plan
            </o-dropdown-item>
            <o-dropdown-item
              v-if="$grants.all({ account: [ 'edit'] })"
              description="Manage account settings"
              icon="menuManage"
              :icon-size="16"
              tag-name="nuxt-link"
              to="/account"
            >
              Settings
            </o-dropdown-item>
            <o-dropdown-item
              description="Guides and FAQs"
              icon="menuSupport"
              :icon-size="16"
              href="https://ovatu.com/au/help/guides"
              target="_blank"
            >
              Help
            </o-dropdown-item>

            <div class="w-full h-px bg-gray-300 my-3" />

            <o-dropdown-item @click="handleChangePassword">
              Change password
            </o-dropdown-item>

            <o-dropdown-item @click="handleChangePin">
              Change pin
            </o-dropdown-item>

            <div class="w-full h-px bg-gray-300 my-3" />

            <o-dropdown-item v-if="!demo" class="mb-2" icon="logout" @click="handleLogout">
              Logout user
            </o-dropdown-item>

            <o-dropdown-item v-if="demo" href="https://ovatu.com/plans" class="mb-2" icon="logout">
              Leave demo
            </o-dropdown-item>
          </div>
        </div>
      </template>
    </o-dropdown>

    <!--<user-settings-modal ref="userSettingsModal" />-->

    <change-password-dialog
      ref="changePasswordDialog"
    />

    <change-pin-dialog
      ref="changePinDialog"
      @success="handleChangePinSuccess"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// import UserSettingsModal from '@/components/auth/elements/UserSettings'

import ChangePasswordDialog from '@/components/auth/actions/ChangePassword'
import ChangePinDialog from '@/components/auth/actions/ChangePin'

import {
  ODropdown,
  ODropdownItem,
  ODropdownDivider
} from '@/components/elements'

export default {
  components: {
    // UserSettingsModal,
    ChangePasswordDialog,
    ChangePinDialog,
    ODropdown,
    ODropdownItem,
    ODropdownDivider
  },
  computed: {
    ...mapGetters({
      collapse: 'menu'
    }),
    account () {
      return this.$auth.info
    },
    demo () {
      return this.$config.instance === 'demo'
    },
    planLabel () {
      return this.account?.plan?.infoLabel || ''
    }
  },
  methods: {
    handleLock () {
      this.$auth.lock()
    },
    handleSwitch () {
      this.$auth.switch()
    },
    handleLogout () {
      this.$auth.logout()
    },
    handleChangePassword () {
      this.$refs.changePasswordDialog.open()
    },
    handleChangePin () {
      this.$refs.changePinDialog.open()
    },
    handleChangePinSuccess () {
      this.$auth.syncActive()
    },
    handleUserSettings () {
      this.$refs.userSettingsModal.open()
    }
  }
}
</script>
