<template>
  <container v-bind="container">
    <div class="w-full h-full relative">
      <select
        ref="select"
        v-model="currentValue"
        :multiple="multiple"
        class="appearance-none h-full w-full text-sm px-2 rounded-md block text-gray-700 placeholder:text-gray-400 pr-6"
        :class="{ 'pointer-events-none' : disabled }"
        @change="$emit('change')"
      >
        <template v-for="(option, index) in normalizedOptionsWithPlaceholder">
          <optgroup
            v-if="option.children"
            :key="`${option.value}-optgroup-${index}`"
            :value="option.value"
            :label="option.text"
          >
            <option
              v-for="(childOption, index2) in option.children"
              :key="`${childOption.value}-${index}-${index2}`"
              :value="childOption.value"
              v-text="childOption.text"
            />
          </optgroup>
          <option
            v-else
            :key="`${option.value}-${index}`"
            :value="option.value"
            v-text="option.text"
          />
        </template>
      </select>
      <div class="absolute inset-y-0 pointer-events-none right-0 flex items-center px-2 text-gray-600">
        <o-icon icon="chevronDown" :size="12" />
      </div>
    </div>

    <template #leading>
      <slot name="leading" />
    </template>

    <template #trailing>
      <slot name="trailing" />
    </template>
  </container>
</template>

<script>
import classes from '@/mixins/fields/classes.js'
import attributes from '@/mixins/fields/attributes.js'
import methods from '@/mixins/fields/methods.js'
import multi from '@/mixins/fields/multi.js'
import input from '@/mixins/fields/input.js'

import Container from './Container'

export default {
  components: {
    Container
  },
  mixins: [attributes, classes, methods, multi, input],
  props: {
    value: {
      type: [String, Number, Boolean, Object],
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    defaultSizeClass: {
      type: [String, Object, Array],
      default: 'h-9'
    }
  },
  computed: {
    currentValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    normalizedOptionsWithPlaceholder () {
      if (!this.placeholder) {
        return this.normalizedOptions
      }
      const { normalizedOptions } = this
      normalizedOptions.unshift({
        value: null,
        text: this.placeholder
      })
      return normalizedOptions
    }
  },
  methods: {
    normalizeOption (option) {
      if (['string', 'number', 'boolean'].includes(typeof option)) {
        return {
          value: option,
          text: option
        }
      }
      if (option.children && Array.isArray(option.children)) {
        return {
          value: this.guessOptionValue(option),
          text: this.guessOptionText(option),
          children: option.children.map(childOption => this.normalizeOption(childOption))
        }
      }

      return {
        value: this.guessOptionValue(option),
        text: this.guessOptionText(option)
      }
    }
  }
}
</script>
