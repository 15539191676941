<template>
  <nuxt-link :to="result.path" class="flex items-center leading-none py-1 text-gray-800">
    <o-icon :icon="result.data.icon" class="mr-4" />
    <div>
      <p class="my-1">
        {{ result.data.label }}
      </p>
      <p class="text-gray-600 text-sm">
        {{ result.data.info }}
      </p>
    </div>
  </nuxt-link>
</template>

<script>
export default {
  props: {
    result: {
      type: Object,
      required: true
    }
  }
}
</script>
