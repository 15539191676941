import Model, { Request } from '../model'
import Enum from '../enums'

import Images from './images'

export default class Location extends Model {
  static modelName () {
    return 'location'
  }

  objectID () {
    return this.idLocation
  }

  relations () {
    return {
      images: { type: Images },
      orderCreationDateDefault: { type: OrderCreationDateDefaultType },
      startDayOfWeek: { type: StartDayOfWeek },
      timeFormat: { type: TimeFormat }
    }
  }

  static modelBaseURL () {
    return this.apiBaseURL() + '/v' + this.modelApiVersion() + '/settings/'
  }

  save () {
    const url = this.constructor.modelBaseURL() + this.constructor.modelName()
    const request = Request.jsonPost(url, this.requestJSON())

    const that = this
    return this.constructor.requestItem(request, this.constructor)
      .catch((err) => {
        throw err
      }).then((item) => {
        that.saturated = new Date()

        for (const key in item) {
          that[key] = item[key]
        }

        return that
      })
  }

  static loyaltyProgramBulkSet (type, status) {
    const url = this.modelBaseURL() + '/loyaltyProgramBulkSet'
    const request = {}

    request[type] = status ? 'on' : 'off'

    return this.requestSuccess(Request.post(url, JSON.stringify(request)))
  }
}

export const OrderCreationDateDefaultType = new Enum({
  item: { value: 0, description: 'Appointment\'s date' },
  now: { value: 1, description: 'Today\'s date' }
})

export const StartDayOfWeek = new Enum({
  monday: { value: 1, description: 'Monday' },
  tuesday: { value: 2, description: 'Tuesday' },
  wednesday: { value: 3, description: 'Wednesday' },
  thursday: { value: 4, description: 'Thursday' },
  friday: { value: 5, description: 'Friday' },
  saturday: { value: 6, description: 'Saturday' },
  sunday: { value: 7, description: 'Sunday' }
})

export const TimeFormat = new Enum({
  12: { value: 0, description: '12 Hour' },
  24: { value: 1, description: '24 Hour' }
})
