<template>
  <div class="flex flex-col w-full" :class="scroll ? 'h-full' : ''">
    <slot name="header">
      <div v-if="title && (filter || $slots['header-left'])" class="mb-3">
        <h2 class="font-medium text-gray-700">
          {{ title }}
        </h2>
        <p v-if="info" class="text-sm text-gray-600 font-normal">
          {{ info }}
        </p>
      </div>
      <div v-if="title || filter || reorder || $scopedSlots['header-left'] || $scopedSlots['header-right'] || $scopedSlots['controls']" class="md:flex items-center justify-between pb-2">
        <div class="py-1 md:py-0">
          <slot name="header-left">
            <div class="flex items-center">
              <div v-if="!_reorderActive && filter" class="w-full md:w-auto">
                <text-field v-model="query" leading="search" placeholder="Filter" class="w-full md:w-50" />
              </div>

              <div v-if="title && !filter">
                <h2 class="font-medium text-gray-700">
                  {{ title }}
                </h2>
                <p v-if="info" class="text-sm text-gray-600 font-normal line-clamp-2">
                  {{ info }}
                </p>
              </div>
            </div>
          </slot>
        </div>
        <div class="py-1 md:py-0">
          <slot name="header-right">
            <div class="flex items-center space-x-1">
              <template v-if="!_reorderActive">
                <o-tooltip v-if="reorder" text="Reorder">
                  <o-button icon="reorder" flat outline @click="_reorderActive = true" />
                </o-tooltip>

                <slot name="controls" />
              </template>

              <template v-else>
                <o-button v-if="reorder" flat outline @click="handleReorderCancel">
                  Cancel reorder
                </o-button>
                <o-button v-if="reorder" variant="success" @click="handleReorderConfirm">
                  Save order changes
                </o-button>
              </template>
            </div>
          </slot>
        </div>
      </div>
    </slot>
    <div :class="[containerClass, scroll ? 'h-full' : '']" class="flex flex-col flex-grow flex-shrink w-full relative">
      <component :is="scroll ? 'o-scroll' : 'div'" class="flex-grow flex-shrink w-full" :class="scroll ? 'h-full' : ''" :x-axis="true">
        <table
          v-if="ready"
          :class="currentClass"
        >
          <slot
            v-if="!renderResponsive"
            :tbody-class="theadClass.thead"
            :tr-class="theadClass.tr"
            :th-class="theadClass.th"
            :data="normalizedHeaders"
            name="thead"
          >
            <thead
              v-if="showHeader"
              :class="theadClass.thead"
            >
              <tr :class="theadClass.tr">
                <th
                  v-if="_reorderActive"
                  :class="[theadClass.th, 'w-12']"
                />
                <th
                  v-if="select"
                  :class="[theadClass.th]"
                >
                  <input
                    ref="select-all"
                    type="checkbox"
                    :value="data.length === selected.length"
                    :checked.prop="data.length === selected.length"
                    class="o-checkbox appearance-none h-4 w-4 border border-gray-400 rounded  checked:bg-blue-600 checked:border-blue-600"
                    @click="selectAll"
                  >
                </th>
                <th
                  v-for="({ text, className, id, sortKey }, index) in normalizedHeaders"
                  :id="id"
                  :key="index"
                  :class="[theadClass.th, className]"
                >
                  <div
                    :class="[theadClass.content, {'cursor-pointer' : sortKey}]"
                    @click="sortKey ? sortData(sortKey) : null"
                  >
                    <p class="leading-none">
                      {{ text || '-' }}
                    </p>
                    <p class="ml-1">
                      <o-icon
                        v-if="sortKey && sort.key === sortKey"
                        class="cursor-pointer"
                        :icon="sort.order === 'asc' ? 'sortAsc' : 'sortDesc'"
                        :size="12"
                      />
                      <o-icon
                        v-if="sortKey && sort.key !== sortKey"
                        class="cursor-pointer"
                        icon="sortUnset"
                        :size="12"
                      />
                    </p>
                  </div>
                </th>
              </tr>
            </thead>
          </slot>
          <slot
            :tbody-class="tbodyClass.tbody"
            :tr-class="tbodyClass.tr"
            :td-class="tbodyClass.td"
            :th-class="theadClass.th"
            :data="page"
            :headers="normalizedHeaders"
            :render-responsive="renderResponsive"
            name="tbody"
          >
            <draggable
              v-model="rows"
              tag="div"
              handle=".reorder-handle"
              :class="[
                _reorderActive && !rows.length ? 'block h-10' : 'contents',
                tbodyClass.tbody
              ]"
              :options="{ disabled: !_reorderActive }"
              :group="group"
              @change="(e) => $emit('change', e)"
            >
              <tbody v-for="(row, rowIndex) in rows" :key="'tbody-' + rowIndex">
                <tr
                  :key="'row-' + rowIndex"
                  :class="[tbodyClass.tr, (rowIndex % 2 === 0) && striped ? tbodyClass.stripe : '']"
                >
                  <td
                    v-if="_reorderActive"
                    :class="tbodyClass.td"
                    class="w-14"
                    scope="row"
                  >
                    <o-icon icon="reorderHandle" :size="12" class="reorder-handle text-gray-700 bg-gray-200 rounded-md h-7 w-7 flex items-center justify-center hover:bg-gray-300 transition-color duration-200 cursor-move" />
                  </td>
                  <td
                    v-if="select"
                    :class="tbodyClass.td"
                    class="w-14"
                  >
                    <div class="flex justify-center">
                      <input v-model="selected" :value="row" type="checkbox" class="o-checkbox appearance-none h-4 w-4 border border-gray-400 rounded mx-auto checked:bg-blue-600 checked:border-blue-600">
                    </div>
                  </td>
                  <slot
                    :row-index="rowIndex"
                    :tr-class="tbodyClass.tr"
                    :td-class="tbodyClass.td"
                    :row="row"
                    :toggle="() => toggle(rowIndex)"
                    name="row"
                  >
                    <slot
                      v-for="(text, columnIndex) in getRowColumns(row)"
                      :row-index="rowIndex"
                      :column-index="columnIndex"
                      :td-class="tbodyClass.td"
                      :text="text"
                      name="column"
                    >
                      <td
                        :key="`${rowIndex}-${columnIndex}`"
                        :class="tbodyClass.td"
                      >
                        {{ getCell(text) }}
                      </td>
                    </slot>
                  </slot>
                </tr>

                <tr v-if="collapse && !_reorderActive" :key="'accordion' + row.idService || row.idProductCategory || row.idEmployee || rowIndex">
                  <td colspan="100">
                    <o-collapse :ref="'collapse' + rowIndex" no-header>
                      <div class="bg-gray-200 p-4 text-gray-800">
                        <slot name="collapse" :row="row" />
                      </div>
                    </o-collapse>
                  </td>
                </tr>
              </tbody>
            </draggable>
          </slot>
          <slot
            :tfoot-class="tfootClass.tfoot"
            :tr-class="tfootClass.tr"
            :td-class="tfootClass.td"
            :data="normalizedFooterData"
            :headers="normalizedHeaders"
            :render-responsive="renderResponsive"
            name="tfoot"
          >
            <tfoot
              v-if="showFooter"
              :class="tfootClass.tfoot"
            >
              <tr :class="tfootClass.tr">
                <td
                  v-for="({ text, className, id }, index) in normalizedFooterData"
                  :id="id"
                  :key="index"
                  :class="[tfootClass.td, className]"
                >
                  {{ text }}
                </td>
              </tr>
            </tfoot>
          </slot>
        </table>
      </component>
      <div v-if="!_reorderActive && !items.length && !$slots['tbody']" class="text-center my-4 text-sm text-gray-700">
        {{ data.loading ? 'Loading' : placeholder }}
      </div>
      <div v-if="!_reorderActive && pages > 1" class="py-2 border-t flex justify-between items-center flex-shrink-0 px-4">
        <div class="text-gray-800 hidden md:block text-sm">
          Showing <span class="font-medium">{{ counter.from }}</span> to <span class="font-medium">{{ counter.to }}</span> of <span class="font-medium">{{ counter.total }}</span>
        </div>
        <div class="flex space-x-2">
          <o-button
            :variant="paginationVariant.default"
            flat
            translucent
            :disabled="current === 1"
            class="px-1"
            size="sm"
            icon="chevronLeftDouble"
            @click="current = 1"
          />
          <o-button
            flat
            translucent
            :disabled="current === 1"
            class="px-1"
            size="sm"
            icon="chevronLeft"
            @click="current--"
          />

          <template v-for="item in pages">
            <o-button
              v-if="current - 3 < item && item < current + 3"
              :key="item"
              flat
              :translucent="current !== item"
              size="sm"
              @click="current = item"
            >
              {{ item }}
            </o-button>
          </template>

          <o-button
            flat
            translucent
            :disabled="current === pages"
            class="px-1"
            size="sm"
            icon="chevronRight"
            @click="current++"
          />
          <o-button
            flat
            translucent
            :disabled="current === pages"
            class="px-1"
            size="sm"
            icon="chevronRightDouble"
            @click="current = pages"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js'
import pick from 'lodash/pick'
import get from 'lodash/get'

import draggable from 'vuedraggable'

import { Fragment } from 'vue-fragment'

import {
  TablePager
} from '@/api/pager'

import {
  TextField
} from '@/components/fields'

export default {
  name: 'OTable',
  components: {
    draggable,
    Fragment,
    TextField
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    data: {
      type: [Array, Object],
      default: () => []
    },
    headers: {
      type: Array,
      default: () => []
    },
    footerData: {
      type: Array,
      default: () => []
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: false
    },
    select: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 100
    },
    striped: {
      type: Boolean,
      default: true
    },
    collapse: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    info: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: 'No data available'
    },
    containerClass: {
      type: String,
      default: ''
    },
    tableClass: {
      type: [String, Object, Array],
      default: 'w-full overflow-hidden table'
    },
    theadClass: {
      type: Object,
      default: () => {
        return {
          thead: '',
          tr: 'border-blue-700',
          th: 'font-medium p-4 pb-3 bg-gray-200 text-gray-700 text-shadow sticky top-0',
          content: 'flex item-center'
        }
      }
    },
    tbodyClass: {
      type: Object,
      default: () => {
        return {
          tfoot: '',
          tr: '',
          td: 'py-3 px-4',
          stripe: 'bg-gray-100'
        }
      }
    },
    tfootClass: {
      type: Object,
      default: () => {
        return {
          tbody: '',
          tr: '',
          td: 'p-3'
        }
      }
    },
    paginationClass: {
      type: String,
      default: ''
    },
    paginationVariant: {
      type: Object,
      default: () => {
        return {
          default: 'light',
          active: 'info'
        }
      }
    },
    filter: {
      type: Boolean,
      default: false
    },
    reorder: {
      type: Boolean,
      default: false
    },
    reorderActive: {
      type: Boolean,
      default: null
    },
    group: {
      type: String,
      default: 'o-table'
    },
    responsive: {
      type: Boolean,
      default: false
    },
    scroll: {
      type: Boolean,
      default: false
    },
    responsiveBreakpoint: {
      type: Number,
      default: 768
    },
    filterKeys: {
      type: Array,
      default: () => []
    },
    sortKeys: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      ready: !this.responsive,
      windowWidth: null,
      current: 1,
      selected: [],
      indeterminate: false,
      query: '',
      sort: {
        key: null,
        order: null
      },
      page: [],
      undo: null,
      reorderActiveLocal: false
    }
  },
  computed: {
    rows: {
      get () {
        if (this._reorderActive) {
          return this.data
        }

        return this.page
      },
      set (data) {
        if (this._reorderActive) {
          this.$emit('update:data', data)
        } else {
          this.page = data
        }
      }
    },
    _reorderActive: {
      get () {
        if (this.reorderActive !== null) {
          return this.reorderActive
        } else {
          return this.reorderActiveLocal
        }
      },
      set (data) {
        if (this.reorderActive !== null) {
          this.$emit('update:reorderActive', data)
        } else {
          this.reorderActiveLocal = data
        }
      }
    },
    items () {
      const data = this.data

      if (this.isTablePagerData) {
        return data.items
      }

      return data
    },
    sorted () {
      const key = this.sort.key
      const order = this.sort.order

      const filter = this.filter
      const query = this.query

      let items = this.items

      if (filter && query) {
        const fuse = new Fuse(items, {
          keys: this.filterKeys
        })

        const results = fuse.search(query)
        items = results.map(({ item }) => item)
      }

      if (key) {
        const sorted = [...items].sort((a, b) => {
          a = get(a, key)
          b = get(b, key)

          if (a < b) {
            return order !== 'desc' ? 1 : -1
          }
          if (a > b) {
            return order !== 'desc' ? -1 : 1
          }
          return 0
        })
        return sorted
      }

      return items
    },
    pages () {
      return this.data.pages || Math.ceil(this.sorted.length / this.limit) || 0
    },
    renderResponsive () {
      return this.responsive && this.windowWidth && this.windowWidth < this.responsiveBreakpoint
    },
    normalizedHeaders () {
      return this.headers.map((header) => {
        if (typeof header === 'string') {
          return {
            text: header
          }
        }
        return header
      })
    },
    normalizedFooterData () {
      return this.footerData.map((footer) => {
        if (typeof footer === 'string') {
          return {
            text: footer
          }
        }
        return footer
      })
    },
    headersValues () {
      return this.headers.filter(h => h.value).map(h => h.value)
    },
    showHeader () {
      return !this.hideHeader
    },
    currentClass () {
      const classes = [
        `${this.$options._componentTag}`
      ]
      if (this.tableClass) {
        classes.push(this.tableClass)
      }
      return classes
    },
    counter () {
      const current = this.current
      const limit = this.limit
      const total = this.data.total || this.items.length

      const position = (current * limit) - limit

      const from = position + 1
      const to = position + limit > total ? total : position + limit

      return {
        from,
        to,
        total
      }
    },
    isTablePagerData () {
      return this.data instanceof TablePager
    }
  },
  watch: {
    current (page) {
      if (this.isTablePagerData) {
        this.data.load(page)
      }

      this.updatePage()
    },
    value (value) {
      this.selected = value
    },
    selected (selected) {
      this.indeterminate = !!selected.length
      this.$emit('input', selected)
    },
    sorted: {
      handler () {
        this.updatePage()
      },
      immediate: true
    },
    _reorderActive (reorderActive) {
      if (reorderActive) {
        this.undo = this.page
      }
    },
    sort: {
      handler (sort) {
        this.$emit('sort', sort)
      },
      deep: true
    },
    limit () {
      this.updatePage()
    }
  },
  mounted () {
    if (this.responsive) {
      this.windowWidth = window.innerWidth
      this.ready = true
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
      })
    }

    if (this.sortKeys) {
      this.sort.key = this.sortKeys.key
      this.sort.order = this.sortKeys.order
    }
  },
  methods: {
    getProp (obj, prop) {
      return prop.split('.').reduce((r, e) => {
        return r[e]
      }, obj)
    },
    getRowColumns (row) {
      if (!this.headersValues.length) {
        return row
      }
      return pick(row, this.headersValues)
    },
    getCell (cell) {
      if (cell === null || cell === undefined) {
        return ''
      }

      if (typeof cell === 'string') {
        return cell
      }

      if (typeof cell === 'object') {
        return Object.values(cell)[0] || '-'
      }

      return '-'
    },
    selectAll () {
      const items = this.items

      if (items.length === this.selected.length) {
        this.selected = []
      } else {
        this.selected = items
      }
    },
    sortData (key) {
      const current = this.sort.key
      const order = this.sort.order

      this.sort = {
        key: current === key && order === 'asc' ? null : key,
        order: current === key && order === 'asc' ? null : order === 'desc' ? 'asc' : 'desc'
      }
    },
    toggle (index) {
      const collapse = this.$refs['collapse' + index]

      if (collapse && collapse[0]) {
        collapse[0].toggle()
      }
    },
    updatePage () {
      const page = this.sorted

      if (this.isTablePagerData) {
        this.page = page
      } else {
        const current = (this.current - 1) * this.limit
        this.page = page.slice(current, current + this.limit)
      }
    },
    handleReorderCancel () {
      this.$emit('reorder-cancel')
      this.page = this.undo
      this._reorderActive = false
    },
    handleReorderConfirm () {
      this.$emit('reorder-confirm')
      this._reorderActive = false
    }
  }
}
</script>
