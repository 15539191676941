<template>
  <div class="overflow-hidden p-3 md:p-4 flex items-center h-full bg-white shadow-lg rounded-2xl">
    <div
      class="flex flex-row text-left items-center"
    >
      <o-icon :icon="icon" :size="20" class="hidden xl:inline-flex p-2 rounded-lg" :class="iconClass" />
      <div class="xl:ml-3">
        <p class="text-sm leading-none text-gray-600 mb-2">
          {{ title }}
        </p>
        <div class="flex items-center space-x-3 md:mt-1">
          <h2 class="leading-none text-lg md:text-xl font-semibold text-gray-800">
            {{ value }}
          </h2>
          <span v-if="change" class="text-base flex items-center space-x-2 leading-none rounded-full px-2 py-1" :class="[ changeSign === -1 ? 'text-red-700 bg-red-100' : 'text-green-700 bg-green-100' ]">
            <o-icon v-if="changeSign === 1" icon="arrowUp" fill :size="13" />
            <o-icon v-if="changeSign === -1" icon="arrowDown" fill :size="13" />
            <p>{{ change }}%</p>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OStatistic',
  props: {
    icon: {
      type: String,
      required: true
    },
    value: {
      type: [Number, String],
      required: true
    },
    title: {
      type: [Number, String],
      required: true
    },
    variant: {
      type: String,
      default: null
    },
    change: {
      type: Number,
      default: null
    }
  },
  computed: {
    iconClass () {
      const variant = this.variant

      switch (variant) {
        case 'success':
          return  'bg-green-200 text-green-700'
        case 'danger':
          return  'bg-red-200 text-red-700'
        case 'warning':
          return  'bg-yellow-200 text-yellow-700'
        case 'info':
          return 'bg-blue-600 text-white'
        default:
          return 'bg-gray-200 text-gray-700'
      }
    },
    changeSign () {
      return Math.sign(this.change)
    }
  }
}
</script>
