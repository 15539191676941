const input = {
  props: {
    // Input attributes
    id: {
      type: [String, Number],
      default: null
    },
    name: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    clear: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: [String, Number],
      default: null
    },
    pattern: {
      type: String,
      default: null
    },
    placeholder: {
      type: [String, Number],
      default: null
    },

    // Input prefix and suffix
    leading: {
      type: [String, Array],
      default: null
    },
    trailing: {
      type: [String, Array],
      default: null
    },

    loading: {
      type: Boolean,
      default: false
    },

    // Input container base classes
    baseClass: {
      type: [String, Object, Array],
      default: 'h-9 w-full relative flex items-center'
    },
    border: {
      type: [String, Object, Array, Boolean],
      default: 'border'
    },
    rounded: {
      type: [String, Object, Array, Boolean],
      default: 'rounded-md'
    },
    shadow: {
      type: [String, Object, Array, Boolean],
      default: 'shadow-sm'
    },
    highlight: {
      type: [String, Object, Array, Boolean],
      default: 'focus-within:ring-2'
    },

    // Input container status classes
    defaultStatusClass: {
      type: [String, Object, Array],
      default: 'bg-white'
    },
    warningStatusClass: {
      type: [String, Object, Array],
      default: 'border-yellow-200 bg-yellow-50'
    },
    errorStatusClass: {
      type: [String, Object, Array],
      default: 'border-red-300 bg-red-100'
    },
    successStatusClass: {
      type: [String, Object, Array],
      default: 'border-green-200 bg-green-100'
    },
    disabledClass: {
      type: [String, Object, Array],
      default: 'bg-gray-100 cursor-not-allowed opacity-75'
    },

    // Input container size classes
    defaultSizeClass: {
      type: [String, Object, Array],
      default: 'px-2 h-9'
    },
    largeSizeClass: {
      type: [String, Object, Array],
      default: 'px-4 text-lg h-12'
    },
    smallSizeClass: {
      type: [String, Object, Array],
      default: 'px-2 text-sm h-8'
    }
  },
  data () {
    return {
      valueWhenFocus: null
    }
  },
  computed: {
    attributes () {
      return {
        id: this.id,
        name: this.name,
        required: this.required,
        readonly: this.readonly,
        clear: this.clear,
        disabled: this.disabled,
        size: this.size,
        pattern: this.pattern,
        placeholder: this.placeholder
      }
    },
    handlers () {
      return {
        blur: this.onBlur,
        focus: this.onFocus,
        keyup: this.onKeyUp,
        keydown: this.onKeyDown
      }
    },
    container () {
      const classes = this.currentClass

      if (this.border) { classes.push(this.border) }
      if (this.rounded) { classes.push(this.rounded) }
      if (this.shadow) { classes.push(this.shadow) }
      if (this.highlight) { classes.push(this.highlight) }

      return {
        class: classes,
        baseClass: this.baseClass,
        leading: this.leading,
        trailing: this.trailing,
        loading: this.loading
      }
    }
  },
  methods: {
    onBlur (e) {
      this.$emit('blur', e)
      if (this.currentValue !== this.valueWhenFocus) {
        this.$emit('change', this.currentValue)
      }
    },
    onFocus (e) {
      this.$emit('focus', e)
      this.valueWhenFocus = this.currentValue
    },
    onKeyUp (e) {
      this.$emit('keyup', e)
    },
    onKeyDown (e) {
      this.$emit('keydown', e)
    }
  }
}

export default input
