import localforage from 'localforage'
import * as API from '~/api'

export const state = () => ({
  customers: [],
  sync: {
    running: false,
    progress: null,
    date: null,
    error: false
  },
  hydrated: false,
  count: null,
  selected: []
})

export const mutations = {
  setSync (state, { running, progress, date, error = null }) {
    if (!(running === undefined)) {
      state.sync.running = running
    }
    if (!(progress === undefined)) {
      state.sync.progress = progress
    }
    if (!(date === undefined)) {
      state.sync.date = date
    }

    state.sync.error = error
  },
  updateCustomers (state, customers) {
    state.customers = Object.freeze(API.Customer.ensure(customers))
  },
  setHydrated (state, hydrated) {
    state.hydrated = hydrated
  },
  setCount (state, count) {
    state.count = count
  },
  selected (state, selected) {
    state.selected =  selected
  }
}

export const getters = {
  isSyncing (state) {
    return state.sync.running
  },
  syncProgress (state) {
    return state.sync.progress
  },
  syncDate (state) {
    return state.sync.date
  },
  all (state) {
    return state.customers
  },
  filtered: state => ({ filters = null } = {}) => {
    if (filters && !filters.isEmpty()) {
      return filters.filter(state.customers)
    } else {
      return state.customers
    }
  },
  count: (state, getters) => ({ filters = null } = {}) => {
    if (!filters.isEmpty()) {
      return getters.filtered({ filters }).length
    } else {
      return state.customers.length
    }
  },
  pages: (state, getters) => ({ limit = 20, filters = null } = {}) => {
    if (!filters.isEmpty()) {
      return parseInt(getters.filtered({ filters }).length / limit)
    } else {
      return parseInt(state.customers.length / limit)
    }
    // return state.count / limit
  },
  pageData: (state, getters) => ({ page = 1, limit = 20, filters = null } = {}) => {
    if (!filters.isEmpty()) {
      return getters.filtered({ filters }).slice((page - 1) * limit, page * limit)
    } else {
      return state.customers.slice((page - 1) * limit, page * limit)
    }
  },
  selected (state) {
    return state.selected
  }
}

export const actions = {
  async sync ({ commit, state, rootState, rootGetters }, { force = false, change = false } = {}) {
    if (state.sync.syncing) {
      console.log('already syncing')
      return
    }

    // Check permission
    if (this.$grants && !this.$grants.all({ customer: ['view'] })) {
      console.log('no customer permissions')
      return
    }

    const guid = rootState.auth.active

    if (!state.hydrated || change) {
      const customers = await localforage.getItem(guid + '.customers.customers')
      const sync = await localforage.getItem(guid + '.customers.sync')


      if (customers) {
        commit('updateCustomers', customers)
      } else {
        commit('updateCustomers', [])
      }

      if (sync) {
        commit('setSync', sync)
      } else {
        commit('setSync', { date: null, progress: null, running: false })
      }

      commit('setHydrated', true)
    }

    const date = new Date()

    commit('setSync', { running: true, progress: 0 })

    const sinceDate = (force) ? null : state.sync.date

    const existing = {}

    if (!force) {
      for (let i = 0; i < state.customers.length; ++i) {
        const customer = state.customers[i]
        existing[customer.idCustomer] = customer
      }
    }

    const errors = []

    try {
      await API.Customer.listAll(sinceDate, (page, pageCount, list) => {
        if (list.items) {
          for (let i = 0; i < list.items.length; ++i) {
            const customer = list.items[i]
            existing[customer.idCustomer] = customer
          }
        }

        if (list.deleted) {
          for (let i = 0; i < list.deleted.length; ++i) {
            const id = list.deleted[i]
            delete existing[id]
          }
        }

        commit('setSync', { running: true, progress: (page / pageCount) })
      })
    } catch (e) {
      if (e?.status === 304) {
        console.log('Customer data is up to date')
      } else {
        errors.push(e)
      }
    } finally {
      commit('updateCustomers', Object.values(existing))

      if (errors.length) {
        commit('setSync', { running: false, progress: null, date: null, error: true })
      } else {
        commit('setSync', { running: false, progress: null, date })
      }

      localforage.setItem(guid + '.customers.customers', state.customers)
      localforage.setItem(guid + '.customers.sync', state.sync)
    }
  },
  async markRead ({ commit, state }, index) {
    await state.alerts[index].markRead().then((success) => {
      if (success) {
        commit('markRead', index)
      }
    })
  }
}
