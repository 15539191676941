import Vue from 'vue'
import merge from '~lodash.mergewith'
import * as Sentry from '~@sentry/vue'

import { ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'

export default async function (ctx, inject) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    Vue,
    release:"web:feature-notifications-preview-7d7830d",
    dsn:"https:\u002F\u002F2faae282fde245a4a1b587aa67036ec2@o357760.ingest.sentry.io\u002F6191489",
    environment:"feature",
  }

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver({ types:["crash","intervention"] }),
    new RewriteFrames(),
  ]

  /* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  Sentry.init(config)

  inject('sentry', Sentry)
  ctx.$sentry = Sentry
}
