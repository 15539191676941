<template>
  <div class="border border-gray-300 rounded-md bg-white overflow-hidden" :class="statusClasses">
    <div class="overflow-hidden">
      <template v-for="option in options">
        <o-collapse
          :key="option.label"
          :visible="option.visible"
          header-class="flex items-center w-full px-3 py-2 border-b cursor-pointer"
        >
          <template #header>
            <div class="flex flex-grow items-start">
              <div class="w-1/4 flex-shrink-0 text-xs text-gray-600">
                {{ option.label }}
              </div>
              <div class="flex-grow text-gray-800 text-sm">
                {{ option.valueLabel() }}
              </div>
            </div>
          </template>
          <div class="bg-gray-200 overflow-hidden">
            <component
              :is="option.component"
              v-model="_value[option.key]"
              v-bind="option.props"
              :options="option.items"
              :search="option.search"
              :selectCategory="option.selectCategory"
              group
              class="h-80 px-2"
            />
          </div>
        </o-collapse>
      </template>
    </div>
    <div class="flex flex-grow items-start px-3 py-2">
      <div class="w-1/4 flex-shrink-0 text-xs text-gray-600">
        During
      </div>
      <div class="flex-grow text-gray-800">
        <date-range-optional-field
          v-model="_value.date"
          group
          :icon="false"
          container-class="flex items-center"
        />
      </div>
    </div>
  </div>
</template>

<script>
import classes from '@/mixins/fields/classes.js'
import {
  DateRangeOptionalField,
  CheckboxGroupField,
  CheckboxCategoriesField
} from '@/components/fields'

export default {
  components: {
    DateRangeOptionalField,
    CheckboxGroupField,
    CheckboxCategoriesField
  },
  mixins: [classes],
  props: {
    options: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    defaultStatusClass: {
      type: [String, Object, Array],
      default: 'bg-white'
    },
    warningStatusClass: {
      type: [String, Object, Array],
      default: 'border-yellow-400 bg-yellow-100'
    },
    errorStatusClass: {
      type: [String, Object, Array],
      default: 'border-red-300 bg-red-100'
    },
    successStatusClass: {
      type: [String, Object, Array],
      default: 'border-green-300 bg-green-100'
    }
  },
  data () {
    return {
      currentValue: null
    }
  },
  computed: {
    _value: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('update:value', value)
      }
    }
  },
  mounted () {
    this.currentValue = this.value
  }
}
</script>
