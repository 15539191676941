var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._b({class:_vm.currentClass,style:(_vm.styles),on:{"click":_vm.onClick,"onFocus":_vm.onFocus,"blur":_vm.onBlur}},'div',_vm.getAttributes(),false),[(_vm.icon && !_vm.iconAfter)?_c('o-icon',_vm._g({class:[
      _vm.hasActionListener ? 'bg-white bg-opacity-25 hover:bg-opacity-50 transition duration-300 ml-2px' : (_vm.$slots.default ? '-ml-1' : ''),
      'cursor-pointer pointer-events-auto p-2px rounded',
      _vm.$slots.default ? 'md:mr-2' : '-mx-1'
    ],attrs:{"icon":_vm.icon,"size":13}},_vm.handlers)):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.icon && _vm.iconAfter)?_c('o-icon',_vm._g({class:[
      _vm.hasActionListener ? 'bg-white bg-opacity-25 hover:bg-opacity-50 transition duration-300' : (_vm.$slots.default ? '-mr-1' : ''),
      'cursor-pointer pointer-events-auto p-2px rounded',
      _vm.$slots.default ? 'md:ml-2' : '-mx-1'
    ],attrs:{"icon":_vm.icon,"size":13}},_vm.handlers)):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }