import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'

export default class Demo extends Model {
  static modelName () {
    return 'demo'
  }

  objectID () {
    return this.idLocation
  }

  static types () {
    const url = this.modelBaseURL() + '/types'
    return this.requestList(Request.get(url), DemoType)
  }

  static create (type) {
    const url = this.modelBaseURL() + '/create?businessType=' + type
    return this.requestItem(Request.get(url), this)
  }

  status () {
    const url = this.constructor.modelBaseURL() + '/check?id=' + this.objectID()
    return this.constructor.requestItem(Request.get(url), DemoStatus)
  }
}

export class DemoType extends APIObject {
  relations () {
    return {

    }
  }
}

export class DemoStatus extends APIObject {
  relations () {
    return {
      status: { type: DemoStatusType }
    }
  }

  get isStatusTypeError () {
    return this.status === DemoStatusType.error
  }

  get isStatusTypeBuilding () {
    return this.status === DemoStatusType.building
  }

  get isStatusTypeComplete () {
    return this.status === DemoStatusType.complete
  }
}

export const DemoStatusType = new Enum({
  error: { value: 1, description: 'Error' },
  building: { value: 2, description: 'Building' },
  complete: { value: 3, description: 'Complete' }
})

