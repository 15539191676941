<template>
  <li v-if="allowed" class="my-px">
    <o-tooltip placement="right" :text="item.name" class="" offset="0, 10" :trigger="collapse ? 'mouseover, mouseleave, mouseenter' : ''">
      <component
        :is="item.external ? 'a' : 'nuxt-link'"
        :href="item.url || '/'"
        :to="item.url || '/'"
        :target="item.external ? '_blank' : ''"
        class="w-full flex flex-row flex-grow items-center h-10 rounded-lg text-gray-300 relative -mx-1 hover:bg-white hover:bg-opacity-15 transition-colors"
        :class="[
          active ? 'bg-white bg-opacity-15' : '',
          collapse ? 'px-3' : 'px-4',
        ]"
        @click.native="setMobile(false)"
      >
        <div class="w-full flex items-center">
          <span v-if="!collapse && active" class="block h-full w-3 left-0 absolute p-1">
            <span class="block w-full h-full bg-primary rounded-full" />
          </span>

          <span
            class="flex items-center justify-center text-lg text-gray-400"
            :class="[
              collapse ? '-ml-px' : '',
            ]"
          >
            <o-icon
              v-if="item.icon"
              :icon="item.icon"
              :size="18"
            />
          </span>
          <span class="ml-5 text-sm" :class="[ collapse? 'sm:hidden' : '']">{{ item.name }}</span>
        </div>

        <div
          class="flex items-center space-x-1"
          :class="[
            collapse ? 'absolute top-0 right-0' : '',
          ]"
        >
          <div v-if="item.badge" class="-mr-1 px-2 py-1 bg-red-600 text-white rounded-lg text-xs">
            {{ item.badge }}
          </div>
        </div>
      </component>
    </o-tooltip>
  </li>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      collapse: 'menu'
    }),
    active () {
      const path = this.$route.path
      const item = this.item

      return item.url === path || (item.match && path.startsWith(item.match, 0))
    },
    allowed () {
      const item = this.item
      const path = item.match || item.url

      const admin = item.admin

      if (admin && !this.$auth.info?.adminUser) {
        return false
      }

      const grants = this.$grants.route(path) || item.grants

      if (!grants) {
        return true
      }

      return this.$grants.all(grants)
    }
  },
  methods: {
    ...mapMutations([
      'setMobile'
    ])
  }
}
</script>
