<template>
  <o-dialog
    ref="dialog"
    title="Change password"
    :info="info"
    :options="[
      { text: 'Cancel', type: 'cancel' },
      { text: 'Change password', type: 'confirm', callback: confirm, disabled: !valid },
    ]"
    :portal="portal"
  >
    <template #heading>
      <img
        v-if="account.employee.image"
        :src="account.employee.image"
        class="mx-auto h-12 w-12 rounded-full mt-3"
      >
    </template>

    <div class="pt-4 text-left">
      <o-form ref="form">
        <o-field-group v-slot="{ status }" label="Old password" name="Old password" rules="required" class="block pb-3">
          <password-field v-model="oldPassword" :status="status" />
        </o-field-group>

        <o-field-group v-slot="{ status }" label="New password" name="New password" rules="required|password:@Confirm" class="block pb-3">
          <password-field v-model="newPassword" :status="status" />
        </o-field-group>

        <o-password-strength :password="newPassword" :strength.sync="strength" class="mb-6" />

        <o-field-group v-slot="{ status }" label="Confirm password" name="Confirm" rules="required" class="block pb-3">
          <password-field v-model="confirmPassword" :status="status" />
        </o-field-group>

        <div class="mb-3 flex justify-end">
          <o-button flat size="sm" @click="handleGeneratePassword()">
            Suggest a password
          </o-button>
        </div>

        <!--<o-field-group label="Revoke sessions" info="Log employee out of all active Ovatu sessions" col reverse class="">
          <toggle-field v-model="revokeSessions" />
        </o-field-group>-->
      </o-form>
    </div>
  </o-dialog>
</template>

<script>
import * as API from '~/api'

import {
  OPasswordStrength
} from '@/components/elements'

import {
  PasswordField,
  ToggleField
} from '@/components/fields'

export default {
  components: {
    OPasswordStrength,
    PasswordField,
    ToggleField
  },
  props: {
    portal: {
      type: String,
      default: undefined
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      revokeSessions: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      strength: 0
    }
  },
  computed: {
    account () {
      return this.$auth.info
    },
    info () {
      const employee = this.account?.employee
      return `${employee?.firstName} ${employee?.lastName}`
    },
    matches () {
      return this.newPassword === this.confirmPassword
    },
    valid () {
      return this.matches && this.oldPassword && this.strength > 2
    }
  },
  methods: {
    open () {
      this.oldPassword = ''
      this.newPassword = ''
      this.confirmPassword = ''

      this.$refs.dialog.open()
    },
    async confirm () {
      this.$emit('update:loading', true)

      try {
        const { oldPassword, newPassword, confirmPassword, revokeSessions } = this
        const success = await API.Auth.changePassword({ oldPassword, newPassword, confirmPassword, revokeSessions })
        this.$emit('update:loading', false)

        if (success) {
          this.$notification.toast({
            title: 'Password changed',
            text: 'Successfully changed your password',
            variant: 'success'
          })

          this.$emit('success')

          return true
        }
      } catch (e) {
        this.$notification.error({ errors: e?.errors })

        this.$emit('error')
      }
    },
    handleGeneratePassword () {
      const suggested = Array(10)
        .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$')
        .map(x => x[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xFFFFFFFF + 1) * x.length)])
        .join('')

      this.newPassword = suggested
      this.confirmPassword = suggested
    }
  }
}
</script>
