<template>
  <portal to="modal-container">
    <div
      class="absolute z-80000 pointer-events-none w-full h-full inset-0"
    >
      <transition name="fade" appear>
        <div
          v-if="active"
          key="modal-overlay"
          :class="overlayClass"
          @click="handleClick()"
        />
      </transition>
      <transition name="pop" appear>
        <div
          v-if="active"
          key="modal-container"
          class="absolute m-auto w-full h-full z-80000 flex items-center justify-center pointer-events-none"
          role="modal"
        >
          <div :class="modalClass">
            <div class="flex h-full w-full">
              <div v-if="$slots.sidebar" :class="sidebarClass" style="background: linear-gradient(172.8deg, #454765 -2.11%, #31324E 77.69%), #323351;">
                <slot name="sidebar" :active="active" />
              </div>
              <div :class="[ $slots.sidebar ? 'w-2/3 flex-grow' : 'w-full', contentClass]">
                <div v-if="$slots.header || title" class="border-b px-3 py-2 flex items-center justify-between">
                  <slot name="header" :close="close">
                    <div>
                      <h4 class="font-semibold text-md leading-none text-gray-800">
                        {{ title }}
                      </h4>
                    </div>

                    <o-button icon="x" flat @click="close" />
                  </slot>
                </div>

                <slot name="body">
                  <div class="px-3 pt-5 sm:px-4 flex-grow flex-shrink h-10">
                    <div v-if="$slots.default" :class="slotClass">
                      <slot :active="active" :close="close" />
                    </div>
                  </div>
                </slot>
                <div class="px-3 py-3 sm:px-4 flex space-x-2">
                  <slot name="footer">
                    <o-button
                      v-for="(button, index) in buttons"
                      :key="index"
                      class="w-full h-9"
                      :variant="button.variant"
                      :flat="button.flat"
                      :outline="button.outline"
                      :disabled="button.disabled"
                      @click="handleClick(button)"
                    >
                      {{ button.text }}
                    </o-button>
                  </slot>
                </div>
              </div>
            </div>

            <transition name="fade">
              <div v-if="loading" class="h-full w-full inset-0 absolute bg-white bg-opacity-75 z-10 flex items-center justify-center">
                <slot name="loader">
                  <o-loader />
                </slot>
              </div>
            </transition>
          </div>
        </div>
      </transition>
    </div>
  </portal>
</template>

<script>
import colours from '~/mixins/elements/colours'

export default {
  name: 'OModal',
  mixins: [colours],
  props: {
    options: {
      type: Array,
      default: () => []
    },
    container: {
      type: [String, Object],
      default: null
    },
    valid: {
      type: Function,
      default: () => true
    },
    title: {
      type: String,
      default: null
    },
    info: {
      type: String,
      default: null
    },
    modalClass: {
      type: String,
      default: 'relative m-2 bg-gray-100 rounded-lg shadow-lg pointer-events-auto overflow-hidden sm:max-w-lg sm:w-full max-h-full'
    },
    overlayClass: {
      type: String,
      default: 'absolute inset-0 w-full h-full bg-black opacity-50 rounded-md z-80000 transition pointer-events-auto'
    },
    sidebarClass: {
      type: String,
      default: 'w-1/3 px-5 py-5 text-white'
    },
    contentClass: {
      type: String,
      default: 'flex flex-col'
    },
    slotClass: {
      type: String,
      default: 'h-full'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      active: false,
      default: {
        confirm: {
          text: 'Confirm',
          variant: 'info',
          outline: false,
          flat: false,
          response: 'confirm',
          validate: true
        },
        cancel: {
          text: 'Cancel',
          variant: undefined,
          outline: true,
          flat: true,
          response: 'cancel',
          validate: false
        }
      }
    }
  },
  computed: {
    buttons () {
      return this.options.map((option) => {
        return Object.assign({ ...this.default[option.type] },  option)
      })
    }
  },
  methods: {
    async handleClick (options = { response: 'cancel' }) {
      const valid = !options.validate || await this.valid()

      if (valid) {
        this.$emit(options.response)

        const callback = options.callback

        if (callback) {
          const success = await callback()

          if (success) {
            this.close()
          }
        } else {
          this.close()
        }
      }
    },
    open () {
      this.active = true
    },
    close () {
      this.active = false
    }
  }
}
</script>

<style>
.pop-enter-active,
.pop-leave-active {
  transition: transform 0.3s, opacity 0.3s
}

.pop-enter,
.pop-leave-to {
  opacity: 0;
  transform: scale(0.3);
}
</style>
