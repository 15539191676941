<template>
  <div>
    <component :is="fullscreen ? 'portal' : 'div'" to="dialog-container">
      <div
        :class="[
          fullscreen ? 'flex flex-col justify-between z-90000 bg-white fixed inset-0 h-screen w-screen' : 'border rounded'
        ]"
      >
        <client-only>
          <VueSignaturePad
            :ref="signatureRef"
            :height="fullscreen ? '100%' : '200px'"
            :options="{ onBegin, onEnd }"
          />
        </client-only>
        <div class="flex justify-between items-center bg-gray-100 p-2 border-t">
          <div>
            <o-button :icon="fullscreen ? 'shrink' : 'expand'" class="md:hidden" @click="expand" />
          </div>
          <div>
            <o-button @click="clear">
              Clear
            </o-button>
            <o-button @click="undo">
              Undo
            </o-button>
          </div>
        </div>
      </div>
    </component>
  </div>
</template>

<script>
import { VueSignaturePad } from 'vue-signature-pad'

export default {
  components: {
    VueSignaturePad
  },
  props: {
    value: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      signatureRef: 'signature-' + (new Date()).getTime(),
      fullscreen: false
    }
  },
  watch: {
    fullscreen () {
      this.$nextTick(() => {
        if (this.value) {
          this.setOvatuData(JSON.parse(this.value))
        }
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.value) {
        this.setOvatuData(JSON.parse(this.value))
      }
    })
  },
  methods: {
    expand () {
      this.fullscreen = !this.fullscreen
    },
    clear () {
      const signaturePad = this.$refs[this.signatureRef]
      if (signaturePad) {
        signaturePad.clearSignature()
      }
    },
    undo () {
      const signaturePad = this.$refs[this.signatureRef]
      if (signaturePad) {
        signaturePad.undoSignature()
      }
    },
    change (value) {
      this.$emit('input', value)
    },
    onBegin () {
    },
    onEnd () {
      const data = this.getOvatuData()
      this.$emit('input', (data) ? JSON.stringify(data) : null)
    },
    getOvatuData () {
      const signaturePad = this.$refs[this.signatureRef]

      if (signaturePad) {
        const nativeData = signaturePad.signaturePad.toData()

        let minX = -1
        let minY = -1
        let maxX = -1
        let maxY = -1

        for (let i = 0; i < nativeData.length; i++) {
          const line = nativeData[i]

          for (let j = 0; j < line.points.length; j++) {
            const point = line.points[j]

            if (minX === -1 || minX > point.x) { minX = point.x }
            if (minY === -1 || minY > point.y) { minY = point.y }

            if (maxX === -1 || maxX < point.x) { maxX = point.x }
            if (maxY === -1 || maxY < point.y) { maxY = point.y }
          }
        }

        const divisor = (maxX - minX)

        const percentageLines = []

        for (let i = 0; i < nativeData.length; i++) {
          const line = nativeData[i]

          const currentPercentageLine = {}
          currentPercentageLine.x = []
          currentPercentageLine.y = []

          for (let j = 0; j < line.points.length; j++) {
            const point = line.points[j]

            const perx = (point.x - minX) / divisor
            const pery = (point.y - minY) / divisor

            currentPercentageLine.x.push(perx)
            currentPercentageLine.y.push(pery)
          }

          percentageLines.push(currentPercentageLine)
        }

        return {
          format: 'signature',
          version: 1,
          lines: percentageLines
        }
      }
    },
    setOvatuData (data) {
      const signaturePad = this.$refs[this.signatureRef]

      const nativeData = []

      let maxX = -1
      let maxY = -1

      const wPadding = 10
      const bPadding = 10

      const canvasWidth = signaturePad.$el.clientWidth
      const canvasHeight = signaturePad.$el.clientHeight

      for (let i = 0; i < data.lines.length; i++) {
        const percentageLine = data.lines[i]

        for (let j = 0; j < percentageLine.x.length; j++) {
          const x = percentageLine.x[j]
          const y = percentageLine.y[j]

          if (maxX === -1 || maxX < x) {
            maxX = x
          }
          if (maxY === -1 || maxY < y) {
            maxY = y
          }
        }
      }

      if (maxX === 0 || maxY === 0) {
        return
      }

      let w = canvasWidth
      let h = parseInt((maxY / maxX) * w)

      if (h > (canvasHeight - bPadding)) {
        h = (canvasHeight - bPadding)
        w = parseInt((maxX / maxY) * (h))
      }

      for (let i = 0; i < data.lines.length; i++) {
        const percentageLine = data.lines[i]

        const xs = percentageLine.x
        const ys = percentageLine.y

        const currentLine = {
          color: '#000',
          points: []
        }

        for (let j = 0; j < xs.length; j++) {
          const x = xs[j]
          const y = ys[j]

          currentLine.points.push({
            x: wPadding + (x * (w - (wPadding * 2))),
            y: wPadding + (y * (w - (wPadding * 2)))
          })
        }

        nativeData.push(currentLine)
      }

      signaturePad.fromData(nativeData)
    }
  }
}
</script>
