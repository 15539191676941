<template>
  <div
    :class="currentClass"
  >
    <div class="flex flex-grow items-start">
      <div class="w-1/4 flex-shrink-0 text-xs px-3 py-2 text-gray-600">
        Date
      </div>
      <div class="flex-grow">
        <select-field v-model="_value.day" :border="false" :highlight="false" placeholder="Any" :options="dayOptions" />
      </div>
    </div>

    <div class="flex flex-grow items-start">
      <div class="w-1/4 flex-shrink-0 text-xs px-3 py-2 text-gray-600">
        Month
      </div>
      <div class="flex-grow">
        <select-field v-model="_value.month" :border="false" :highlight="false" placeholder="Any" :options="monthOptions" />
      </div>
    </div>

    <div class="flex flex-grow items-start">
      <div class="w-1/4 flex-shrink-0 text-xs px-3 py-2 text-gray-600">
        Year
      </div>
      <div class="flex-grow">
        <select-field v-model="_value.year" :border="false" :highlight="false" placeholder="Any" :options="yearOptions" />
      </div>
    </div>
  </div>
</template>

<script>
import classes from '@/mixins/fields/classes.js'
import { SelectField } from '@/components/fields'

export default {
  components: {
    SelectField
  },
  mixins: [classes],
  props: {
    value: {
      type: Object,
      required: true
    },
    baseClass: {
      type: [String, Object, Array],
      default: 'border border-gray-300 rounded-md bg-white overflow-hidden divide-y divide-gray-300 focus-within:ring-2'
    },
    defaultStatusClass: {
      type: [String, Object, Array],
      default: 'bg-white'
    },
    warningStatusClass: {
      type: [String, Object, Array],
      default: 'border border-yellow-400 bg-yellow-100'
    },
    errorStatusClass: {
      type: [String, Object, Array],
      default: 'border border-red-300 bg-red-100'
    },
    successStatusClass: {
      type: [String, Object, Array],
      default: 'border border-green-300 bg-green-100'
    }
  },
  computed: {
    _value: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    dayOptions () {
      const options = []
      const days = this.$dateFns.getDaysInMonth(new Date(this._value.year || 2000, this._value.month || 0))

      for (let i = 1; i <= days; i++) {
        options.push({ text: i, value: i })
      }

      return options
    },
    monthOptions () {
      const options = []

      for (let i = 0; i < 12; i++) {
        options.push({ text: this.$date.format(new Date(0, i), 'MMMM'), value: i })
      }

      return options
    },
    yearOptions () {
      const options = []
      const total = 100
      const current = this.$dateFns.getYear(new Date())

      for (let i = current; i >= current - total; i--) {
        options.push({ text: i, value: i })
      }

      return options
    }
  },
  watch: {
    'value.month' () {
      this.validateDay()
    },
    'value.year' () {
      this.validateDay()
    }
  },
  methods: {
    validateDay () {
      const days = this.$dateFns.getDaysInMonth(new Date(this._value.year || 2000, this._value.month || 1))
      if (this._value.day > days) {
        this._value.day = null
      }
    }
  }
}
</script>
