export const pages = [
  { label: 'Preferences', icon: 'preferences', info: 'Waitlist, customer access & booking limits', path: '/minisite/preferences' },
  { label: 'Theme', icon: 'preferences', info: 'Colours, images & logos', path: '/minisite/theme' },
  { label: 'Widgets', icon: 'widget', info: 'Embed code for widgets and ovatu bot', path: '/minisite/embed' },
  { label: 'Pages', icon: 'services', info: 'Enable pages on your book.app site', path: '/minisite/pages/booking', match: '/minisite/pages' },
  { label: 'Business info', icon: 'product', info: 'Contact details, social accounts and business hours', path: '/minisite/business-info/contact', match: '/minisite/business-info' },
  { label: 'Payments', icon: 'payment', info: 'Manage online payment and deposit options', path: '/minisite/payments' },
  { label: 'New customers', icon: 'customFields', info: 'What fields are required for new customer registrations', path: '/minisite/new-customers' },
  { label: 'Minimise Gaps', icon: 'forms', info: 'Reduce unwanted gaps and manage times between appointments created online. ', path: '/minisite/minimise-gaps' },
  { label: 'Terms', icon: 'giftCard', info: 'Online booking customer terms and conditions', path: '/minisite/terms' }
  // { label: 'Advanced', icon: 'warning', info: 'Add custom javascript to your minisite', path: '/minisite/advanced' }
]
