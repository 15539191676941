import * as API from '~/api'

export const state = () => ({
  updates: [],
  counts: []
})

export const mutations = {
  setUpdates (state, updates) {
    state.updates = updates
  },
  setCounts (state, counts) {
    state.counts = counts
  }
}

export const getters = {
  updates (state) {
    return API.Updates.ensure(state.updates)
  },
  counts (state) {
    API.UpdatesNewsCount.ensure(state.counts)
  }
}

export const actions = {
  async get ({ commit }) {
    const updates = await API.Updates.listAll()
    const counts = await API.Updates.newsCount()

    if (updates) {
      commit('setUpdates', updates)
    }
    if (counts) {
      commit('setCounts', counts)
    }
  }
}
