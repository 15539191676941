<template>
  <div class="w-full">
    <div id="otp" class="w-full flex items-center space-x-2">
      <input
        v-for="(field, key) in fields"
        :id="key"
        :key="key"
        :ref="`field-${key}`"
        v-model="field.value"
        class="w-10 flex-grow text-center text-2xl text-gray-700 border rounded-md py-5 leading-none font-light form-control form-control-solid focus:border-blue-300 focus:ring-4 focus:ring-blue-200 focus:shadow-outline transition-all"
        type="number"
        maxlength="1"
        @input="onInput"
        @keydown="onKeydown"
        @paste.prevent="onPaste"
      >
    </div>
  </div>
</template>

<script>
const KEY_CODE = {
  backspace: 8,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  v: 86
}

export default {
  data () {
    return {
      fields: {
        0: { value: '' },
        1: { value: '' },
        2: { value: '' },
        3: { value: '' },
        4: { value: '' },
        5: { value: '' }
      }
    }
  },
  computed: {
    _value () {
      return Object.values(this.fields)
        .map(field => field.value)
        .join('')
    }
  },
  watch: {
    _value (value) {
      this.$emit('input', value)
    }
  },
  methods: {
    onPaste (event) {
      const code = (event.clipboardData || window.clipboardData).getData('text')

      if (typeof code !== 'string') { return }

      const string = code.replace(/\D/g, '')
      const chars = string.split('')

      for (const key of Object.keys(this.fields)) {
        if (chars[key]) {
          this.fields[key].value = chars[key]

          this.focus(key)
        }
      }
    },
    onInput (event) {
      const id = Number(event.target.id)
      this.focus(id + 1)
      event.target.blur()
    },
    onKeydown (event) {
      const id = Number(event.target.id)
      const fields = this.fields
      const field = fields[id]

      switch (event.keyCode) {
        case KEY_CODE.v: {
          break
        }
        case KEY_CODE.backspace: {
          event.preventDefault()
          if (field.value !== null) {
            field.value = null
            // break
          }
          this.focus(id - 1)
          break
        }
        case KEY_CODE.left: {
          event.preventDefault()
          this.focus(id - 1)
          break
        }
        case KEY_CODE.right: {
          event.preventDefault()
          this.focus(id + 1)
          break
        }
        default: {
          if (!(/^[0-9]$/i.test(event.key))) {
            event.preventDefault()
          }
          if (event.target.value !== '') {
            event.target.value = ''
          }
          break
        }
      }
    },
    focus (id) {
      const refs = this.$refs[`field-${id}`]
      const field = refs ? refs[0] : null

      if (!field) {
        return null
      }
      return field.focus()
    }
  }
}
</script>
