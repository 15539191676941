<template>
  <div class="flex flex-col">
    <div v-if="search" class="w-full py-3 border-b relative px-3">
      <text-field v-model="query" placeholder="Filter" leading="search" />
      <div class="absolute right-0 transform mr-2 top-1/2 -translate-y-1/2">
        <o-button
          v-if="query.length"
          size="xs"
          variant="danger"
          transparent
          flat
          icon="x"
          @click="clearQuery()"
        />
      </div>
    </div>

    <div class="flex-shrink flex-grow h-1/2 text-base leading-6 focus:outline-none sm:text-sm sm:leading-5 pt-3">
      <o-scroll clas="pt-6">
        <template
          v-for="(category, index) in filtered"
        >
          <div v-if="category.options.length" :key="index">
            <p v-if="!selectCategory && category.label" class="text-gray-500 text-xs uppercase tracking-wider mt-2 px-4">
              {{ category.label }}
            </p>
            <checkbox-group-field
              v-model="currentValue"
              :options="category.options"
              :scroll="false"
              :select-all="selectCategory"
              :select-all-label="category.label || 'Other'"
              :checkbox-base-class="checkboxBaseClass"
            >
              <template #option="{ option }">
                <slot name="option" :option="option" />
              </template>
            </checkbox-group-field>
          </div>
        </template>
      </o-scroll>
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js'

import CheckboxGroupField from './CheckboxGroup'
import TextField from './Text'

export default {
  components: {
    CheckboxGroupField,
    TextField
  },
  props: {
    value: {
      type: [Array, Object, String],
      default: () => []
    },
    options: {
      type: [Object, Array],
      required: true
    },
    search: {
      type: Boolean,
      default: false
    },
    selectCategory: {
      type: Boolean,
      default: false
    },
    checkboxBaseClass: {
      type: [String, Object, Array],
      default: undefined
    }
  },
  data () {
    return {
      query: ''
    }
  },
  computed: {
    currentValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    filtered () {
      const categories = this.options

      if (this.query.length) {
        const arrays = Object.values(categories).map(({ options }) => options)
        const items = [].concat(...arrays)
        const fuse = new Fuse(items, {
          keys: [
            'text',
            'info',
            'value'
          ]
        })
        const results = fuse.search(this.query)
        const options = [
          { options: results.map(({ item }) => item) }
        ]
        return options
      }

      return categories
    }
  },
  watch: {
    currentValue (currentValue) {
      this.$emit('change', currentValue)
    }
  },
  methods: {
    clearQuery () {
      this.query = ''
    }
  }
}
</script>
