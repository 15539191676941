<template>
  <div class="">
    <div class="flex items-center justify-between flex-wrap md:flex-nowrap">
      <div class="md:flex-shrink md:flex-grow">
        <nav v-if="breadcrumbs && breadcrumbs.length" class="flex items-center mb-2" aria-label="Breadcrumb">
          <ol class="list-none p-0 inline-flex items-center text-sm">
            <li class="flex items-center">
              <router-link class="text-gray-700 leading-none" to="/">
                <span>Home</span>
              </router-link>
              <o-icon icon="chevronRight" :size="11" class="mx-2" />
            </li>
            <li v-for="(link, index) in breadcrumbs.slice(1,-1)" :key="index" class="flex items-center">
              <router-link v-if="link.url" class="text-gray-700" :to="link.url">
                {{ link.title }}
              </router-link>
              <o-icon icon="chevronRight" :size="11" class="mx-2" />
            </li>
            <li>
              <span v-if="breadcrumbs.slice(-1)[0].active" class="text-gray-600" aria-current="page">{{ breadcrumbs.slice(-1)[0].title }}</span>
            </li>
          </ol>
        </nav>
        <slot name="left">
          <h2 class="text-xl font-bold leading-none text-secondary text-opacity-85 sm:text-2xl sm:truncate">
            {{ title }}
          </h2>
          <p v-if="subtitle" class="mt-1 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
            {{ subtitle }}
          </p>
        </slot>
      </div>
      <div class="md:flex-grow md:flex-shrink flex items-center justify-end mt-4 md:mt-0">
        <slot name="right" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OHeading',
  props: {
    title: {
      type: String,
      default: 'Page title'
    },
    subtitle: {
      type: String,
      default: null
    },
    breadcrumbs: {
      type: Array,
      default: null
    }
  }
}
</script>
