import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'

import Order from './order'
import CustomerCreditCard from './customerCreditCard'

export default class Integration extends Model {
  static modelName () {
    return 'integration'
  }

  static modelApiVersion () {
    return 2
  }

  objectID () {
    return this.guid
  }

  relations () {
    return {
      status: { type: IntegrationStatus }
    }
  }

  static index () {
    const url = this.modelBaseURL() + '/index'
    return this.requestList(Request.get(url), Integration)
  }

  static info (guid) {
    const url = this.modelBaseURL() + '/info?guid=' + guid
    return this.requestItem(Request.get(url), Integration)
  }

  static active (modifiedSince = null) {
    const url = this.modelBaseURL() + '/active'

    const headers = {}
    if (modifiedSince) {
      headers['If-Modified-Since'] = modifiedSince.toUTCString()
    }

    return this.requestList(Request.get(url, headers), Integration)
  }

  authenticate (params) {
    const query = Object.entries(params).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&')
    const url = this.constructor.modelBaseURL() + '/authenticate?guid=' + this.objectID() + '&' + query

    return this.constructor.requestItem(Request.get(url), IntegrationAuthenticate)
  }

  unauthenticate () {
    const url = this.constructor.modelBaseURL() + '/unAuthenticate?guid=' + this.objectID()
    return this.constructor.requestSuccess(Request.get(url))
  }

  getOptions () {
    const url = this.constructor.modelBaseURL() + '/options?guid=' + this.objectID()
    return this.constructor.requestList(Request.get(url), IntegrationOption)
  }

  setOptions (options) {
    for (const index in options) {
      options[index] = options[index].currentValue
    }

    const url = this.constructor.modelBaseURL() + '/options?guid=' + this.objectID()
    return this.constructor.requestList(Request.post(url, JSON.stringify(options)), IntegrationOption)
  }

  runAction ({ action, async = false, data = null } = {}) {
    const options = {
      action,
      async,
      data
    }

    const url = this.constructor.modelBaseURL() + '/runAction?guid=' + this.objectID()
    return this.constructor.requestSuccess(Request.post(url, JSON.stringify(options)))
  }

  request (action) {
    const url = this.constructor.modelBaseURL() + '/request?guid=' + this.objectID() + '&action=' + action
    return this.constructor.requestSuccess(Request.get(url))
  }

  static paymentOrder () {
    const url = this.modelBaseURL() + '/reorderPayment'
    return this.requestItem(Request.get(url), IntegrationPaymentOrder)
  }

  static reorderPayment (order) {
    const url = this.modelBaseURL() + '/reorderPayment'

    const data = {
      order
    }

    return this.requestItem(Request.post(url, JSON.stringify(data)), IntegrationPaymentOrder)
  }

  processPayment (order, amount, params, idempotency_key) {
    const url = this.constructor.modelBaseURL() + '/processPayment'

    const request = {
      guid: this.objectID(),
      order: order.idOrder,
      amount,
      params,
      idempotency_key
    }

    return this.constructor.requestSuccess(Request.post(url, JSON.stringify(request)))
  }

  processRefund (order, amount, params) {
    const url = this.constructor.modelBaseURL() + '/processRefund'

    const request = {
      guid: this.objectID(),
      order: order.idOrder,
      amount,
      params
    }

    return this.constructor.requestItem(Request.post(url, JSON.stringify(request)), Order)
  }

  /**
   * @param { integration } - the integration being used
   * @param { customer } - the customer the card is being added to
   * @param { params } - the integration params (stripe: { stripeToken }, square: { squareToken, verificationToken })
   * @returns CustomerCreditCard
   */
  static saveCardIntegration ({ integration, customer, params }) {
    const url = this.modelBaseURL() + '/saveCardIntegration'

    const request = {
      guid: integration.guid,
      idCustomer: customer.idCustomer,
      paymentIntegrationParams: params
    }

    return this.requestItem(Request.post(url, JSON.stringify(request)), CustomerCreditCard)
  }

  get isPlatformWeb () {
    return this.platforms.includes('web')
  }

  get isPlatformApple () {
    return this.platforms.includes('ios')
  }

  get isPlatformAndroid () {
    return this.platforms.includes('android')
  }

  get isSquare () {
    return this.guid === 'b0bca8b297674792a6f4489d272b8230'
  }

  get isStripe () {
    return this.guid === '29bdebaccd9e452fac2be8963d3daba7'
  }

  get isPayPal () {
    return this.guid === '795fc26230c941cc89bc3c6eaae65e30'
  }

  get isStatusDisconnected () {
    return this.status === IntegrationStatus.disconnected
  }

  get isStatusConnected () {
    return this.status === IntegrationStatus.connected
  }

  get isStatusSetupIncomplete () {
    return this.status === IntegrationStatus.setupIncomplete
  }

  get isStatusAuthError () {
    return this.status === IntegrationStatus.authError
  }
}

export class IntegrationOption extends APIObject {

}

export class IntegrationAuthenticate extends APIObject {
  relations () {
    return {
      type: { type: IntegrationAuthenticateType }
    }
  }

  get isTypeUrl () {
    return this.type === IntegrationAuthenticateType.url
  }

  get isTypeNone () {
    return this.type === IntegrationAuthenticateType.none
  }
}

export class IntegrationPaymentOrder extends APIObject {
  relations () {
    return {
      order: { type: Integration }
    }
  }
}

export class IntegrationLabel extends APIObject {
  get isText () {
    return this.type === 'text'
  }

  get isUrl () {
    return this.type === 'url'
  }
}

export class IntegrationAction extends APIObject {
  relations () {
    return {
      type: { type: IntegrationActionType }
    }
  }

  get isCallback () {
    return this.type === IntegrationActionType.callback
  }

  get isInternalUrl () {
    return this.type === IntegrationActionType.internalUrl
  }

  get isExternalUrl () {
    return this.type === IntegrationActionType.externalUrl
  }
}

export const IntegrationActionType = new Enum({
  callback: { value: 1 },
  internalUrl: { value: 2 },
  externalUrl: { value: 3 }
})

export const IntegrationAuthenticateType = new Enum({
  url: { value: 'url' },
  none: { value: 'none' }
})

export const IntegrationStatus = new Enum({
  disconnected: { value: 'disconnected', description: 'Disconected' },
  connected: { value: 'connected', description: 'Connected' },
  setupIncomplete: { value: 'setupIncomplete', description: 'Set Incomplete' },
  authError: { value: 'authError', description: 'Needs reconnecting' }
})
