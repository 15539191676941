class RedirectRule {
  constructor ({ from, to }) {
    this.from = from
    this.to = to
  }

  get component () {
    return 'nuxt-link'
  }

  get regex () {
    return new RegExp(this.from)
  }

  action (context) {
    context.$route.push(this.url)
  }
}

class PanelRedirectRule extends RedirectRule {
  get component () {
    return 'o-panel-link'
  }

  action (url, context) {
    context.$panel.create(url)
  }
}

class ExternalRedirectRule extends RedirectRule {
  get component () {
    return 'a'
  }

  action (url, context) {
    if (window) {
      window.location = url
    }
  }
}

const rules = [
  // Customer
  new RedirectRule({ from: '^ovatu://customer/([0-9]+)/view', to: '/customer/$1' }),

  // Customer
  new RedirectRule({ from: '^ovatu://customer/([0-9]+)/forms', to: '/customer/$1?tab=forms' }),

  // Reservation
  new PanelRedirectRule({ from: '^ovatu://reservation/([0-9]+)/view\\?date=([0-9]{6})', to: '/appointment/$1?date=$2' }),

  // Order
  new PanelRedirectRule({ from: '^ovatu://order/([0-9]+)/view', to: '/order/$1' }),

  // Waitlist
  new PanelRedirectRule({ from: '^ovatu://waitlist/([0-9]+)/view', to: '/waitlist/$1' }),

  // Review
  new RedirectRule({ from: '^ovatu://review/list', to: '/review' }),

  // Messages
  new RedirectRule({ from: '^ovatu://messages/list', to: '/message' }),

  // SMS settings
  new RedirectRule({ from: '^ovatu://settings/sms', to: '/manage/sms' }),

  // Plan settings
  new RedirectRule({ from: '^ovatu://settings/plan', to: '/account/billing' }),

  // Integration authentication
  new PanelRedirectRule({ from: '^ovatu://integration/(\\w+)/authenticate', to: '/integration/$1' }),

  // Resend email verification
  new RedirectRule({ from: '^ovatu://settings/verify', to: '/account/verify' }),

  // Password
  new RedirectRule({ from: '^ovatu://settings/password', to: '/account/users/users' }),

  // Minisite settings
  new RedirectRule({ from: '^ovatu://settings/minisite', to: '/minisite' }),

  // Policy settings
  new RedirectRule({ from: '^ovatu://settings/policy\\?policy=(\\w+)', to: '/account/policy?policy=$1' }),

  // Reservation list
  new PanelRedirectRule({ from: '^ovatu://reservation/list\\?ids=([&\\w]+)', to: '/appointment/list?ids=$1' }),

  // Order list
  new PanelRedirectRule({ from: '^ovatu://order/list\\?ids=([&\\w]+)', to: '/order/list?ids=$1' }),

  // Minisite home
  new ExternalRedirectRule({
    from: '^ovatu://minisite/home',
    to: (url, context) => {
      const domain = context.$config.bookappDomain
      const slug = context.$auth.info.location.slug

      return 'https://' + slug + '.' + domain
    }
  })
]

export default (context, inject) => {
  const plugin = {
    match (url) {
      const found = this.rules.find(rule => rule.regex.test(url))
      let path = '/'

      if (found) {
        if (found.to instanceof Function) {
          path = found.to(url, context)
        } else  {
          path = url.replace(found.regex, found.to)
        }
      }

      const component = found?.component

      return {
        path,
        component,
        rule: found
      }
    },

    route (internalUrl) {
      const match = this.match(internalUrl)

      if (match) {
        match.rule.action(match.path, context)
      }
    },

    get rules () {
      return rules
    }
  }

  inject('redirect', plugin)
}
