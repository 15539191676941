<template>
  <div class="flex items-center pb-3 overflow-y-auto whitespace-nowrap">
    <template v-for="(route, index) in routes">
      <component
        :is="route.path ? 'nuxt-link' : 'p'"
        :key="'item' + index"
        :to="route.path"
        class="text-gray-600 dark:text-gray-200 hover:underline text-sm"
        :class="{ 'text-green-600 dark:text-green-400 hover:underline text-sm': index === routes.length - 1 }"
      >
        {{ route.name }}
      </component>

      <span v-if="index < routes.length - 1" :key="'arrow' + index" class="mx-3 text-gray-500 dark:text-gray-300">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
        </svg>
      </span>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    pages: {
      type: Array,
      default: null
    }
  },
  computed: {
    routes () {
      if (this.pages) {
        return this.pages
      }

      const route = this.$route
      const matched = route.matched

      return matched
        .map((route) => {
          const { name, path, meta } = route

          return {
            name: meta?.name || name,
            path
          }
        })
    }
  }
}
</script>
