<template>
  <div :class="[ baseClass, padding, { 'items-center' : center }]">
    <dt :class="[ termClass, termCol ]">
      <slot name="term">
        {{ term }}
      </slot>
    </dt>
    <dd :class="[ definitionClass, definitionCol, { 'text-right' : alignRight } ]">
      <slot />
    </dd>
  </div>
</template>

<script>
export default {
  name: 'ODescriptionDetails',
  props: {
    term: {
      type: String,
      default: null
    },
    baseClass: {
      type: String,
      default: 'sm:grid sm:grid-cols-6 sm:gap-1'
    },
    termClass: {
      type: String,
      default: 'text-sm leading-5 text-gray-500'
    },
    definitionClass: {
      type: String,
      default: 'mt-1 text-sm leading-5 text-gray-900 sm:mt-0'
    },
    termCol: {
      type: String,
      default: 'sm:col-span-1'
    },
    definitionCol: {
      type: String,
      default: 'sm:col-span-5'
    },
    center: {
      type: Boolean,
      default: false
    },
    alignRight: {
      type: Boolean,
      default: false
    },
    padding: {
      type: String,
      default: 'py-3'
    }
  }
}
</script>
