<template>
  <div class="h-full flex flex-col">
    <div
      class="flex-shrink h-10 w-full flex items-center flex-row"
      :class="navClass"
    >
      <slot name="leading" />

      <!-- Left nav arrow-->
      <template v-if="showArrows">
        <o-button flat size="sm" icon="chevronLeft" @click="handleMoveScroll(-1)" />
        <div class="py-2 pl-2 h-full">
          <span class="border-r h-full" />
        </div>
      </template>

      <!-- Tab list -->
      <o-scroll
        ref="scroll"
        x-axis
        :y-axis="false"
        :options="{ visibility: 'hidden' }"
      >
        <div class="flex relative h-full items-center px-2">
          <!-- Tab item -->
          <template v-for="tab in tabs">
            <nuxt-link
              v-if="owner || ($grants.access(tab.path) && $features.access(tab.path))"
              :key="tab.path"
              :ref="tab.path"
              :to="tab.path"
              class="z-10 flex h-8 flex-shrink-0 items-center text-sm -mb-px leading-none hover:text-green-600 focus:outline-none px-2 mr-2 md:mr-4"
              :class="[
                (active && tab.path === active.path) ? 'text-green-600 active:text-green-600 font-medium' : 'text-gray-600',
                $features.access(tab.path) ? '' : 'opacity-50'
              ]"
            >
              <o-icon v-if="tab.icon" :icon="tab.icon" :size="16" class="lg:mr-3 p-2 md:p-0 hidden lg:block" />
              <span class="">{{ tab.label }}</span>
            </nuxt-link>
          </template>

          <!-- Underline -->
          <span class="absolute transition-all ease-in-out duration-200 h-2px bottom-0 bg-green-600 shadow'" :style="underline" />
        </div>
      </o-scroll>

      <!-- Right nav arrow-->
      <template v-if="showArrows">
        <div class="py-2 pr-2 h-full">
          <span class="border-r h-full" />
        </div>
        <o-button flat size="sm" icon="chevronRight" @click="handleMoveScroll(1)" />
      </template>

      <slot name="trailing" />
    </div>

    <div :class="containerClass">
      <o-scroll class="py-3">
        <!-- Active section header -->
        <div v-if="showHeader && active" class="w-full flex items-center justify-between px-5 py-2">
          <div>
            <h2 class="font-medium text-gray-700">
              {{ active.label }}
            </h2>
            <p v-if="active.info" class="text-sm text-gray-600 font-normal">
              {{ active.info }}
            </p>
          </div>

          <!-- Control slot -->
          <slot name="control" />
        </div>

        <slot />
      </o-scroll>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: [Array, Object],
      required: true
    },
    navClass: {
      type: String,
      default: 'border-b border-gray-300 px-5'
    },
    containerClass: {
      type: String,
      default: 'h-10 flex-grow flex-shrink'
    },
    showArrows: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      underline: {
        left: '0px',
        width: '0px'
      }
    }
  },
  computed: {
    active () {
      const tabs = this.tabs
      const activeIndex = this.activeIndex

      return tabs[activeIndex]
    },
    activeIndex () {
      const path = this.$route.path
      const tabs = this.tabs

      const index = tabs
        .findIndex((tab) => {
          return tab.path === path
        })

      return index
    },
    owner () {
      const employee = this.$auth.info.employee
      return employee.role.isRoleTypeOwner
    }
  },
  watch: {
    active () {
      this.calcUnderline()
    },
    tabs () {
      this.$nextTick(() => this.calcUnderline())
    }
  },
  mounted () {
    this.calcUnderline()
  },
  methods: {
    calcUnderline () {
      const active = this.active
      const path = active ? active.path : ''
      const item = this.$refs[path]

      let left = '0px'
      let width = '0px'

      if (item && item[0]) {
        const el = item[0].$el

        left = el.offsetLeft + 'px'
        width = el.getBoundingClientRect().width + 'px'
      }

      this.underline = {
        left,
        width
      }
    },
    handleMoveScroll (position = 1) {
      const tabs = this.tabs
      const current = this.activeIndex
      const element = this.$refs.scroll

      const index = current + position

      if (index >= 0 && index < tabs.length) {
        const path = tabs[index].path
        const tab = this.$refs[path]

        if (tab) {
          element.scroll(tab[0], 500)
          this.$router.push({ path })
        }
      }
    }
  }
}
</script>
