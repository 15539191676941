<template>
  <div class="relative bg-gray-100 h-screen w-screen">
    <router-view />

    <portal-target name="modal-container" multiple />
    <portal-target name="dialog-container" multiple />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  mounted () {
    this.setWidth(window.innerWidth)

    window.addEventListener('resize', () => {
      this.setWidth(window.innerWidth)
    })
  },
  methods:{
    ...mapMutations([
      'setWidth',
      'setMobile'
    ])
  }
}
</script>
