import Model, { Request } from '../model'
import Enum from '../enums'

export default class Audit extends Model {
  static modelName () {
    return 'audit'
  }

  objectID () {
    return this.idCustomer
  }

  relations () {
    return {
      action: { type: AuditAction }
    }
  }

  static history (modelType, primaryKey, page = 1, attribute = null) {
    const url = this.modelBaseURL() + '/history?model=' + modelType + '&key=' + primaryKey + '&page=' + page + '&attribute=' + attribute
    return this.requestList(Request.get(url), Audit)
  }
}

export const AuditAction = new Enum({
  create: { value: 0, description: 'Create' },
  update: { value: 1, description: 'Update' },
  event: { value: 2, description: 'Event' }
})
