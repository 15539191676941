import Fuse from 'fuse.js'

import { plugin as $date } from '../../plugins/date'

import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'
import { FilterController, Filter, RemoteFilter, FilterCategory, FilterOptions } from '../filter'

import Customer from './customer'
import CustomField from './customField'
import Employee, { EmployeeEmployeeType } from './employee'
import OrderItem, { ItemPaymentType } from './orderItem'
import Tax from './tax'

export default class Order extends Model {
  static modelName () {
    return 'order'
  }

  objectID () {
    return this.idOrder
  }

  static modelApiVersion () {
    return 2
  }

  relations () {
    return {
      customer: { type: Customer },
      customFields: { type: CustomField },
      employee: { type: Employee },
      employeeTips: { type: OrderEmployeeTip },
      data: { type: OrderData },
      tipType: { type: OrderTipType }
    }
  }

  static filter (filters, { from, to }) {
    const url = this.modelBaseURL() + '/filter'
    const request = {
      filters,
      from,
      to
    }

    return this.requestItem(Request.post(url, JSON.stringify(request)), OrderFilterResponse)
  }

  match (query) {
    let match = false
    match = match || this.orderNumber.toLowerCase().includes(query)

    return match
  }

  static create ({ customer, notes, items = [] }) {
    const data = {
      idCustomer: customer.idCustomer,
      notes,
      items: items
        .map((item) => {
          return {
            type: item.constructor.modelName(),
            id: item.objectID()
          }
        })
    }

    const order = new Order(data)

    return order.save()
  }

  static recentCharges () {
    const url = this.modelBaseURL() + '/recentCharges'
    return this.requestList(Request.get(url), OrderItem)
  }

  static listMonth (year, month) {
    const url = this.modelBaseURL() + '/month?year=' + year + '&month=' + month
    return this.requestItem(Request.get(url), OrderListMonth)
  }

  /* listIDs (ids) {
    const data = ids.join(',')
    const url = this.constructor.modelBaseURL() + '/ids?ids=' + data
    return this.constructor.requestList(Request.get(url), this.constructor)
  } */

  static ids (ids, page = 1) {
    const url = this.modelBaseURL() + '/ids?page=' + page

    const data = {
      ids
    }

    return this.requestList(Request.post(url, JSON.stringify(data)), this)
  }

  static listIDs (ids) {
    // use a default runner (to call the list method)
    const that = this

    const runner = (page) => {
      return that.ids(ids, page)
    }

    return this.listRunner(runner)
  }

  email () {
    const url = this.constructor.modelBaseURL() + '/email?id=' + this.objectID()
    return this.constructor.requestSuccess(Request.get(url))
  }

  open () {
    const url = this.constructor.modelBaseURL() + '/open?id=' + this.objectID()
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  close (email) {
    const sendEmail = email ? 1 : 0
    const url = this.constructor.modelBaseURL() + '/close?id=' + this.objectID() + '&email=' + sendEmail
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  addProduct (product, quantity, employee) {
    const employeeId = employee ? employee.idEmployee : ''
    const url = this.constructor.modelBaseURL() + '/addProduct?id=' + this.objectID() + '&product=' + product.idProduct + '&quantity=' + quantity + '&employee=' + employeeId
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  addCustomerCredit (price, employee) {
    const employeeId = employee ? employee.idEmployee : ''
    const url = this.constructor.modelBaseURL() + '/addCustomerCredit?id=' + this.objectID() + '&price=' + price + '&employee=' + employeeId
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  addProducts (quantities, employee) {
    const employeeId = employee ? employee.idEmployee : ''
    const url = this.constructor.modelBaseURL() + '/addProducts?id=' + this.objectID() + '&employee=' + employeeId

    const data = {
      quantities: quantities
        .map(({ product, quantity }) => {
          return {
            product: product.idProduct,
            quantity
          }
        })
    }

    return this.constructor.requestItem(Request.post(url, data), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  addProductWithBarcode (barcode) {
    const url = this.constructor.modelBaseURL() + '/addProductwithBarcode?id=' + this.objectID()
    return this.constructor.requestItem(Request.post(url, { barcode }), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  addService (service, employee) {
    const employeeId = employee && employee.idEmployee ? employee.idEmployee : ''
    const url = this.constructor.modelBaseURL() + '/addService?id=' + this.objectID() + '&service=' + service.idService + '&employee=' + employeeId
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  addReservation (reservation) {
    const url = this.constructor.modelBaseURL() + '/addReservation?id=' + this.objectID() + '&reservation=' + reservation.idReservation
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  addReservations (reservations) {
    const url = this.constructor.modelBaseURL() + '/' + this.objectID()  + '/addReservations'

    const data = {
      reservations: [],
      reservationCustomers: []
    }

    for (const reservation of reservations) {
      if (reservation.isSingle) {
        data.reservations.push(reservation.idReservation)
      }

      if (reservation.isGroup) {
        data.reservationCustomers.push(reservation.reservationCustomer.idReservationCustomer)
      }
    }

    return this.constructor.requestItem(Request.post(url, data), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  // NOTE: There were 3 addReservation functions - I renamed the second 2 "addReservationEmployee" & "addReservationCustomer"
  addReservationEmployee (reservation, employee) {
    const employeeId = employee ? employee.idEmployee : ''
    const url = this.constructor.modelBaseURL() + '/addReservation?id=' + this.objectID() + '&reservation=' + reservation.idReservation + '&employee=' + employeeId
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  addReservationCustomer (reservationCustomer) {
    const url = this.constructor.modelBaseURL() + '/addReservationCustomer?id=' + this.objectID() + '&reservationCustomer=' + reservationCustomer.idReservationCustomer
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  addReservationCustomers (reservationCustomers) {
    const url = this.constructor.modelBaseURL() + '/addReservationCustomers?id=' + this.objectID()

    const data = {
      reservationCustomers: reservationCustomers
        .map(({ idReservationCustomer }) => idReservationCustomer)
    }

    return this.constructor.requestItem(Request.post(url, data), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  addGiftCard (giftCard) {
    const url = this.constructor.modelBaseURL() + '/addGiftCard?id=' + this.objectID() + '&giftCard=' + giftCard.idGiftCard
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  addGiftCards (giftCards) {
    const url = this.constructor.modelBaseURL() + '/addGiftCards?id=' + this.objectID()

    const data = {
      giftCards: giftCards
        .map(({ idGiftCard }) => idGiftCard)
    }

    return this.constructor.requestItem(Request.post(url, data), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  addCustomerPasses (customerPasses) {
    const url = this.constructor.modelBaseURL() + '/addCustomerPasses?id=' + this.objectID()

    const data = {
      customerPasses: customerPasses
        .map(({ idCustomerPass }) => idCustomerPass)
    }

    return this.constructor.requestItem(Request.post(url, data), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  addCharge (charge) {
    const url = this.constructor.modelBaseURL() + '/addCharge?id=' + this.objectID()

    try {
      const data = charge.toJSON()
      return this.constructor.requestItem(Request.post(url, data), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
    } catch (error) {
      return error
    }
  }

  addDiscount (discount) {
    const url = this.constructor.modelBaseURL() + '/addDiscount?id=' + this.objectID()

    try {
      const data = discount.toJSON()
      return this.constructor.requestItem(Request.post(url, data), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
    } catch (error) {
      return error
    }
  }

  addPayment (payment) {
    const url = this.constructor.modelBaseURL() + '/addPayment?id=' + this.objectID()
    // return this.constructor.requestItem(Request.post(url, payment), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))

    try {
      const data = payment.toJSON()
      return this.constructor.requestItem(Request.post(url, data), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
    } catch (error) {
      return error
    }
  }

  addPaymentSavedCreditCard (amount, creditCard, idempotency_key) {
    const url = this.constructor.modelBaseURL() + '/addPaymentSavedCreditCard?id=' + this.objectID() + '&amount=' + amount + '&idCustomerCreditCard=' + creditCard.idCustomerCreditCard + '&idempotency_key=' + idempotency_key
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  addPaymentNewCreditCard (amount, processor, params, saveCard) {
    const url = this.constructor.modelBaseURL() + '/addPaymentSavedCreditCard?id=' + this.objectID()
    const data = {
      amount,
      processor,
      saveCard
    }
    const jsonData = Object.assign(data, params)
    return this.constructor.requestItem(Request.post(url, jsonData), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  saveItem (item) {
    const url = this.constructor.modelBaseURL() + '/saveItem?id=' + this.objectID()
    return this.constructor.requestItem(Request.post(url, item), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  deleteItem (item) {
    const url = this.constructor.modelBaseURL() + '/deleteItem?id=' + this.objectID() + '&item=' + item.idOrderItem
    return this.constructor.requestItem(Request.get(url), Order).then(this.updateSelf(res => this.updateSelf(res)))
  }

  refundPayment (orderItem, amount, processorRefund, customerPayment) {
    const url = this.constructor.modelBaseURL() + '/refundPayment'

    if (customerPayment) {
      delete customerPayment.idLocationCustomerPayment
    }

    const jsonData = {
      order: this.objectID(),
      orderItem: orderItem.objectID(),
      amount,
      processorRefund,
      customerPayment
    }
    return this.constructor.requestItem(Request.post(url, jsonData), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  requestPayment (amount, details) {
    const url = this.constructor.modelBaseURL() + '/requestPayment'
    const jsonData = {
      order: this.objectID(),
      amount,
      details
    }

    return this.constructor.requestSuccess(Request.post(url, jsonData))
  }

  setTipSingle (amount) {
    const url = this.constructor.modelBaseURL() + '/setTip?id=' + this.objectID()
    const jsonData = {
      tipType: OrderTipType.single.value,
      amount
    }
    return this.constructor.requestItem(Request.post(url, jsonData), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  setTipEmployee (employeeTips) {
    const url = this.constructor.modelBaseURL() + '/setTip?id=' + this.objectID()
    const tips = []

    for (const employeeTip of employeeTips) {
      tips.push({
        employee: employeeTip.employee.idEmployee,
        amount: employeeTip.amount
      })
    }

    const jsonData = {
      tipType: OrderTipType.perEmployee.value,
      employeeTips: tips
    }

    return this.constructor.requestItem(Request.post(url, jsonData), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  get hasServices () {
    return !!this.data.items.reservations.length
  }

  get hasProducts () {
    return !!this.data.items.products.length
  }

  get hasCharges () {
    return !!this.data.items.charges.length
  }

  get hasDiscounts () {
    return !!this.data.items.discounts.length
  }

  get hasPayments () {
    return !!this.data.items.payments.length
  }

  get hasItems () {
    return this.hasReservations || this.hasProducts || this.hasCharges || this.hasDiscounts || this.hasPayments
  }

  get hasRemaining () {
    return this.data.totals.remainder > 0
  }

  get hasOverpayment () {
    return this.data.totals.total < this.data.totals.payments
  }

  get hasTip () {
    return this.data.totals.tip > 0
  }

  get isTipTypeSingle () {
    return this.tipType === OrderTipType.single
  }

  get isTipTypePerEmployee () {
    return this.tipType === OrderTipType.perEmployee
  }
}

export class OrderData extends APIObject {
  relations () {
    return {
      items: { type: OrderDataItems },
      totals: { type: OrderDataTotals },
      taxtotals: { type: OrderDataTaxTotal },
      employeeTips: { type: OrderEmployeeTip }
    }
  }
}

export class OrderDataItems extends APIObject {
  relations () {
    return {
      reservations: { type: OrderItem },
      products: { type: OrderItem },
      charges: { type: OrderItem },
      discounts: { type: OrderItem },
      payments: { type: OrderItem }
    }
  }
}

export class OrderDataTaxTotal extends APIObject {
  relations () {
    return {
      tax: { type: Tax }
    }
  }
}

export class OrderDataTotals extends APIObject {
}

export class OrderListMonth extends APIObject {
  relations () {
    return {
      orders: { type: Order }
    }
  }
}

export class OrderEmployeeTip extends APIObject {
  relations () {
    return {
      employee: { type: Employee }
    }
  }
}

export const OrderTipType = new Enum({
  single: { value: 0 },
  perEmployee: { value: 1 }
})

export class OrderFilterResponse extends APIObject {

}

export class OrderFilterController extends FilterController {
  constructor (context) {
    super(Order, { context, custom: true })

    const { $store } = context

    this.search = new Filter({
      label: 'Search',
      matches (orders) {
        const fuse = new Fuse(orders, {
          keys: [
            'customer.firstName',
            'customer.lastName',
            'customer.company',
            'priceLabel',
            'orderNumber'

          ]
        })

        const results = fuse.search(this.value)
        return results.map(({ item }) => item)
      }
    })

    this.dates = new RemoteFilter('date', {
      label: 'Dates',
      value: {
        start: null,
        finish: null
      },
      component: 'OrderDateRange',
      force: true,
      validation () {
        const { start, finish } = this.value
        const valid = start && finish

        if (!valid) {
          return {
            status: false,
            message: 'Start and finish date must be provided'
          }
        }

        return null
      },
      valueLabel () {
        /* if (this.value.start && this.value.finish) {
          return $date.format(this.value.start * 1000, $date.presets.short) + '-' + $date.format(this.value.finish * 1000, $date.presets.short)
        }

        if (this.value.start) {
          return $date.format(this.value.start * 1000, $date.presets.short)
        }

        if (this.value.finish) {
          return $date.format(this.value.finish * 1000, $date.presets.short)
        } */
        return false
      },
      getParams () {
        return {
          from: this.value.start,
          to: this.value.finish
        }
      },
      clear () {
        this.value.start = null
        this.value.finish = null
      },
      isEmpty () {
        return !this.value.start && !this.value.finish
      }
    })

    this.paymentStatus = new RemoteFilter('status', {
      component: 'SegmentField',
      label: 'Payment status',
      options: [
        { label: 'Paid in full', value: 'paid' },
        { label: 'Unpaid', value: 'unpaid' },
        { label: 'Partial', value: 'partial' }
      ],
      props: {
        outline: true, baseClass: 'flex rounded-lg p-1 h-9 focus-within:ring-2'
      },
      matches (orders) {
        return orders.filter((order) => {
          return this.value.includes(order.statusLabel.toLowerCase())
        })
      },
      valueLabel () {
        switch (this.value) {
          case 'paid':
            return 'Paid in full'
          case 'unpaid':
            return 'Unpaid'
          case 'partial':
            return 'Partial'
          default:
            return 'All'
        }
      },
      getParams () {
        return {
          status: this.value
        }
      }
    })


    this.saleStatus = new Filter({
      component: 'SegmentField',
      label: 'Sale status',
      options: [
        { label: 'Open', value: 'true' },
        { label: 'Closed', value: 'false' }
      ],
      props: {
        outline: true, baseClass: 'flex rounded-lg p-1 h-9 focus-within:ring-2'
      },
      matches (orders) {
        const value = this.value === 'true'

        return orders.filter((order) => {
          return order.closed === !value
        })
      },
      valueLabel () {
        return (this.value === 'true') ? 'Yes' : 'No'
      }
    })

    this.employees = new RemoteFilter('employees', {
      label: 'Employees',
      info: 'Sales that include the selected employees',
      component: 'CheckboxGroupField',
      props: {
        search: true,
        class: 'border rounded overflow-hidden bg-gray-200 h-80 px-1'
      },
      value: [],
      options: [],
      valueLabel () {
        const value = this.value

        if (value.length) {
          const labels = value.slice(0, 3).map(({ displayName }) => displayName).join(', ')
          return labels + (value.length > 3 ? ` and ${value.length - 3} more` : '')
        }

        return 'All employees'
      },
      getParams () {
        return {
          employees: this.value.map(({ idEmployee }) => idEmployee)
        }
      },
      clear () {
        this.value = []
      }
    })

    this.services = new RemoteFilter('services', {
      label: 'Services',
      info: 'Sales that include the selected services',
      component: 'CheckboxCategoriesField',
      props: {
        search: true,
        class: 'border rounded overflow-hidden bg-gray-200 h-80 px-1'
      },
      value: [],
      options: [],
      valueLabel () {
        const value = this.value

        if (value.length) {
          const labels = value.slice(0, 3).map(({ title }) => title).join(', ')
          return labels + (value.length > 3 ? ` and ${value.length - 3} more` : '')
        }

        return 'All services'
      },
      getParams () {
        return {
          services: this.value.map(({ idService }) => idService)
        }
      },
      clear () {
        this.value = []
      }
    })

    this.products = new RemoteFilter('products', {
      label: 'Products',
      info: 'Sales that include the selected products',
      component: 'CheckboxCategoriesField',
      props: {
        search: true,
        selectCategory: true,
        class: 'border rounded overflow-hidden bg-gray-200 h-80 px-1'
      },
      value: [],
      options: [],
      valueLabel () {
        const value = this.value

        if (value.length) {
          const labels = value.slice(0, 3).map(({ name }) => name).join(', ')
          return labels + (value.length > 3 ? ` and ${value.length - 3} more` : '')
        }

        return 'All products'
      },
      getParams () {
        return {
          products: this.value.map(({ idProduct }) => idProduct)
        }
      },
      clear () {
        this.value = []
      }
    })

    this.discounts = new RemoteFilter('discount', {
      component: 'SelectField',
      label: 'Discount',
      options: [],
      valueLabel () {
        return this.value?.title
      },
      getParams () {
        return {
          discount: this.value?.idDiscountModel
        }
      }
    })

    this.vouchers = new RemoteFilter('voucher', {
      component: 'SelectField',
      label: 'Promo code',
      options: [],
      features: ['vouchers'],
      valueLabel () {
        return `${this.value?.name} (${this.value?.code})`
      },
      getParams () {
        return {
          voucher: this.value?.idVoucher
        }
      }
    })

    this.paymentType = new RemoteFilter('paymentType', {
      component: 'SelectField',
      label: 'Payment type',
      options: Object.values(ItemPaymentType)
        .map((item) => {
          return { label: item.description, value: item }
        }),
      props: {
        outline: true //, baseClass: 'flex rounded-lg p-1 h-9 focus-within:ring-2'
      },
      valueLabel () {
        return this.value?.descrption
      },
      getParams () {
        return {
          paymentType: this.value?.value
        }
      }
    })

    this.employeeOptions = new FilterOptions({
      map () {
        return $store
          ? Object.values($store.getters['employee/all'])
            .filter(({ type }) => type === EmployeeEmployeeType.employee)
            .map(employee => ({
              value: employee,
              text: employee.displayName,
              info: employee.type ? employee.type.description : 'Employee',
              image: employee.images ? employee.images.square : null
            }))
          : []
      },
      set: (options) => {
        this.employees.options = [{ text: 'None', value: '-1' }, ...options]
      }
    })

    this.serviceOptions = new FilterOptions({
      map () {
        return $store
          ? Object.values($store.getters['service/categorised']).map(category => ({
            label: category.name || '',
            options: category.services?.map(service => ({
              value: service,
              text: service.title
            }))
          }))
          : []
      },
      set: (options) => {
        this.services.options = options
      }
    })

    this.productOptions = new FilterOptions({
      map () {
        const products = $store ? $store.getters['product/all'] : null
        return products
          ? Object.values(products).map(category => ({
            label: category.category ? category.category.name : 'Other',
            options: category.products.map(product => ({
              value: product,
              text: product.name
            }))
          }))
          : []
      },
      set: (options) => {
        this.products.options = options
      }
    })

    this.discountOptions = new FilterOptions({
      map () {
        return $store
          ? Object.values($store.getters['discountModel/all'])
            .map(discountModel => ({
              value: discountModel,
              label: discountModel.title
            }))
          : []
      },
      set: (options) => {
        this.discounts.options = options
      }
    })

    this.voucherOptions = new FilterOptions({
      map () {
        return $store
          ? Object.values($store.getters['voucher/all'])
            .map(voucher => ({
              value: voucher,
              label: `${voucher.name} (${voucher.code})`
            }))
          : []
      },
      set: (options) => {
        this.vouchers.options = options
      },
      features: ['vouchers']
    })

    this.categorised = [
      new FilterCategory({
        label: 'Payments',
        filters: [this.paymentStatus, this.paymentType, this.discounts, this.vouchers]
      }),
      new FilterCategory({
        label: 'Sale status',
        filters: [this.saleStatus]
      }),
      new FilterCategory({
        label: 'Employees',
        filters: [this.employees]
      }),
      new FilterCategory({
        label: 'Services',
        filters: [this.services]
      }),
      new FilterCategory({
        label: 'Products',
        filters: [this.products]
      }),
      new FilterCategory({
        label: 'Custom fields',
        filters: this.custom,
        features: ['custom-fields']
      })
    ]
  }

  get params () {
    const value = this.dates.value
    const from = value.start
    const to = value.finish

    return {
      from,
      to
    }
  }
}
