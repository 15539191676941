<template>
  <div class="flex items-center" :class="[ vertical ? 'px-4' : 'w-full my-6' ]">
    <div class="border-gray-300 flex-grow" :class="[ vertical ? 'border-r h-full' : 'border-b' ]" />
    <p v-if="text" class="px-4 leading-none">
      {{ text }}
    </p>
    <div v-if="text" class="border-b border-gray-300 flex-grow" />
  </div>
</template>

<script>
export default {
  name: 'o-divider',
  props: {
    text: {
      type: String,
      default: null
    },
    vertical: {
      type: Boolean,
      default: false
    }
  }
}
</script>
