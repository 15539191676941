import localforage from 'localforage'
import * as API from '~/api'

export const state = () => ({
  integrations: [],
  connect: {
    guid: null,
    query: {}
  },
  sync: {
    running: false,
    progress: null,
    date: null,
    error: false
  },
  hydrated: false,
  count: null
})

export const mutations = {
  setSync (state, { running, progress, date }) {
    if (!(running === undefined)) {
      state.sync.running = running
    }
    if (!(progress === undefined)) {
      state.sync.progress = progress
    }
    if (!(date === undefined)) {
      state.sync.date = date
    }
  },
  updateIntegrations (state, integrations) {
    state.integrations = integrations
  },
  setHydrated (state, hydrated) {
    state.hydrated = hydrated
  },
  setCount (state, count) {
    state.count = count
  },
  setConnectQuery (state, query) {
    state.connect.query = query
  },
  setConnectGuid (state, guid) {
    state.connect.guid = guid
  }
}

export const getters = {
  isSyncing (state) {
    return state.sync.running
  },
  syncProgress (state) {
    return state.sync.progress
  },
  syncDate (state) {
    return state.sync.date
  },
  all (state) {
    return state.integrations
  },
  count (state) {
    return state.integrations.length
  },
  connect (state) {
    return state.connect
  }
}

export const actions = {
  async sync ({ commit, state, rootState }, { force = false, change = false } = {}) {
    const guid = rootState.auth.active

    if (!state.hydrated || change) {
      const integrations = await localforage.getItem(guid + '.integrations.integrations')
      const sync = await localforage.getItem(guid + '.integrations.sync')

      if (integrations) {
        commit('updateIntegrations', integrations)
      } else {
        commit('updateIntegrations', [])
      }

      if (sync) {
        commit('setSync', sync)
      } else {
        commit('setSync', { date: null, progress: null, running: false })
      }

      commit('setHydrated', true)
    }

    const date = new Date()

    commit('setSync', { running: true })

    const sinceDate = (force) ? null : state.sync.date

    let integrations
    const errors = []

    try {
      integrations = await API.Integration.active(sinceDate)
    } catch (e) {
      if (e?.status === 304) {
        console.log('Integration data is up to date')
      } else {
        errors.push(e)
      }
    } finally {
      if (integrations?.items) {
        commit('updateIntegrations', integrations.items)
      }

      if (errors.length) {
        commit('setSync', { running: false, progress: null, date: null, error: true })
      } else {
        commit('setSync', { running: false, progress: null, date })
      }

      localforage.setItem(guid + '.integrations.integrations', state.integrations)
      localforage.setItem(guid + '.integrations.sync', state.sync)
    }
  }
}
