<template>
  <component :is="tag" :to="to" v-bind="$attrs" :disabled="disabled" @click.native.stop.prevent="handleClick">
    <slot />
  </component>
</template>

<script>
import {
  ODropdownItem,
  OStatistic
} from '@/components/elements'

export default {
  name: 'OPanelLink',
  components: {
    ODropdownItem,
    OStatistic
  },
  props: {
    path: {
      type: String,
      required: true
    },
    props: {
      type: Object,
      default: () => {}
    },
    events: {
      type: Object,
      default: () => {}
    },
    tag: {
      type: String,
      default: 'nuxt-link'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    to () {
      const path = '#' + this.path
      const query = this.$route.query

      return {
        path,
        query
      }
    },
    attrs () {
      return [
        ...this.$attrs
      ]
    }
  },
  methods: {
    handleClick ($event) {
      if (!this.disabled) {
        const path = this.path
        const options = {
          props: this.props,
          events: this.events
        }
        this.$panel.create(path, options)
        this.$emit('click', $event)
      }
    }
  }
}
</script>
